/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface QuestionRowProps {
  question: string;
  answer?: "YES" | "NO" | "UNSURE";
  textAnswer?: string;
}

export function QuestionRow({
  question,
  answer,
  textAnswer,
}: QuestionRowProps): JSX.Element {
  return (
    <div
      css={(theme: AppTheme) => css`
        display: grid;
        grid-gap: ${theme.spacing(1.5)};
        margin-bottom: ${theme.spacing(1.5)};
        grid-template-columns: calc(75% - ${theme.spacing(1)}) calc(
            25% - ${theme.spacing(1)}
          );
      `}
    >
      <Typography variant="body">{question}</Typography>
      <div
        css={css`
          justify-content: center;
          text-align: center;
        `}
      >
        {answer ? (
          (() => {
            switch (answer) {
              case "YES":
                return (
                  <div
                    css={css`
                      display: inline-flex;
                    `}
                  >
                    <CheckCircleIcon
                      css={css`
                        color: green;
                      `}
                    />
                    <Typography
                      css={(theme: AppTheme) => css`
                        margin-left: ${theme.spacing(0.5)};
                        font-weight: bold;
                      `}
                      display="inline"
                      color="textSecondary"
                    >
                      Yes
                    </Typography>
                  </div>
                );
              case "NO":
                return (
                  <div
                    css={css`
                      display: inline-flex;
                    `}
                  >
                    <CancelIcon color="error" />
                    <Typography
                      css={(theme: AppTheme) => css`
                        margin-left: ${theme.spacing(0.5)};
                        font-weight: bold;
                      `}
                      display="inline"
                      color="textSecondary"
                    >
                      No
                    </Typography>
                  </div>
                );
              case "UNSURE":
                return (
                  <div
                    css={css`
                      display: inline-flex;
                    `}
                  >
                    <HelpIcon />
                    <Typography
                      css={(theme: AppTheme) => css`
                        margin-left: ${theme.spacing(0.5)};
                        font-weight: bold;
                      `}
                      display="inline"
                      color="textSecondary"
                    >
                      UNSURE
                    </Typography>
                  </div>
                );
            }
          })()
        ) : (
          <Typography variant="body">{textAnswer}</Typography>
        )}
      </div>
    </div>
  );
}
