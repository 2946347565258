/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faClock,
  faIdCardClip,
  faRightFromBracket,
  faRightToBracket,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Controller, useWatch, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { Divider } from "@rewards-web/shared/components/divider";
import { Form } from "@rewards-web/shared/components/form";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { SwitchField } from "@rewards-web/shared/components/switch";
import { Typography } from "@rewards-web/shared/components/typography";
import { extractNumber } from "@rewards-web/shared/lib/extract-number";
import { formatMinutes } from "@rewards-web/shared/lib/format-evv-time";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { EVVRulesFormValues } from ".";
import { PageCardActions } from "../../../../../shared/components/page-card/page-card-actions";
import { AdminOnboardingCard } from "../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../components/step-actions";
import { EVVRuleRow } from "./evv-rule-row";
import { EVVTrackPageEVVRules } from "./evv-rules-details";
import { useEvvRulesPageDataQuery } from "./evv-rules-details-data.generated";
import { getTheInitialValidClockMethodValue, isEVVRulesEnabled } from "./lib";

export type EVVRuleName =
  | "ScheduledStart"
  | "ScheduledEnd"
  | "ScheduledDuration";

export enum EvvRulesClockMethodToRender {
  MobileApp = "MOBILE_APP",
  IvrAndMobileApp = "IVR_AND_MOBILE_APP",
}

const initialValues = {
  minutesLessThanScheduledStart: "",
  minutesGreaterThanScheduledStart: "",
  minutesLessThanScheduledEnd: "",
  minutesGreaterThanScheduledEnd: "",
  minutesLessThanScheduledDuration: "",
  minutesGreaterThanScheduledDuration: "",
  validClockMethods: EvvRulesClockMethodToRender.MobileApp,
  enabledEVVRules: false,
};

const defaultRules = {
  minutesLessThanScheduledStart: formatMinutes(7),
  minutesGreaterThanScheduledStart: formatMinutes(7),
  minutesLessThanScheduledEnd: formatMinutes(7),
  minutesGreaterThanScheduledEnd: formatMinutes(7),
  minutesLessThanScheduledDuration: formatMinutes(7),
  minutesGreaterThanScheduledDuration: formatMinutes(7),
  validClockMethods: EvvRulesClockMethodToRender.MobileApp,
  enabledEVVRules: true,
};

interface EVVRulesContainerProps {
  onSubmit: (values: EVVRulesFormValues) => void;
}

export function EVVRulesForm({ onSubmit }: EVVRulesContainerProps) {
  const form = useForm<EVVRulesFormValues>({
    defaultValues: initialValues,
  });
  const { reset, formState, control } = form;
  const { data, loading: dataLoading } = useEvvRulesPageDataQuery({
    onError: reportError,
  });

  const location = useLocation();
  const isOnbordingPage = location.pathname === "/onboarding/evv-compliance";

  useEffect(() => {
    if (!dataLoading) {
      if (data?.getMyRewardsOrganizationEVVRules.rules) {
        const {
          minutesLessThanScheduledStart,
          minutesGreaterThanScheduledStart,
          minutesLessThanScheduledEnd,
          minutesGreaterThanScheduledEnd,
          minutesLessThanScheduledDuration,
          minutesGreaterThanScheduledDuration,
          validClockMethods,
        } = data.getMyRewardsOrganizationEVVRules.rules;

        reset({
          minutesLessThanScheduledStart: formatMinutes(
            minutesLessThanScheduledStart
          ),
          minutesGreaterThanScheduledStart: formatMinutes(
            minutesGreaterThanScheduledStart
          ),
          minutesLessThanScheduledEnd: formatMinutes(
            minutesLessThanScheduledEnd
          ),
          minutesGreaterThanScheduledEnd: formatMinutes(
            minutesGreaterThanScheduledEnd
          ),
          minutesLessThanScheduledDuration: formatMinutes(
            minutesLessThanScheduledDuration
          ),
          minutesGreaterThanScheduledDuration: formatMinutes(
            minutesGreaterThanScheduledDuration
          ),
          validClockMethods: validClockMethods
            ? getTheInitialValidClockMethodValue(validClockMethods)
            : EvvRulesClockMethodToRender.MobileApp,
          enabledEVVRules: isEVVRulesEnabled(
            data.getMyRewardsOrganizationEVVRules.rules
          ),
        });
      } else {
        reset(defaultRules);
      }
    }
  }, [dataLoading, data, reset]);
  const {
    minutesLessThanScheduledStart,
    minutesGreaterThanScheduledStart,
    minutesLessThanScheduledEnd,
    minutesGreaterThanScheduledEnd,
    minutesLessThanScheduledDuration,
    minutesGreaterThanScheduledDuration,
    validClockMethods,
    enabledEVVRules,
  } = useWatch({ control });

  const fieldNames = {
    minutesLessThanScheduledStart: "minutesLessThanScheduledStart",
    minutesGreaterThanScheduledStart: "minutesGreaterThanScheduledStart",
    minutesLessThanScheduledEnd: "minutesLessThanScheduledEnd",
    minutesGreaterThanScheduledEnd: "minutesGreaterThanScheduledEnd",
    minutesLessThanScheduledDuration: "minutesLessThanScheduledDuration",
    minutesGreaterThanScheduledDuration: "minutesGreaterThanScheduledDuration",
    validClockMethods: "validClockMethods",
  } as const;
  return (
    <>
      <Form
        submitting={formState.isSubmitting}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <AdminOnboardingCard
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <AdminOnboardingCardHeader title="☎️ Compliance (clock in and out)" />
          <AdminOnboardingCardContent>
            <div
              css={(appTheme: AppTheme) => css`
                margin-bottom: ${appTheme.spacing(3)};
              `}
            >
              <Typography
                color="textSecondary"
                variant="body"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                Select the specific rules you’d like us to use when it comes to
                rewarding your employees for consistent Clock In/Out compliance.
              </Typography>
            </div>

            <Controller
              control={control}
              name="enabledEVVRules"
              render={({ field }) => (
                <SwitchField
                  label={enabledEVVRules ? "On" : "Off"}
                  {...field}
                  value={field.value ?? false}
                  onChange={(e, value) => {
                    field.onChange(e, value);
                  }}
                />
              )}
            />
            {enabledEVVRules ? (
              <Typography
                color="textSecondary"
                variant="body"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                Employees must clock in and out according to the specified Clock
                In/Out rule to earn rewards.
              </Typography>
            ) : (
              <Typography
                color="textSecondary"
                variant="body"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                Employees are not required to clock in and out for visits to
                earn rewards
              </Typography>
            )}
          </AdminOnboardingCardContent>
        </AdminOnboardingCard>
        {enabledEVVRules && (
          <>
            <AdminOnboardingCard
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              <AdminOnboardingCardHeader title="Clock in/out rules" />
              <AdminOnboardingCardContent>
                <EVVRuleRow
                  fieldNames={fieldNames}
                  title="Clock-in"
                  tooltipText="Clock ins that are before and after the set delay (in minutes) will not be counted towards rewards."
                  icon={
                    <FontAwesomeIcon
                      css={(theme: AppTheme) => css`
                        margin-right: ${theme.spacing(1)};
                      `}
                      icon={faRightToBracket}
                    />
                  }
                  control={control}
                  name="ScheduledStart"
                />

                <EVVRuleRow
                  fieldNames={fieldNames}
                  title="Clock-out"
                  tooltipText="Clock outs that are before and after the set delay (in minutes) will not be counted towards rewards."
                  icon={
                    <FontAwesomeIcon
                      css={(theme: AppTheme) => css`
                        margin-right: ${theme.spacing(1)};
                      `}
                      icon={faRightFromBracket}
                    />
                  }
                  control={control}
                  name="ScheduledEnd"
                />
                <Divider
                  css={(theme: AppTheme) => css`
                    margin-bottom: ${theme.spacing(4)};
                  `}
                />
                <EVVRuleRow
                  fieldNames={fieldNames}
                  title="Visit duration threshold"
                  tooltipText="Actual visit duration can vary based on the time you set (in minutes)."
                  icon={
                    <FontAwesomeIcon
                      css={(theme: AppTheme) => css`
                        margin-right: ${theme.spacing(1)};
                      `}
                      icon={faClock}
                    />
                  }
                  control={control}
                  name="ScheduledDuration"
                />

                <div
                  css={(theme: AppTheme) => css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    gap: ${theme.spacing(1)};
                  `}
                >
                  <Typography
                    css={(theme: AppTheme) => css`
                      margin-bottom: ${theme.spacing(2)};
                      align-items: center;
                      flex-basis: 35%;
                    `}
                    color="textSecondary"
                    variant="body"
                  >
                    <FontAwesomeIcon
                      css={(theme: AppTheme) => css`
                        margin-right: ${theme.spacing(1)};
                      `}
                      icon={faIdCardClip}
                    />
                    Method
                  </Typography>
                  <div
                    css={css`
                      flex-grow: 1;
                      flex-basis: 0;
                      min-width: 80px;
                    `}
                  >
                    <Controller
                      control={control}
                      name="validClockMethods"
                      render={({ field, fieldState }) => (
                        <div
                          css={css`
                            flex-grow: 1;
                            flex-basis: 0;
                          `}
                        >
                          <SelectField
                            {...field}
                            label="Mobile App"
                            hideLabel
                            error={fieldState.error}
                            width="full"
                            options={[
                              {
                                label: "Mobile app only",
                                value: EvvRulesClockMethodToRender.MobileApp,
                              },
                              {
                                label: "Mobile app or client’s home phone",
                                value:
                                  EvvRulesClockMethodToRender.IvrAndMobileApp,
                              },
                            ]}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </AdminOnboardingCardContent>
            </AdminOnboardingCard>
            <AdminOnboardingCard>
              <AdminOnboardingCardHeader title="☎️ How employees see these rules" />
              <AdminOnboardingCardContent>
                <Typography variant="footnote">
                  Based on the rules configured above, the instructions to
                  employees will be adjusted in the app and in our
                  communications. We recommend you keep the rules simple to
                  ensure they are easily understood by your staff!
                </Typography>
                <Typography
                  css={(theme: AppTheme) => css`
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: ${theme.spacing(1)};
                  `}
                >
                  Rules
                </Typography>
                <EVVTrackPageEVVRules
                  evvRulesLoading={false}
                  evvRules={{
                    minutesLessThanScheduledStart: minutesLessThanScheduledStart
                      ? extractNumber(minutesLessThanScheduledStart)
                      : null,
                    minutesGreaterThanScheduledStart: minutesGreaterThanScheduledStart
                      ? extractNumber(minutesGreaterThanScheduledStart)
                      : null,
                    minutesLessThanScheduledEnd: minutesLessThanScheduledEnd
                      ? extractNumber(minutesLessThanScheduledEnd)
                      : null,
                    minutesGreaterThanScheduledEnd: minutesGreaterThanScheduledEnd
                      ? extractNumber(minutesGreaterThanScheduledEnd)
                      : null,
                    minutesLessThanScheduledDuration: minutesLessThanScheduledDuration
                      ? extractNumber(minutesLessThanScheduledDuration)
                      : null,
                    minutesGreaterThanScheduledDuration: minutesGreaterThanScheduledDuration
                      ? extractNumber(minutesGreaterThanScheduledDuration)
                      : null,
                    validClockMethods,
                  }}
                />
              </AdminOnboardingCardContent>
            </AdminOnboardingCard>
          </>
        )}
        {isOnbordingPage ? (
          <AdminOnboardingStepActions
            disableSkip={true}
            submitButtonLabel={"Save and Continue"}
          />
        ) : (
          <PageCardActions disabled={!form.formState.isDirty} />
        )}
      </Form>
    </>
  );
}
