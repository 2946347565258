import { ClassNames } from "@emotion/react";
import { StepIcon, StepIconProps } from "@material-ui/core";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

interface FormStepTitleProps extends StepIconProps {
  title: string;
}

export function FormStepTitle({ title, icon: index }: FormStepTitleProps) {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  return (
    <ClassNames>
      {({ css, theme }) => (
        <div
          className={css`
            display: flex;
            margin-bottom: ${theme.spacing(0.5)};
            align-items: center;
          `}
        >
          <StepIcon
            active={false}
            icon={index}
            classes={{
              root: css`
                margin-right: ${theme.spacing(1)};
              `,
            }}
          />
          <div>
            <Typography variant={newThemeEnabled ? "subtitle" : "h3"}>
              {title}
            </Typography>
          </div>
        </div>
      )}
    </ClassNames>
  );
}
