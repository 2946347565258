/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Card } from "@rewards-web/shared/components/card";
import { ProgressBar } from "@rewards-web/shared/components/progress-bar";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ScoreText } from "../../components/score-text";
import { SurveyQuestionTypeSupportedByUI } from "../../constants";

export interface ResponseBreakdownCardProps {
  questionType: SurveyQuestionTypeSupportedByUI;
  scores: { score: number; count: number }[];
}

export function ResponseBreakdownCard({
  questionType,
  scores,
}: ResponseBreakdownCardProps) {
  const totalCount = scores.reduce((acc, { count }) => acc + count, 0);
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(3)};
          box-sizing: border-box;
          // ensure 2 cards per row, at the most
          width: calc((100% - ${theme.spacing(3)}) / 2);
          min-width: 450px;
        `
      }
    >
      <Typography
        variant={newThemeEnabled ? "h6" : "h3"}
        fontWeight={700}
        css={(theme: AppTheme) => css`
          ${newThemeEnabled
            ? css`
                margin-bottom: ${theme.spacing(2.5)};
              `
            : css`
                margin-bottom: ${theme.spacing(2)};
              `}
        `}
      >
        Response Breakdown
      </Typography>
      {scores.map(({ score, count }) => {
        const percentage = Math.round((count / totalCount) * 100);
        return (
          <div
            key={score}
            css={(theme: AppTheme) => css`
              display: flex;
              gap: ${theme.spacing(2)};
              align-items: center;
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <div
              css={css`
                width: 30%;
              `}
            >
              <ScoreText
                score={score}
                questionType={questionType}
                color="grey.800"
              />
            </div>
            <div
              css={css`
                flex-grow: 1;
              `}
            >
              <ProgressBar value={percentage} greyBackground />
            </div>
            <div
              css={css`
                width: 5%;
                text-align: right;
              `}
            >
              <Typography variant="body" color="grey.800">
                {percentage}%
              </Typography>
            </div>
          </div>
        );
      })}
    </Card>
  );
}
