import { ApolloError, ServerError } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { GraphQLError } from "graphql";
import { ReactNode, useCallback } from "react";

import { ErrorPage } from "../../pages/error";

export type TransformBreadcrumbFn = (
  breadcrumb: Sentry.Breadcrumb,
  hint?: Sentry.BreadcrumbHint
) => Sentry.Breadcrumb;

export type AllowUrls = Sentry.BrowserOptions["allowUrls"];

export function initializeSentry(config: {
  dsn: string;
  environment: string;
  release?: string;
  transformBreadcrumb?: TransformBreadcrumbFn;
  allowUrls: AllowUrls;
  appName: string;
}) {
  Sentry.init({
    dsn: config.dsn,
    environment: config.environment,
    integrations: [new Integrations.BrowserTracing()],

    // try to parse `v` out of a semver app version (e.g. v1.2.3 -> 1.2.3)
    // otherwise, use the app version as is (e.g. dev)
    release: (() => {
      if (!config.release) {
        return undefined;
      }

      return `${config.appName}@${
        config.release.startsWith("v")
          ? config.release.slice(1)
          : config.release
      }`;
    })(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeBreadcrumb: config.transformBreadcrumb,
    normalizeDepth: 10,
    allowUrls: config.allowUrls,
  });
}

export function useSetErrorTrackingIdentity() {
  return useCallback((userId: string) => Sentry.setUser({ id: userId }), []);
}

export function useClearErrorTrackingIdentity() {
  return () => Sentry.configureScope((scope) => scope.setUser(null));
}

/**
 * Reports an error to the error reporting service
 */
export function reportError(error: any) {
  captureError(error);
}

function captureError(error: any) {
  // eslint-disable-next-line no-console
  console.error(error);

  if (error instanceof ApolloError) {
    // for apollo errors, we want to capture the operation name and query string,
    // so we know which specific graphql operation failed
    // (the stack trace is unfortunately unhelpful)
    const defaultCaptureContext = {
      tags: {
        graphql_operation_name: error.extraInfo?.operationName,
      },
      contexts: {
        ...(error.extraInfo
          ? {
              graphql_operation: {
                operationName: error.extraInfo?.operationName,
                query: error.extraInfo?.query,
              },
            }
          : {}),
      },
      fingerprint: [
        "{{ default }}",

        // fingerprint by operation name to distinguish between
        // different graphql operations
        // (since stack trace is unlikely to be helpful)
        ...(error.extraInfo?.operationName
          ? [error.extraInfo.operationName]
          : []),
      ],
    };

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      // if we have graphql errors, we want to capture those individually

      for (const graphQLError of error.graphQLErrors) {
        let sentryError: Error = graphQLError;

        if (graphQLError instanceof GraphQLError) {
          sentryError = graphQLError;
        } else {
          // ensure it reads "GraphQLError" in the error name.
          // sometimes apollo client will not instantiate the `GraphQLError` properly
          sentryError = new Error((graphQLError as any).message);
          sentryError.name = "GraphQLError";
        }

        Sentry.captureException(sentryError, {
          ...defaultCaptureContext,
          contexts: {
            ...defaultCaptureContext.contexts,
            graphql_error_details: {
              message: graphQLError.message,
              name: graphQLError.name,
              locations: graphQLError.locations,
              extensions: graphQLError.extensions,
              nodes: graphQLError.nodes,
            },
          },
          fingerprint: [
            ...defaultCaptureContext.fingerprint,
            // use the graphql message in the fingerprint
            // since it may not be populated in the error message properly
            graphQLError.message,
          ],
        });
      }
    } else if (error.networkError) {
      if (
        error.graphQLErrors?.some(
          (error) => error.message === "Not signed in"
        ) ||
        (error.networkError?.name === "ServerError" &&
          (error.networkError as ServerError).statusCode === 401)
      ) {
        // if it's a 401 error, it should not be reported as an error
        // since it's expected to happen when the user's session expires
        return;
      }

      // if we _only_ have a network error, we can just capture that
      Sentry.captureException(error.networkError, {
        ...defaultCaptureContext,
        contexts: {
          ...defaultCaptureContext.contexts,
          apollo_network_error_details: {
            message: error.networkError.message,
            name: error.networkError.name,
            ...(error.networkError.name === "ServerError"
              ? {
                  status_code: (error.networkError as ServerError).statusCode,
                  status_text: (error.networkError as ServerError).response
                    .statusText,
                  response_json: (error.networkError as ServerError).result,
                }
              : {}),
          },
        },
      });
    } else {
      // capture raw errors
      Sentry.captureException(error, defaultCaptureContext);
    }
  } else {
    Sentry.captureException(error);
  }
}

/**
 * Reports a breadcrumb to provide context leading up to a potential error
 */
export function reportErrorBreadcrumb(breadcrumb: {
  type?: "error";
  category?: string;
  level?: `${Sentry.Severity}`;
  data?: {
    [key: string]: any;
  };
}) {
  Sentry.addBreadcrumb({
    ...breadcrumb,
    level: Sentry.Severity.Info,
  });
}

interface AppErrorBoundaryProps {
  children: ReactNode;
}

/**
 * This should wrap the application
 */
export function AppErrorBoundary({ children }: AppErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorPage
          message={
            <>
              An unexpected error occurred. Refresh the page to try again.
              <br />
              If the problem persists, please contact{" "}
              <a href="mailto:help@caribou.care">help@caribou.care</a>.
            </>
          }
        />
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
