/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import OpenInNew from "@material-ui/icons/OpenInNew";
import { compact } from "lodash";
import { useState } from "react";

import { IconButton } from "@rewards-web/shared/components/icon-button";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  JobPosting,
  JobPostingPromotion,
  JobPostingStats,
  Maybe,
  RewardsOrganizationBranch,
} from "@rewards-web/shared/graphql-types";
import { useDrawerControl } from "@rewards-web/shared/hooks/use-drawer-control";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { DataTable } from "../../../../../components/data-table";
import { ListActions } from "../../../../../components/list-actions";
import { useMyBranches } from "../../../../branches/use-my-branches";
import { AddNewJobModal } from "../add-new-job-modal";
import { ConfirmCloseJobModal } from "../confirm-close-job-modal";
import { ConfirmDuplicateModal } from "../confirm-duplicate-modal";
import { ConfirmPromoteModal } from "../confirm-promote-modal";
import { EditJobRightDrawer } from "../edit-job-right-drawer";
import { JobStatsTooltipContent } from "./job-stats-tooltip-content";

export interface AdminJobsDataTableProps {
  currentPageIndex: number;
  setCurrentPageIndex(currentPageIndex: number): void;
  perPage: number;
  loading: boolean;
  items:
    | undefined
    | ({
        __typename?: "JobPosting" | undefined;
      } & Pick<
        JobPosting,
        "id" | "title" | "geography" | "closedForSubmission"
      > & {
          branch?:
            | Maybe<
                {
                  __typename?: "RewardsOrganizationBranch" | undefined;
                } & Pick<RewardsOrganizationBranch, "id" | "name">
              >
            | undefined;
          activePromotion?: Maybe<
            { __typename?: "JobPostingPromotion" } & Pick<
              JobPostingPromotion,
              "id"
            >
          >;
          stats: { __typename?: "JobPostingStats" } & Pick<
            JobPostingStats,
            | "id"
            | "numShares"
            | "numCandidates"
            | "numInterviewsScheduled"
            | "numHires"
          >;
        })[];
  total: number | undefined;
  refreshList(): void;
  readOnly?: boolean;
  disableBorderRadius?: boolean;
  hideStats?: boolean;
  editingJobId: string | undefined;
  onCloseEditJob(): void;
  onOpenEditJob(jobPostingId: string): void;
  onAddJobPosting?(): void;
  getCloseJobConfirmationDescription(jobPostingTitle: string): string;
  duplicateJobConfirmationDescription: string;
  editJobDescription: string;
  duplicateJobSubmitButtonLabel: string;
  duplicateJobSuccessSnackbarMessage: string;
}

export function AdminJobsDataTable({
  currentPageIndex,
  perPage,
  loading,
  setCurrentPageIndex,
  items,
  total,
  refreshList,
  readOnly,
  hideStats,
  disableBorderRadius,
  editingJobId,
  onCloseEditJob,
  onOpenEditJob,
  onAddJobPosting,
  getCloseJobConfirmationDescription,
  duplicateJobSubmitButtonLabel,
  duplicateJobSuccessSnackbarMessage,
  editJobDescription,
  duplicateJobConfirmationDescription,
}: AdminJobsDataTableProps) {
  const myBranchesQuery = useMyBranches();
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  const [editJobDrawer, editJobDrawerActions] = useDrawerControl(editingJobId);
  const [
    duplicateConfirmationModalOpen,
    setDuplicateConfirmationModalOpen,
  ] = useState(false);
  const [closingJobPostingId, setClosingJobPostingId] = useState<
    string | undefined
  >();
  const [duplicatingJobPostingId, setDuplicatingJobPostingId] = useState<
    string | undefined
  >();
  const [updatingPromotionStatus, setUpdatingPromotionStatus] = useState<
    | {
        jobPostingId: string;
        promoted: boolean;
        jobPostingTitle: string;
        promotionId?: string;
      }
    | undefined
  >();

  const handleEditJobPosting = (id: string) => {
    onOpenEditJob(id);
  };

  const organizationHasBranches =
    (myBranchesQuery.data?.getMyRewardsOrganization.branches ?? []).length > 0;

  return (
    <>
      <DataTable
        itemsPerPage={perPage}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        emptyText="No jobs have been created yet."
        loading={loading}
        items={items}
        total={total}
        disableBorderRadius={disableBorderRadius}
        tableHeaders={
          <TableRow>
            <TableHeader>
              <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                Job Title
              </Typography>
            </TableHeader>
            {organizationHasBranches && (
              <TableHeader>
                <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                  Branch
                </Typography>
              </TableHeader>
            )}
            <TableHeader>
              <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                Location
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                Status
              </Typography>
            </TableHeader>
            {!hideStats && (
              <TableHeader>
                <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                  Stats
                </Typography>
              </TableHeader>
            )}

            <TableHeader align="right">
              <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                {readOnly ? "View Details" : "Actions"}
              </Typography>
            </TableHeader>
          </TableRow>
        }
        tableBody={items?.map((item) => (
          <TableRow key={item.id}>
            <TableCell divider>
              <Typography variant="body" color="textSecondary">
                {item.title}
              </Typography>
            </TableCell>
            {organizationHasBranches && (
              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {item.branch?.name ?? "-"}
                </Typography>
              </TableCell>
            )}
            <TableCell divider>
              <Typography variant="body" color="textSecondary">
                {item.geography}
              </Typography>
            </TableCell>
            <TableCell divider>
              <Typography variant="body" color="textSecondary">
                {(() => {
                  if (item.closedForSubmission) {
                    return "Closed";
                  } else {
                    if (item.activePromotion) {
                      return "Open (Promoted)";
                    } else {
                      return "Open";
                    }
                  }
                })()}
              </Typography>
            </TableCell>
            {item.stats && !hideStats && (
              <TableCell
                divider
                css={css`
                  padding: 0;
                `}
              >
                <Tooltip
                  css={(theme: AppTheme) => css`
                    padding: ${theme.spacing(1.5)};
                  `}
                  placement="right"
                  title={<JobStatsTooltipContent stats={item.stats} />}
                >
                  <Typography
                    css={css`
                      cursor: default;
                    `}
                    variant="body"
                    color="primary"
                  >
                    View Stats
                  </Typography>
                </Tooltip>
              </TableCell>
            )}

            <TableCell divider align="right">
              {readOnly ? (
                <IconButton
                  css={css`
                    margin-top: -14px;
                    margin-bottom: -14px;
                  `}
                  onClick={() => {
                    handleEditJobPosting(item.id);
                  }}
                  aria-label="View job posting details"
                >
                  <OpenInNew
                    css={css`
                      height: 0.8em;
                      width: 0.8em;
                    `}
                  />
                </IconButton>
              ) : (
                <ListActions
                  menuOptions={compact([
                    !item.closedForSubmission && {
                      label: "Edit",
                      onClick: () => {
                        handleEditJobPosting(item.id);
                      },
                    },
                    {
                      label: "Duplicate",
                      onClick: () => {
                        setDuplicateConfirmationModalOpen(true);
                        setDuplicatingJobPostingId(item.id);
                      },
                    },
                    !item.closedForSubmission && {
                      label: item.activePromotion ? "End Promotion" : "Promote",
                      onClick: () => {
                        setUpdatingPromotionStatus({
                          jobPostingId: item.id,
                          promoted: item.activePromotion !== null,
                          jobPostingTitle: item.title,
                          promotionId: item.activePromotion?.id,
                        });
                      },
                    },
                    !item.closedForSubmission && {
                      label: "Close",
                      onClick: () => {
                        setClosingJobPostingId(item.id);
                      },
                    },
                  ])}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      />

      <EditJobRightDrawer
        open={editJobDrawer.open}
        jobPostingId={editJobDrawer.state}
        onUpdated={() => refreshList()}
        onClose={onCloseEditJob}
        onExited={editJobDrawerActions.onExited}
        saveDescription={editJobDescription}
      />

      <ConfirmPromoteModal
        jobPosting={updatingPromotionStatus ?? null}
        onClose={() => setUpdatingPromotionStatus(undefined)}
        onSuccess={() => {
          refreshList();
        }}
      />

      <ConfirmCloseJobModal
        onClose={() => {
          setClosingJobPostingId(undefined);
        }}
        onSuccess={() => {
          refreshList();
        }}
        jobPostingId={closingJobPostingId ?? null}
        getDescription={getCloseJobConfirmationDescription}
      />

      <ConfirmDuplicateModal
        open={duplicateConfirmationModalOpen && !!duplicatingJobPostingId}
        onCancel={() => {
          setDuplicateConfirmationModalOpen(false);
          setDuplicatingJobPostingId(undefined);
        }}
        onConfirm={() => {
          setDuplicateConfirmationModalOpen(false);
        }}
        description={duplicateJobConfirmationDescription}
      />

      <AddNewJobModal
        open={!duplicateConfirmationModalOpen && !!duplicatingJobPostingId}
        onClose={() => {
          setDuplicatingJobPostingId(undefined);
        }}
        onAdded={() => {
          refreshList();
          onAddJobPosting?.();
        }}
        duplicatingJobPostingId={duplicatingJobPostingId ?? null}
        submitButtonLabel={duplicateJobSubmitButtonLabel}
        successSnackbarMessage={duplicateJobSuccessSnackbarMessage}
      />
    </>
  );
}
