/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { AppTheme } from "../../../style/theme";
import { Button } from "../../button";
import { Typography } from "../../typography";
import { CHOOSE_IMAGE_BUTTON_TEXT } from "../constants";

interface FileInputActionsProps {
  description?: ReactNode;
  openFilePicker(): void;
  selectedFileUrl: string | null;
  error: string | undefined;
}

export function FileInputActions({
  description,
  openFilePicker,
  selectedFileUrl,
  error,
}: FileInputActionsProps) {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${theme.spacing(2)};
        border: 1px solid ${theme.palette.divider};
        border-top: none;
        border-bottom-left-radius: ${theme.shape.borderRadius}px;
        border-bottom-right-radius: ${theme.shape.borderRadius}px;

        ${Boolean(error) &&
        css`
          border-color: ${theme.palette.error.main};
        `}
      `}
    >
      <Typography
        variant="caption"
        css={(theme: AppTheme) => css`
          font-size: 0.83em;
          font-style: italic;
          color: #7f8e9f;
          ${Boolean(error) &&
          css`
            color: ${theme.palette.error.main};
            font-weight: 800;
            font-style: initial;
          `}
        `}
      >
        {error ?? description}
      </Typography>
      <Button
        size="small"
        width="auto"
        noWrap={newThemeEnabled}
        variant="outlined"
        label={selectedFileUrl ? "Change Image" : CHOOSE_IMAGE_BUTTON_TEXT}
        onClick={openFilePicker}
      />
    </div>
  );
}
