/** @jsxImportSource @emotion/react */
import {
  SurveyInsightsDateRangeInput,
  SurveyQuestionType,
} from "@rewards-web/shared/graphql-types";

import { SurveyQuestionTypeSupportedByUI } from "../../constants";
import { NpsQuestionScoreCard } from "./nps-question-score-card";
import { ScoreQuestionScoreCard } from "./score-question-score-card";

export interface SurveyQuestionScoreCardProps {
  questionType: SurveyQuestionTypeSupportedByUI;
  dateRangeInput?: SurveyInsightsDateRangeInput;
}

export function SurveyQuestionScoreCard({
  questionType,
  dateRangeInput,
}: SurveyQuestionScoreCardProps) {
  if (questionType === SurveyQuestionType.Nps) {
    return <NpsQuestionScoreCard dateRangeInput={dateRangeInput} />;
  }

  return (
    <ScoreQuestionScoreCard
      questionType={questionType}
      dateRangeInput={dateRangeInput}
    />
  );
}
