/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { times } from "lodash";

import { Card } from "@rewards-web/shared/components/card";
import { ProgressBar } from "@rewards-web/shared/components/progress-bar";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SurveyQuestionTypeSupportedByUI } from "../../constants";
import { scoreToProgressValue } from "../../pulse-surveys/utils";

export interface ScoreComparisonCardProps {
  previousScores: { score: number | null; label: string; year: string }[];
  surveyQuestionType: SurveyQuestionTypeSupportedByUI;
}

export function ScoreComparisonCard({
  previousScores,
  surveyQuestionType,
}: ScoreComparisonCardProps) {
  const theme = useTheme();
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  // stretch progress bar to fit the height of the card
  const progressBarHeight =
    surveyQuestionType === SurveyQuestionType.Nps ? "350px" : "144px";

  const scoreLabels = (() => {
    if (surveyQuestionType === SurveyQuestionType.Nps) {
      return [100, 50, 0, -50, -100].map((score) => (
        <Typography key={score} variant="footnote" color="textPrimary">
          {score}
        </Typography>
      ));
    }

    return times(5, (index) => {
      const score = 5 - index;
      return (
        <Typography key={index} variant="footnote" color="textPrimary">
          {score} pt{score > 1 ? "s" : ""}
        </Typography>
      );
    });
  })();

  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(3)};
          box-sizing: border-box;
          // ensure 2 cards per row, at the most
          width: calc((100% - ${theme.spacing(3)}) / 2);
          min-width: 450px;
        `
      }
    >
      <Typography
        variant={newThemeEnabled ? "h6" : "h3"}
        fontWeight={700}
        css={(theme: AppTheme) => css`
          ${newThemeEnabled &&
          css`
            margin-bottom: ${theme.spacing(2.5)};
          `}
        `}
      >
        Quarterly Scores
      </Typography>
      <div
        css={css`
          display: flex;
          align-items: center;
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            text-align: center;
            flex: 1;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: ${progressBarHeight};
              align-self: flex-start;
            `}
          >
            {scoreLabels}
          </div>
          <div
            css={css`
              flex: 1;
              display: flex;
              justify-content: space-around;
            `}
          >
            {previousScores.map((previousScore, i) => {
              return (
                <div key={i}>
                  <Tooltip title={previousScore.score ?? ""} placement="top">
                    <div
                      css={(theme: AppTheme) => css`
                        height: ${progressBarHeight};
                        padding: ${theme.spacing(0.5)};
                      `}
                    >
                      <ProgressBar
                        value={scoreToProgressValue(
                          previousScore.score,
                          surveyQuestionType
                        )}
                        orientation="vertical"
                        greyBackground={true}
                        barWidth={32}
                        borderRadius={100}
                      />
                    </div>
                  </Tooltip>
                  <Typography
                    variant="footnote"
                    color={theme.palette.grey[800]}
                    css={(theme: AppTheme) =>
                      css`
                        margin-top: ${theme.spacing(1)};
                      `
                    }
                  >
                    {previousScore.label}/{previousScore.year.slice(-2)}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}
