/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faArrowsRotate,
  faCalendar,
  faDollar,
  faWallet,
} from "@fortawesome/pro-regular-svg-icons";
import { faDollarCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInDays, format } from "date-fns";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Form } from "@rewards-web/shared/components/form";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  AdminGoalRewardsType,
  AdminGoalTargetType,
  AdminGoalType,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useAdminGoalAdminListQuery } from "./admin-goal-admin-list-data.generated";
import { AdminGoalConfigurationAdminList } from "./admin-goal-configuration-admin-list";
import { useLaunchAdminGoalConfigMutation } from "./launch-admin-goal-config.generated";
import { LaunchGoalConfirmationModal } from "./launch-goal-confirmation-modal";

type AdminGoalFormValues = {
  adminIds: string[];
  rewardAmount: number;
  targetPercentage: number;
  startDate: Date;
  endDate: Date;
};

type BaseGoalConfigurationFormProps = {
  goalType: AdminGoalType;
  goalTitle: string;
  goalPeriod: string;
  defaultValues: AdminGoalFormValues;
  rewardAmountDisabled?: boolean;
  targetPercentageDisabled?: boolean;
};

export function BaseGoalConfigurationForm({
  goalType,
  goalTitle,
  goalPeriod,
  defaultValues,
  rewardAmountDisabled,
  targetPercentageDisabled,
}: BaseGoalConfigurationFormProps): JSX.Element {
  const track = useTrack();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [launchAdminGoal] = useLaunchAdminGoalConfigMutation();

  const form = useForm<AdminGoalFormValues>({
    mode: "onChange",
    defaultValues,
  });

  const { control, formState, handleSubmit, setValue } = form;

  const [adminIds, rewardAmount, endDate] = useWatch({
    control,
    name: ["adminIds", "rewardAmount", "endDate"],
  });

  const adminsListQuery = useAdminGoalAdminListQuery({
    variables: {
      budgetsEnabled:
        goalType === AdminGoalType.BudgetUtilization ? true : undefined,
    },
    onError: reportError,
    onCompleted: (data) => {
      // all admins should be selected by default
      if (adminIds.length === 0) {
        setValue(
          "adminIds",
          data.listRewardsAdmins.items.map((admin) => admin.id)
        );
      }
    },
  });

  const totalBudget = (() => adminIds.length * rewardAmount)();

  // Ensure we always show at least 1 day left in the period
  const daysLeftInPeriod = Math.max(differenceInDays(endDate, new Date()), 1);

  // If there are 15 days or less left in the goal period we consider this a "late" launch,
  // and show a warning to the user when confirming.
  const lateLaunchingConfiguration = daysLeftInPeriod <= 15;

  const onSubmit = async (values: AdminGoalFormValues) => {
    await launchAdminGoal({
      variables: {
        adminIds: values.adminIds,
        rewards: {
          type: AdminGoalRewardsType.GiftCard,
          giftCardDollarValue: Number(values.rewardAmount),
        },
        target: {
          type: AdminGoalTargetType.Percentage,
          percentageValue: Number(values.targetPercentage),
        },
        startDate: format(values.startDate, "yyyy-MM-dd"),
        endDate: format(values.endDate, "yyyy-MM-dd"),
        goalType,
      },
      onError: (error) => {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "Your goal could not be launched. Please try again.",
        });
      },
      onCompleted: () => {
        snackbar.show({
          severity: "success",
          message: "Your goal has been successfully launched!",
        });
        navigate("/settings/office-goals");
      },
    });
  };

  if (adminsListQuery.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (!adminsListQuery.data) {
    return (
      <div
        css={css`
          height: 200px;
        `}
      >
        <PageLoadingState />
      </div>
    );
  }

  return (
    // submit is handled by confirmation modal
    <Form submitting={formState.isSubmitting} onSubmit={() => {}}>
      <Typography
        variant="h5"
        color="textPrimary"
        fontWeight={700}
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(1)};
          `
        }
      >
        Select admins who will receive rewards for accomplishing the{" "}
        {goalTitle.toLowerCase()}
      </Typography>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: right;
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        <Card
          variant="flat"
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <FontAwesomeIcon icon={faDollarCircle} width={16} />
            <Typography
              variant="footnote"
              color="textPrimary"
              fontWeight={400}
              css={(theme: AppTheme) => css`
                font-style: italic;
                margin-left: ${theme.spacing(1)};
                margin-right: ${theme.spacing(0.5)};
              `}
            >
              Total budget for reward
            </Typography>
            <Typography
              variant="footnote"
              color="textPrimary"
              fontWeight={700}
              css={css`
                font-style: italic;
              `}
            >
              ${totalBudget}
            </Typography>
          </div>
        </Card>
      </div>
      <Typography
        variant="footnote"
        color="grey.800"
        fontWeight={600}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
          text-transform: uppercase;
        `}
      >
        {adminIds.length} employee{adminIds.length === 1 ? "" : "s"} selected
      </Typography>
      <AdminGoalConfigurationAdminList
        loading={adminsListQuery.loading}
        allAdmins={adminsListQuery.data.listRewardsAdmins.items}
        selectedAdminIds={new Set(adminIds)}
        onAdminSelectionChange={(selectedAdminIds) =>
          setValue("adminIds", Array.from(selectedAdminIds))
        }
      />
      <Card
        variant="flat"
        css={(theme: AppTheme) =>
          css`
            padding: ${theme.spacing(2)};
            margin-top: ${theme.spacing(4)};
          `
        }
      >
        <Typography
          variant="h6"
          color="textPrimary"
          fontWeight={700}
          css={css`
            margin-bottom: 21px;
          `}
        >
          Goal configuration
        </Typography>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: top;
              gap: ${theme.spacing(2)};
              flex: 1;
            `}
          >
            <FontAwesomeIcon
              icon={faCalendar}
              width={18}
              css={css`
                margin-top: 4px;
              `}
            />
            <div>
              <Typography variant="body" color="textPrimary">
                Goal Period
              </Typography>
              <Typography
                variant="footnote"
                color="grey.800"
                css={(theme: AppTheme) => css`
                  margin-top: ${theme.spacing(1)};
                  max-width: 350px;
                `}
              >
                Goals include data from the start of the period, regardless of
                launch date
              </Typography>
            </div>
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <TextField
              label="Goal period"
              value={goalPeriod}
              disabled
              hideSpaceForErrorText
              css={css`
                max-width: 400px;
              `}
            />
          </div>
        </div>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(2)};
              flex: 1;
            `}
          >
            <FontAwesomeIcon icon={faArrowsRotate} width={18} />
            <Typography variant="body" color="textPrimary">
              Recurrence
            </Typography>
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <TextField
              label="Recurrence"
              value="One-time"
              disabled
              hideSpaceForErrorText
              css={css`
                max-width: 400px;
              `}
            />
          </div>
        </div>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(2)};
              flex: 1;
            `}
          >
            <div
              css={css`
                width: 18px;
              `}
            >
              <FontAwesomeIcon icon={faDollar} />
            </div>
            <Typography variant="body" color="textPrimary">
              Reward amount per admin
            </Typography>
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <Controller
              control={control}
              name="rewardAmount"
              rules={{
                required: "Reward amount is required",
                min: {
                  value: 1,
                  message: "Reward amount must be greater than 0",
                },
                max: {
                  // Note: this is a temporary limit while Caribou is sponsoring the goal
                  value: 20,
                  message: "Reward amount must be 20 or less",
                },
              }}
              render={({ fieldState }) => (
                <TextField
                  label="Reward amount"
                  type="number"
                  {...control.register("rewardAmount")}
                  error={fieldState.error}
                  startAdornment="$"
                  disabled={rewardAmountDisabled}
                  css={css`
                    max-width: 400px;
                  `}
                />
              )}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(2)};
              flex: 1;
            `}
          >
            <FontAwesomeIcon icon={faWallet} width={18} />
            <Typography variant="body" color="textPrimary">
              {goalTitle}
            </Typography>
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <Controller
              control={control}
              name="targetPercentage"
              rules={{
                required: "Goal target is required",
                min: {
                  value: 1,
                  message: "Goal target must be greater than 0",
                },
                max: {
                  value: 100,
                  message: "Goal target must be 100 or less",
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  label="Goal"
                  type="number"
                  {...control.register("targetPercentage")}
                  error={fieldState.error}
                  endAdornment={`${field.value}%`}
                  disabled={targetPercentageDisabled}
                  css={css`
                    max-width: 400px;
                  `}
                />
              )}
            />
          </div>
        </div>
      </Card>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: space-between;
          margin-top: ${theme.spacing(4)};
        `}
      >
        <Button
          variant="outlined"
          size="medium"
          width="auto"
          label="Cancel"
          linkTo="/settings/office-goals"
          onClick={() =>
            track("Cancelled configuring admin goal", {
              goalType,
            })
          }
        />
        <Button
          size="medium"
          color="primary"
          width="auto"
          label="Launch goal"
          onClick={() => {
            track("Viewed confirmation modal to launch admin goal", {
              goalType,
              lateLaunch: lateLaunchingConfiguration,
            });
            setConfirmationModalOpen(true);
          }}
          disabled={adminIds.length === 0 || !isEmpty(formState.errors)}
        />
      </div>
      <LaunchGoalConfirmationModal
        open={confirmationModalOpen}
        onClose={() => {
          track("Closed confirmation modal to launch admin goal", {
            goalType,
          });
          setConfirmationModalOpen(false);
        }}
        onConfirm={handleSubmit(onSubmit)}
        goalPeriod={goalPeriod}
        daysLeftInPeriod={daysLeftInPeriod}
        lateLaunch={lateLaunchingConfiguration}
        loading={formState.isSubmitting}
      />
    </Form>
  );
}
