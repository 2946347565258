/** @jsxImportSource @emotion/react */
import { BaseGoalConfigurationPage } from "../base-goal-configuration-page";
import { FirstMonthLoginGoalForm } from "./first-month-login-goal-form";

export function FirstMonthLoginGoalConfigurationPage(): JSX.Element {
  return (
    <BaseGoalConfigurationPage>
      <FirstMonthLoginGoalForm />
    </BaseGoalConfigurationPage>
  );
}
