/** @jsxImportSource @emotion/react */
import { ClassNames, Theme } from "@emotion/react";
import { lighten, LinearProgress } from "@material-ui/core";

export interface ProgressBarProps {
  value: number;
  barColor?: string;
  borderRadius?: number;
  greyBackground?: boolean;
  orientation?: "horizontal" | "vertical";
  barWidth?: number;
}

export function ProgressBar({
  value,
  barColor,
  borderRadius = 5,
  greyBackground = false,
  orientation = "horizontal",
  barWidth = 10,
}: ProgressBarProps): JSX.Element {
  const getBackgroundColor = (theme: Theme) => {
    if (greyBackground) {
      return "#f1f1f1";
    } else if (barColor === "background.default") {
      return lighten(theme.palette.primary.main, 0.1);
    } else {
      return "#fff";
    }
  };

  const getBarColor = (theme: Theme) => {
    if (!barColor) {
      return theme.palette.primary.main;
    } else if (barColor === "background.default") {
      return theme.palette.background.default;
    } else {
      return barColor;
    }
  };

  return (
    <ClassNames>
      {({ css, theme }) => (
        <LinearProgress
          css={css`
            min-width: ${orientation === "vertical" ? "auto" : "100%"};
            min-height: ${orientation === "vertical" ? "100%" : "auto"};
          `}
          classes={{
            root: css`
              height: ${orientation === "vertical" ? "auto" : `${barWidth}px`};
              width: ${orientation === "vertical" ? `${barWidth}px` : "auto"};
              border-radius: ${borderRadius}px;
            `,
            colorPrimary: css`
              background-color: ${getBackgroundColor(theme)};
            `,
            bar: css`
              border-radius: 5;
              background-color: ${getBarColor(theme)};
              transform: ${orientation === "vertical"
                ? `translateY(${100 - value}%) !important`
                : "auto"};
            `,
          }}
          variant="determinate"
          value={value}
        ></LinearProgress>
      )}
    </ClassNames>
  );
}
