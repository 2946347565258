/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";

import { Alert } from "@rewards-web/shared/components/alert";
import {
  SurveyInsightsDateRangeInput,
  SurveyQuestionType,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ResponseBreakdownCard } from "../../components/response-breakdown-card";
import { ScoreComparisonCard } from "../../components/score-comparison-card";
import { SurveyQuestionTypeSupportedByUI } from "../../constants";
import {
  getCurrentScoreForComparison,
  getPreviousScoresForComparison,
} from "../utils";
import { EmployeeFeedbackTable } from "./employee-feedback-table";
import { useSurveyQuestionDetailsPageDataQuery } from "./question-details-page-data.generated";

/**
 * Question details page contents for non-NPS questions, scored 1-5.
 */
export function ScoreQuestionDetailsPage({
  questionType,
  dateRangeInput,
}: {
  questionType: Exclude<
    SurveyQuestionTypeSupportedByUI,
    SurveyQuestionType.Nps
  >;
  dateRangeInput?: SurveyInsightsDateRangeInput;
}) {
  const { data, error } = useSurveyQuestionDetailsPageDataQuery({
    variables: {
      questionType,
      dateRange: dateRangeInput,
    },
    onError: reportError,
    skip: !dateRangeInput,
  });

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const previousScores = getPreviousScoresForComparison(
    data?.getSurveyQuestionResults.previousAverageScores ?? []
  );

  const currentScoreWithDateRange = getCurrentScoreForComparison({
    averageScore: data?.getSurveyQuestionResults.averageScore,
    dateRange: dateRangeInput,
  });

  const totalResponseCount = data?.getScoreDistributionForSurveyQuestion.reduce(
    (acc, { count }) => acc + count,
    0
  );

  return (
    <>
      {data && (
        <>
          {totalResponseCount === 0 ? (
            <Alert
              severity="info"
              message="No responses received during this time period."
            />
          ) : (
            <>
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  flex-wrap: wrap;
                  gap: ${theme.spacing(3)};
                `}
              >
                <ResponseBreakdownCard
                  questionType={questionType}
                  scores={data.getScoreDistributionForSurveyQuestion}
                />
                {previousScores?.length ? (
                  <ScoreComparisonCard
                    previousScores={compact([
                      ...previousScores,
                      currentScoreWithDateRange,
                    ])}
                    surveyQuestionType={questionType}
                  />
                ) : null}
              </div>
              <EmployeeFeedbackTable
                questionType={questionType}
                dateRangeInput={dateRangeInput}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
