/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate, Outlet, useMatch, useLocation } from "react-router";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { useScrollToTop } from "@rewards-web/shared/hooks/use-scroll-to-top";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { PageLayout } from "../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { useAdminAppAuthenticatedOrganizationConfigQuery } from "../admin-app-authenticated-organization-config.generated";
import { ONBOARDING_STEP_BY_PATH } from "./constants";
import { useOnboardingState } from "./hooks/use-onboarding-state";
import { OnboardingNavigationTabs } from "./navigation-tabs";

const MAX_CONTENT_WIDTH = 1200;

export function OnboardingPage(): JSX.Element {
  const onboardingState = useOnboardingState({ loadFreshData: true });
  const organizationWhiteLabelConfigQuery = useAdminAppAuthenticatedOrganizationConfigQuery();
  const rewardsProgramShortName =
    organizationWhiteLabelConfigQuery.data?.getMyRewardsOrganization
      ?.whiteLabelConfig?.rewardsProgramShortName ?? "Caribou Rewards";

  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  // navigate to the top whenever the path changes
  const location = useLocation();
  useScrollToTop([location.pathname]);

  const match = useMatch("/onboarding/:path/*");
  const currentStep = match?.pattern.path
    ? ONBOARDING_STEP_BY_PATH[match.params.path!]
    : null;

  if (onboardingState.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (onboardingState.loading) {
    // important to not show loading state when loading
    // because it may load intermittently due to step completion
    // and we should keep the current page open
    return <PageLoadingState />;
  }

  if (onboardingState.launched || onboardingState.disabled) {
    // if we're launched or if onboarding is disabled, navigate back to admin app index
    return <Navigate to="/" />;
  }

  const completedSteps = Array.from(onboardingState.completedSteps).filter(
    onboardingState.hasOnboardingStep
  );

  return (
    <>
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <AuthorizedAdminPageWrapper resource="onboarding">
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <div>
              <Typography
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(3)};
                `}
                variant={newThemeEnabled ? "h3" : "h1"}
                color="textPrimary"
              >
                Set Up {rewardsProgramShortName}
              </Typography>
              <Typography
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(3)};
                `}
                variant="body"
                color="textSecondary"
              >
                Follow our step-by-step by guide to launch your rewards program
              </Typography>
            </div>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Typography
                css={(appTheme: AppTheme) =>
                  css`
                    margin-right: ${appTheme.spacing(1.25)};
                  `
                }
              >
                Questions about onboarding?
              </Typography>
              <Button
                label="Help guide"
                externalLink
                linkTo="https://support.caribou.care/hc/en-us/articles/26187623490711-Onboarding"
                color="primary"
                size="small"
                width="auto"
                target="_blank"
                startIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
              />
            </div>
          </div>

          <Divider />

          <div
            css={(theme: AppTheme) => css`
              display: grid;
              grid-template-columns: 25% 75%;
              padding-top: ${theme.spacing(1)};
            `}
          >
            <OnboardingNavigationTabs
              orderedSteps={onboardingState.orderedSteps}
              currentStep={currentStep}
              completedSteps={completedSteps}
            />
            <div
              css={(theme: AppTheme) => css`
                padding: ${theme.spacing(5)} ${theme.spacing(2)};
                ${theme.breakpoints.up("lg")} {
                  padding: ${theme.spacing(5)} ${theme.spacing(5)};
                }
              `}
            >
              <Outlet />
            </div>
          </div>
        </AuthorizedAdminPageWrapper>
      </PageLayout>
    </>
  );
}
