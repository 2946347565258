/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";
import { ReactNode } from "react";

import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  Maybe,
  RewardsAdminUser,
  RewardsOrganizationBranch,
  RewardsUser,
} from "@rewards-web/shared/graphql-types";
import { useDrawerControl } from "@rewards-web/shared/hooks/use-drawer-control";
import { formatPhoneNumber } from "@rewards-web/shared/lib/phone-number-format";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { DataTable } from "../../../../../components/data-table";
import { ListActions } from "../../../../../components/list-actions";
import { useMyBranches } from "../../../../branches/use-my-branches";
import { EditEmployeeDrawer } from "../edit-employee-modal";
import { EditIcon } from "./icons/edit-icon";
import { SendIcon } from "./icons/send-icon";
import { ViewIcon } from "./icons/view-icon";
import { useSendInvitationLinkToEmployeeMutation } from "./send-invitation-link-to-employee.generated";
import { SendLinkToEmployeeButton } from "./send-link-to-employee-button";
import { useSendLoginLinkToEmployeeMutation } from "./send-login-link-to-employee.generated";
import { SendRecognitionToEmployeeButton } from "./send-recognition-to-employee-button";

export type RewardsUserPartial = {
  __typename?: "RewardsUser" | undefined;
} & Pick<
  RewardsUser,
  | "id"
  | "firstName"
  | "lastName"
  | "personalContactInfo"
  | "workEmail"
  | "workPhoneNumber"
  | "active"
  | "hasReceivedRecognitionPointsInPast30Days"
  | "hasLoggedInToAppInPast30Days"
  | "lastLoginDate"
> & {
    branch?: Maybe<
      { __typename?: "RewardsOrganizationBranch" } & Pick<
        RewardsOrganizationBranch,
        "id" | "name"
      >
    >;
    manager?: Maybe<
      { __typename?: "RewardsAdminUser" } & Pick<
        RewardsAdminUser,
        "id" | "firstName" | "lastName"
      >
    >;
  };
export interface EmployeesDataTableProps {
  currentPageIndex: number;
  setCurrentPageIndex(currentPageIndex: number): void;
  perPage: number;
  loading: boolean;
  items: undefined | RewardsUserPartial[];
  total: number | undefined;
  refreshList(): void;
  readOnly?: boolean;
  disableBorderRadius?: boolean;
  usesWorkDevices: boolean;
  integratedWithAlayaCare: boolean;
  managersEnabled: boolean;
  referralsEnabled: boolean;
  canSendRecognitionPoints: boolean;
  rewardsProgramShortName: string;
  onDeactivateEmployee?(): void;
  onActivateEmployee?(): void;
  editingEmployeeId: string | undefined;
  onCloseEditEmployee(): void;
  onOpenEditEmployee(EmployeePostingId: string): void;
  title?: ReactNode;
}

export function EmployeesDataTable({
  currentPageIndex,
  setCurrentPageIndex,
  perPage,
  loading,
  items,
  total,
  refreshList,
  readOnly,
  disableBorderRadius,
  onDeactivateEmployee,
  onActivateEmployee,
  usesWorkDevices,
  integratedWithAlayaCare,
  managersEnabled,
  referralsEnabled,
  rewardsProgramShortName,
  canSendRecognitionPoints,
  editingEmployeeId,
  onCloseEditEmployee,
  onOpenEditEmployee,
  title,
}: EmployeesDataTableProps) {
  const myBranchesQuery = useMyBranches();
  const [editJobDrawer, editJobDrawerActions] = useDrawerControl(
    editingEmployeeId
  );
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  const employeePageUpdatesEnabled = useFeatureFlag(
    "admin-app-employee-page-updates-temp"
  );
  const track = useTrack();
  const snackbar = useSnackbar();

  const hasBranches =
    (myBranchesQuery.data?.getMyRewardsOrganization.branches ?? []).length > 0;

  // TODO: remove this once we enable the feature flag
  const [
    sendLoginLinkToEmployee,
    { loading: sendingLoginLinkToEmployee },
  ] = useSendLoginLinkToEmployeeMutation();
  const [
    sendInvitationLinkToEmployee,
    { loading: sendingInvitationLinkToEmployee },
  ] = useSendInvitationLinkToEmployeeMutation();
  const snackbarMessage = `A link to open ${rewardsProgramShortName} has been texted and/or emailed to the user's phone number and email.`;

  const handleSendLoginLinkToEmployee = async (employeeId: string) => {
    try {
      const res = await sendLoginLinkToEmployee({
        variables: {
          employeeId,
        },
      });
      track("Sent login link to employee", {
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
        employeeId,
      });
      snackbar.show({
        severity: "success",
        message: snackbarMessage,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
  };

  const handleSendInvitationLinkToEmployee = async (employeeId: string) => {
    try {
      const res = await sendInvitationLinkToEmployee({
        variables: {
          employeeId,
        },
      });
      track("Sent invitation link to employee", {
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
        employeeId,
      });
      snackbar.show({
        severity: "success",
        message: snackbarMessage,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
  };

  return (
    <>
      <DataTable
        title={title}
        disableBorderRadius={disableBorderRadius}
        itemsPerPage={perPage}
        currentPageIndex={currentPageIndex}
        onCurrentPageIndexChange={setCurrentPageIndex}
        loading={loading}
        items={items}
        total={total}
        tableHeaders={
          <TableRow>
            <TableHeader>
              <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                Name
              </Typography>
            </TableHeader>
            {hasBranches && (
              <TableHeader>
                <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                  Branch
                </Typography>
              </TableHeader>
            )}
            <TableHeader>
              <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                Status
              </Typography>
            </TableHeader>
            {usesWorkDevices ? (
              <>
                <TableHeader>
                  <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                    Work phone
                  </Typography>
                </TableHeader>
                <TableHeader>
                  <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                    Work email
                  </Typography>
                </TableHeader>
              </>
            ) : (
              <>
                <TableHeader>
                  <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                    Cell phone
                  </Typography>
                </TableHeader>
                <TableHeader>
                  <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                    Email
                  </Typography>
                </TableHeader>
              </>
            )}
            {managersEnabled && (
              <TableHeader>
                <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                  Manager
                </Typography>
              </TableHeader>
            )}
            {employeePageUpdatesEnabled && (
              <>
                <TableHeader width={180}>
                  <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                    Recognition points earned in last 30 days
                  </Typography>
                </TableHeader>
                <TableHeader width={180}>
                  <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                    Logins in last 30 days
                  </Typography>
                </TableHeader>
                <TableHeader width={180}>
                  <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                    Last login date
                  </Typography>
                </TableHeader>
              </>
            )}
            <TableHeader align="right">
              <Typography variant={newThemeEnabled ? "subtitle" : "body"}>
                Actions
              </Typography>
            </TableHeader>
          </TableRow>
        }
        tableBody={items?.map((user) => {
          return (
            <TableRow key={user.id}>
              <TableCell divider>
                <ObscureRecordedText>
                  <Typography variant="body" color="textSecondary">
                    {user.firstName} {user.lastName}
                  </Typography>
                </ObscureRecordedText>
              </TableCell>
              {hasBranches && (
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    {user.branch?.name ?? "-"}
                  </Typography>
                </TableCell>
              )}
              <TableCell divider>
                <Typography variant="body" color="textSecondary">
                  {user.active ? "Active" : "De-activated"}
                </Typography>
              </TableCell>
              {usesWorkDevices ? (
                <>
                  <TableCell divider>
                    <Typography variant="body" color="textSecondary">
                      {user.workPhoneNumber
                        ? formatPhoneNumber(user.workPhoneNumber)
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell divider>
                    <Typography variant="body" color="textSecondary">
                      {user.workEmail || "-"}
                    </Typography>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell divider>
                    <Typography variant="body" color="textSecondary">
                      {(() => {
                        if (
                          user.personalContactInfo?.__typename ===
                          "RewardsUserPersonalContactInfoSuppressed"
                        ) {
                          return "(Suppressed)";
                        }

                        if (
                          user.personalContactInfo?.__typename ===
                          "RewardsUserPersonalContactInfoData"
                        ) {
                          return user.personalContactInfo.phoneNumber;
                        }

                        return "-";
                      })()}
                    </Typography>
                  </TableCell>
                  <TableCell divider>
                    <Typography variant="body" color="textSecondary">
                      {(() => {
                        if (
                          user.personalContactInfo?.__typename ===
                          "RewardsUserPersonalContactInfoSuppressed"
                        ) {
                          return "(Suppressed)";
                        }

                        if (
                          user.personalContactInfo?.__typename ===
                          "RewardsUserPersonalContactInfoData"
                        ) {
                          return user.personalContactInfo.email;
                        }

                        return "-";
                      })()}
                    </Typography>
                  </TableCell>
                </>
              )}
              {managersEnabled && (
                <TableCell divider>
                  <Typography variant="body" color="textSecondary">
                    {user.manager
                      ? `${user.manager.firstName} ${user.manager.lastName}`
                      : "-"}
                  </Typography>
                </TableCell>
              )}
              {employeePageUpdatesEnabled && (
                <>
                  <TableCell divider>
                    <div
                      css={(theme: AppTheme) => css`
                        display: flex;
                        align-items: center;
                        gap: ${theme.spacing(2.5)};
                      `}
                    >
                      <Typography
                        variant="body"
                        color="textSecondary"
                        width={24}
                      >
                        {user.hasReceivedRecognitionPointsInPast30Days
                          ? "Yes"
                          : "No"}
                      </Typography>
                      {user.active && canSendRecognitionPoints && (
                        <SendRecognitionToEmployeeButton employee={user} />
                      )}
                    </div>
                  </TableCell>
                  <TableCell divider>
                    <div
                      css={(theme: AppTheme) => css`
                        display: flex;
                        align-items: center;
                        gap: ${theme.spacing(2.5)};
                      `}
                    >
                      <Typography
                        variant="body"
                        color="textSecondary"
                        width={24}
                      >
                        {user.hasLoggedInToAppInPast30Days ? "Yes" : "No"}
                      </Typography>
                      {user.active &&
                        (user.personalContactInfo || usesWorkDevices) && (
                          <SendLinkToEmployeeButton
                            employee={user}
                            rewardsProgramShortName={rewardsProgramShortName}
                            usesWorkDevices={usesWorkDevices}
                          />
                        )}
                    </div>
                  </TableCell>
                  <TableCell divider>
                    <Typography variant="body" color="textSecondary">
                      {user.lastLoginDate
                        ? new Date(user.lastLoginDate).toLocaleString("en-us", {
                            timeZone: "UTC",
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })
                        : "-"}
                    </Typography>
                  </TableCell>
                </>
              )}
              <TableCell divider align="right">
                <ListActions
                  menuOptions={compact([
                    !readOnly && {
                      label: "Edit Details",
                      icon: <EditIcon />,
                      onClick: () => {
                        onOpenEditEmployee(user.id);
                      },
                    },
                    readOnly && {
                      label: "View Details",
                      icon: <ViewIcon />,
                      onClick: () => {
                        onOpenEditEmployee(user.id);
                      },
                    },
                    !employeePageUpdatesEnabled &&
                      usesWorkDevices &&
                      user.active &&
                      !user.personalContactInfo && {
                        label: `Send Invite to ${user.firstName}`,
                        icon: <SendIcon />,
                        disabled: sendingInvitationLinkToEmployee,
                        onClick: () => {
                          handleSendInvitationLinkToEmployee(user.id);
                        },
                      },
                    !employeePageUpdatesEnabled &&
                      user.active &&
                      user.personalContactInfo && {
                        label: `Send Link to ${user.firstName}`,
                        icon: <SendIcon />,
                        disabled: sendingLoginLinkToEmployee,
                        onClick: () => {
                          handleSendLoginLinkToEmployee(user.id);
                        },
                      },
                  ])}
                />
              </TableCell>
            </TableRow>
          );
        })}
      />

      <EditEmployeeDrawer
        open={editJobDrawer.open}
        userId={editJobDrawer.state}
        onUpdated={() => refreshList()}
        onClose={onCloseEditEmployee}
        onExited={editJobDrawerActions.onExited}
        usesWorkDevices={usesWorkDevices}
        integratedWithAlayaCare={integratedWithAlayaCare}
        managersEnabled={managersEnabled}
        referralsEnabled={referralsEnabled}
        onDeactivateEmployee={onDeactivateEmployee}
        onActivateEmployee={onActivateEmployee}
      />
    </>
  );
}
