/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { lighten } from "@material-ui/core";
import { Link } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";
import {
  NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment,
  NumActiveCandidatesByJobPostingAndStatusItemTotalRetention,
} from "@rewards-web/shared/graphql-types";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { getCandidatesFilterPathFromStep } from "../../lib";

interface StatusLinkProps {
  jobPostingId?: string;
  hovered?: boolean;
  disableTab?: boolean;
  stepStatus:
    | ({
        __typename: "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment";
      } & Pick<
        NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment,
        "recruitmentStepName" | "numActiveCandidates"
      >)
    | ({
        __typename: "NumActiveCandidatesByJobPostingAndStatusItemTotalRetention";
      } & Pick<
        NumActiveCandidatesByJobPostingAndStatusItemTotalRetention,
        "retentionDurationMonths" | "numActiveCandidates"
      >);
}

export function StatusLink({
  jobPostingId,
  stepStatus,
  hovered = false,
  disableTab,
}: StatusLinkProps): JSX.Element {
  const trackScreenRecording = useTrackScreenRecordingEvent();
  const track = useTrack();
  const theme = useTheme();
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  const hasNoCandidates = stepStatus.numActiveCandidates === 0;

  const hoverState = css`
    transform: translate(-1px, -5px);
    background-color: ${theme.palette.primary.main};
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  `;

  return (
    <Link
      tabIndex={disableTab ? -1 : undefined}
      css={css`
        ${hasNoCandidates &&
        css`
          cursor: default;
        `}

        text-decoration: none;
        height: 100%;
        width: 100%;
      `}
      onClick={() => {
        trackScreenRecording("hiring_pipeline_block_clicked");
        track("Hiring pipeline block clicked", {
          jobPostingId,
          stepStatus:
            stepStatus.__typename ===
            "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment"
              ? { recruitmentStep: stepStatus.recruitmentStepName }
              : { retentionDurationMonths: stepStatus.retentionDurationMonths },
        });
      }}
      to={
        !hasNoCandidates && jobPostingId
          ? getCandidatesFilterPathFromStep(stepStatus, jobPostingId)
          : ""
      }
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          height: 55px;
          width: 125px;
          background-color: ${hasNoCandidates
            ? lighten(theme.palette.primary.main, 0.9)
            : theme.palette.primary.main};
          border-radius: 5px;
          justify-content: center;
          opacity: 0.9;

          ${hovered && hoverState}

          ${!hasNoCandidates &&
          css`
            :hover {
              ${hoverState}
            }
            transition: all 250ms;
          `}
        `}
      >
        {!hasNoCandidates && (
          <>
            <Typography
              display="inline"
              variant={newThemeEnabled ? "body" : "h3"}
              css={(theme: AppTheme) => css`
                color: #fff;
                font-weight: bold;
                margin-right: ${theme.spacing(0.5)};
              `}
            >
              {stepStatus.numActiveCandidates}
            </Typography>
            <Typography
              display="inline"
              css={css`
                color: #fff;
              `}
              variant={newThemeEnabled ? "body" : undefined}
            >
              (Candidates)
            </Typography>
          </>
        )}
      </div>
    </Link>
  );
}
