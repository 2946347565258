/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Navigate, useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsAdminPermissionsType } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { EmployeeAutoSyncIntegrations } from "../../../../shared/modules/employee-auto-sync/components/employee-auto-sync-integrations";
import { useAutoSyncIntegrationsTableDataQuery } from "../../../../shared/modules/employee-auto-sync/components/employee-auto-sync-integrations/auto-sync-integrations-table-data.generated";
import { usePermissionsQuery } from "../../../../shared/modules/permissions/hooks/use-permissions-query";
import { useAutoSyncPageDataQuery } from "./auto-sync-page-data.generated";

export function AutoSyncPage(): JSX.Element {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const query = useAutoSyncPageDataQuery({
    onError: reportError,
  });
  const integrationsQuery = useAutoSyncIntegrationsTableDataQuery({
    onError: reportError,
  });
  const permissionsQuery = usePermissionsQuery();

  if (query.error || permissionsQuery.error || integrationsQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!query.data || !permissionsQuery.data || !integrationsQuery.data) {
    return <PageLoadingState />;
  }

  const isFullAccessAdmin =
    permissionsQuery.data.getMyRewardsAdminUser.permissions.type ===
    RewardsAdminPermissionsType.FullAccess;

  if (query.data && !isFullAccessAdmin) {
    return <Navigate to="/" replace />; // Page currently only available to full access admins
  }

  return (
    <>
      <Typography
        variant="h3"
        color="textPrimary"
        fontWeight={700}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        Auto-sync
      </Typography>
      <Typography
        variant="body"
        color="textPrimary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(5)};
        `}
      >
        Caribou will automatically create, update, and deactivate employee
        accounts continuously to ensure that your reward program list remains
        up-to-date.
      </Typography>

      <EmployeeAutoSyncIntegrations
        data={integrationsQuery.data}
        disableEdit // can only edit during onboarding for now
        onClickSetUp={(integration) => {
          switch (integration.__typename) {
            case "HHAXIntegration":
              navigate(`/employees/auto-sync/enable-hhax/${integration.id}`);
              break;
            default:
              snackbar.show({
                severity: "error",
                message: "Auto-sync is not supported for this integration.",
              });
          }
        }}
        onClickEdit={(integration) => {
          switch (integration.__typename) {
            case "HHAXIntegration":
              navigate(
                `/employees/auto-sync/edit-hhax/${
                  integration.userAutoSyncs[0]!.id
                }`
              );
              break;
            default:
              snackbar.show({
                severity: "error",
                message: "Auto-sync is not supported for this integration.",
              });
          }
        }}
      />
    </>
  );
}
