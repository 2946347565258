/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { lighten } from "@material-ui/core";
import { compact } from "lodash";
import { useMemo } from "react";

import { Table } from "@rewards-web/shared/components/table-components/table";
import { TableBody } from "@rewards-web/shared/components/table-components/table-body";
import { TableCell } from "@rewards-web/shared/components/table-components/table-cell";
import { TableHeader } from "@rewards-web/shared/components/table-components/table-header";
import { TableRow } from "@rewards-web/shared/components/table-components/table-row";
import { Typography } from "@rewards-web/shared/components/typography";
import { CandidateRecruitmentStepName } from "@rewards-web/shared/graphql-types";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { formatPointsAndDollars } from "@rewards-web/shared/lib/format-reward-points";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { QuestionMarkIcon } from "../question-mark-icon";
import { ClockIcon } from "./icons/clock-icon";
import { DocumentCheckIcon } from "./icons/document-check";
import { DollarIcon } from "./icons/dollar-icon";
import { PeopleIcon } from "./icons/people-icon";
import { ShareIcon } from "./icons/share-icon";
import { UserCardIcon } from "./icons/user-card-icon";
import { useRewardsStructureQuery } from "./rewards-structure.generated";

type DescriptionAndIcon = {
  description: string;
  icon: JSX.Element;
};

export const RECRUITMENT_ITEM_DESCRIPTION_AND_ICONS: {
  [key in CandidateRecruitmentStepName | "JobShared"]: DescriptionAndIcon;
} = {
  JobShared: {
    description: "Job Shared",
    icon: <ShareIcon />,
  },
  [CandidateRecruitmentStepName.ApplicationSubmitted]: {
    description: "Candidate expresses interest",
    icon: <PeopleIcon />,
  },
  [CandidateRecruitmentStepName.CompletedOrientation]: {
    description: "Completed orientation",
    icon: <DocumentCheckIcon />,
  },
  [CandidateRecruitmentStepName.CompletedFirstShift]: {
    description: "Completed first shift",
    icon: <ClockIcon />,
  },
  [CandidateRecruitmentStepName.Contacted]: {
    description: "Candidate contacted",
    icon: <PeopleIcon />,
  },
  [CandidateRecruitmentStepName.Hired]: {
    description: "Hired",
    icon: <DocumentCheckIcon />,
  },
  [CandidateRecruitmentStepName.InterviewScheduled]: {
    description: "Interview scheduled",
    icon: <PeopleIcon />,
  },
  [CandidateRecruitmentStepName.InterviewSuccessful]: {
    description: "Interview successful",
    icon: <DocumentCheckIcon />,
  },
  [CandidateRecruitmentStepName.StartedOrientation]: {
    description: "Started orientation",
    icon: <PeopleIcon />,
  },
  [CandidateRecruitmentStepName.StartedWork]: {
    description: "Started work",
    icon: <ClockIcon />,
  },
};

/**
 * This is a safeguard against adding steps on backend which may break the mapping on frontend.
 * For such cases, it would return a question mark and Unknown step name so that the rendering doesn't break.
 */
export function safeGetRecruitmentItemDescriptionAndIcon(
  stepName: keyof typeof RECRUITMENT_ITEM_DESCRIPTION_AND_ICONS
): DescriptionAndIcon {
  return (
    RECRUITMENT_ITEM_DESCRIPTION_AND_ICONS[stepName] ?? {
      description: "Unknown",
      icon: <QuestionMarkIcon />,
    }
  );
}

export function ReferralsStructureTable() {
  const { data } = useRewardsStructureQuery({ onError: reportError });
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  const tableRows: Array<{
    description: string;
    reward: string;
    icon: JSX.Element;
    isTotalRow?: boolean;
  }> = useMemo(() => {
    if (!data) {
      return [];
    }

    const rows = data.getMyRewardsOrganization.referralRewardStructure.items.map(
      (item) => {
        switch (item.__typename) {
          case "ReferralRewardStructureJobShareItem": {
            switch (item.reward.__typename) {
              case "ReferralRewardStructureDrawTicketReward": {
                return {
                  ...RECRUITMENT_ITEM_DESCRIPTION_AND_ICONS["JobShared"],
                  reward: `${numberWithCommas(
                    item.reward.numTickets
                  )} Draw ticket (Limit of ${
                    item.maxAwardableJobSharesPerMonth
                  } per month)`,
                };
              }
              case "ReferralRewardStructurePointReward": {
                return {
                  ...RECRUITMENT_ITEM_DESCRIPTION_AND_ICONS["JobShared"],
                  reward: formatPointsAndDollars(
                    item.reward.pointValue,
                    data.getMyRewardsOrganization.pointsPerDollar
                  ),
                };
              }
              default: {
                throw new Error(
                  `Unexpected ReferralRewardStructureJobShareItem reward of type: ${item.reward.__typename}`
                );
              }
            }
          }
          case "ReferralRewardStructureReferralRecruitmentItem": {
            switch (item.reward.__typename) {
              case "ReferralRewardStructureDrawTicketReward": {
                return {
                  ...safeGetRecruitmentItemDescriptionAndIcon(item.step),
                  reward: `${numberWithCommas(
                    item.reward.numTickets
                  )} Draw ticket`,
                };
              }
              case "ReferralRewardStructurePointReward": {
                return {
                  ...safeGetRecruitmentItemDescriptionAndIcon(item.step),
                  reward: formatPointsAndDollars(
                    item.reward.pointValue,
                    data.getMyRewardsOrganization.pointsPerDollar
                  ),
                };
              }
              default: {
                throw new Error(
                  `Unexpected ReferralRewardStructureReferralRecruitmentItem reward of type: ${item.reward.__typename}`
                );
              }
            }
          }
          case "ReferralRewardStructureReferralRetentionItem": {
            switch (item.reward.__typename) {
              case "ReferralRewardStructureDrawTicketReward": {
                return {
                  icon: <UserCardIcon />,
                  description: `${numberWithCommas(
                    item.duration.amount
                  )} ${item.duration.unit.toLowerCase()} worked`,
                  reward: `${numberWithCommas(
                    item.reward.numTickets
                  )} Draw ticket`,
                };
              }
              case "ReferralRewardStructurePointReward": {
                return {
                  icon: <UserCardIcon />,
                  description: `${numberWithCommas(
                    item.duration.amount
                  )} ${item.duration.unit.toLowerCase()} worked`,
                  reward: formatPointsAndDollars(
                    item.reward.pointValue,
                    data.getMyRewardsOrganization.pointsPerDollar
                  ),
                };
              }
              default: {
                throw new Error(
                  `Unexpected ReferralRewardStructureReferralRetentionItem reward of type: ${item.reward.__typename}`
                );
              }
            }
          }
          default: {
            throw new Error(
              `Unrecognized ReferralRewardStructure item of type: ${item.__typename}`
            );
          }
        }
      }
    );

    return compact([
      ...rows,
      {
        description: "Total rewards to referring employee",
        reward: formatPointsAndDollars(
          data.getMyRewardsOrganization.maxPointsEarnedPerCandidate,
          data.getMyRewardsOrganization.pointsPerDollar
        ),
        icon: <DollarIcon />,
        isTotalRow: true,
      },
    ]);
  }, [data]);

  const theme = useTheme();

  if (!data) {
    return null;
  }

  return (
    <div
      css={(theme: AppTheme) => css`
        border-style: solid;
        border-width: 1px;
        border-radius: 10px;
        border-color: #dfe4eb;
        margin-top: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(1.5)};
        width: 100%;
      `}
    >
      <Table>
        <TableRow>
          <TableHeader rightDivider>
            <Typography
              variant={newThemeEnabled ? "subtitle" : "caption"}
              color="textPrimary"
              css={(theme: AppTheme) =>
                css`
                  margin-left: ${theme.spacing(1.5)};
                  font-weight: 600;
                `
              }
            >
              Step of New Hire
            </Typography>
          </TableHeader>
          <TableHeader>
            <Typography
              variant={newThemeEnabled ? "subtitle" : "caption"}
              color="textPrimary"
              css={css`
                font-weight: 600;
              `}
            >
              Rewards Given
            </Typography>
          </TableHeader>
        </TableRow>

        <TableBody>
          {tableRows.map((row, index) => (
            <TableRow
              backgroundColor={
                row.isTotalRow
                  ? lighten(theme.palette.tertiary.main, 0.9)
                  : undefined
              }
              css={css`
                border-radius: ${row.isTotalRow ? "20px" : "0px"};
              `}
              key={index}
            >
              <TableCell rightDivider divider={index !== tableRows.length - 1}>
                <div
                  css={(theme: AppTheme) =>
                    css`
                      margin-left: ${theme.spacing(1.5)};
                      display: flex;
                      align-items: center;
                    `
                  }
                >
                  {row.icon}
                  <Typography
                    css={(theme: AppTheme) =>
                      css`
                        margin-left: ${theme.spacing(1.5)};
                        font-weight: ${row.isTotalRow ? "600" : "400"};
                      `
                    }
                    variant="body"
                    color="textPrimary"
                  >
                    {row.description}
                  </Typography>
                </div>
              </TableCell>
              <TableCell divider={index !== tableRows.length - 1}>
                <Typography
                  css={css`
                    font-weight: ${row.isTotalRow ? "600" : "400"};
                  `}
                  variant="body"
                >
                  {row.reward}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
