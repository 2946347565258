/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

interface NavigationBarOrganizationNameProps {
  shortName: string;
}

export function NavigationBarOrganizationName({
  shortName,
}: NavigationBarOrganizationNameProps) {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <Typography
        display="inline"
        css={
          newThemeEnabled
            ? undefined
            : css`
                font-weight: 600;
              `
        }
        variant={newThemeEnabled ? "h6" : "h3"}
        component="span"
      >
        {shortName}
      </Typography>
    </div>
  );
}
