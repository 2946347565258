import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

// Note: this is a temporary hook to be used during the roll-out
// of admin goals. Once one of the feature flags is enabled for
// all organizations, we can remove this check and make admin
// goals enabled for all.
export function useAdminGoalsEnabled(): boolean {
  const budgetUtilizationGoalEnabled = useFeatureFlag(
    "admin-app-budget-utilization-goal-available-temp"
  );
  const firstMonthLoginGoalEnabled = useFeatureFlag(
    "admin-app-first-month-login-goal-available-temp"
  );
  // add flags for other goal types here

  return budgetUtilizationGoalEnabled || firstMonthLoginGoalEnabled;
}
