import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LaunchAdminGoalConfigMutationVariables = Types.Exact<{
  adminIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  rewards: Types.AdminGoalRewardsInput;
  target: Types.AdminGoalTargetInput;
  startDate: Types.Scalars['Date'];
  endDate: Types.Scalars['Date'];
  goalType: Types.AdminGoalType;
}>;


export type LaunchAdminGoalConfigMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'launchNewAdminGoalConfig'>
);


export const LaunchAdminGoalConfigDocument = gql`
    mutation LaunchAdminGoalConfig($adminIds: [ID!]!, $rewards: AdminGoalRewardsInput!, $target: AdminGoalTargetInput!, $startDate: Date!, $endDate: Date!, $goalType: AdminGoalType!) {
  launchNewAdminGoalConfig(
    adminIds: $adminIds
    goalType: $goalType
    rewards: $rewards
    target: $target
    startDate: $startDate
    endDate: $endDate
  )
}
    `;
export type LaunchAdminGoalConfigMutationFn = Apollo.MutationFunction<LaunchAdminGoalConfigMutation, LaunchAdminGoalConfigMutationVariables>;

/**
 * __useLaunchAdminGoalConfigMutation__
 *
 * To run a mutation, you first call `useLaunchAdminGoalConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchAdminGoalConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchAdminGoalConfigMutation, { data, loading, error }] = useLaunchAdminGoalConfigMutation({
 *   variables: {
 *      adminIds: // value for 'adminIds'
 *      rewards: // value for 'rewards'
 *      target: // value for 'target'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      goalType: // value for 'goalType'
 *   },
 * });
 */
export function useLaunchAdminGoalConfigMutation(baseOptions?: Apollo.MutationHookOptions<LaunchAdminGoalConfigMutation, LaunchAdminGoalConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LaunchAdminGoalConfigMutation, LaunchAdminGoalConfigMutationVariables>(LaunchAdminGoalConfigDocument, options);
      }
export type LaunchAdminGoalConfigMutationHookResult = ReturnType<typeof useLaunchAdminGoalConfigMutation>;
export type LaunchAdminGoalConfigMutationResult = Apollo.MutationResult<LaunchAdminGoalConfigMutation>;
export type LaunchAdminGoalConfigMutationOptions = Apollo.BaseMutationOptions<LaunchAdminGoalConfigMutation, LaunchAdminGoalConfigMutationVariables>;