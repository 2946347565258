/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faFolderXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";

import { Divider } from "@rewards-web/shared/components/divider";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { CandidateListDetailsFragment } from "../../candidate-fragment.generated";
import {
  formatCandidateActionDate,
  formatCandidateApplicationDateDistance,
} from "../lib";
import { Disclaimer } from "./disclaimer";
import { shouldDisplayAppliedAtDisclaimer } from "./lib";

export interface CandidateSummaryProps {
  candidate: CandidateListDetailsFragment;
  viewDetailsLink: string;
  resumesEnabled: boolean;
  organizationSteps: GraphQLTypes.CandidateStep[];
}

export function CandidateSummary({
  candidate,
  viewDetailsLink,
  resumesEnabled,
  organizationSteps,
}: CandidateSummaryProps): JSX.Element {
  const earliestApplicationDate = candidate.appliedOnDates.reduce<
    string | null
  >((prev, appliedOnDate) => {
    if (!prev) {
      return appliedOnDate;
    }

    if (parseISO(appliedOnDate) < parseISO(prev)) {
      return appliedOnDate;
    }

    return prev;
  }, null);

  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  return (
    <>
      <ObscureRecordedText>
        <Typography
          css={(theme: AppTheme) => css`
            margin-top: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(0.5)};
          `}
          variant={newThemeEnabled ? "subtitle" : "h3"}
          component="h2"
          color="textPrimary"
        >
          {candidate.firstName}{" "}
          {candidate.preferredName && `(${candidate.preferredName}) `}
          {candidate.lastName}
        </Typography>
      </ObscureRecordedText>
      {candidate.assignedToAdmin && (
        <>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <AssignmentIndIcon
              css={(theme: AppTheme) => css`
                color: ${theme.palette.text.secondary};
                margin-right: ${theme.spacing(0.5)};
                font-size: 16px;
              `}
            />

            <Typography
              display="block"
              variant="footnote"
              color="textSecondary"
            >
              Assigned to:{" "}
              <ObscureRecordedText>
                {candidate.assignedToAdmin.firstName}{" "}
                {candidate.assignedToAdmin.lastName}
              </ObscureRecordedText>
            </Typography>
          </div>
          <Divider
            css={(theme: AppTheme) => css`
              margin: ${theme.spacing(0.8)} 0;
            `}
          />
        </>
      )}
      {candidate.archived &&
        candidate.archiveReason ===
          GraphQLTypes.CandidateArchiveReason.AutoReject && (
          <div
            css={(theme: AppTheme) => css`
              background-color: ${theme.palette.grey[800]};
              border-radius: 100px;
              width: fit-content;
              padding: 0 ${theme.spacing(1)};
              display: flex;
              align-items: center;
            `}
          >
            <FontAwesomeIcon
              icon={faFolderXmark}
              color="white"
              size="sm"
              css={(theme: AppTheme) => css`
                margin-right: ${theme.spacing(1)};
              `}
            />
            <Typography variant="caption" color="white">
              AUTO-REJECTED
            </Typography>
          </div>
        )}
      {candidate.assignedToJobPosting && (
        <Typography
          display="block"
          variant="footnote"
          color="textSecondary"
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(0.5)};
            `
          }
        >
          {candidate.assignedToJobPosting.title} (
          {candidate.assignedToJobPosting.geography})
        </Typography>
      )}
      <Typography display="block" variant="footnote" color="textSecondary">
        Applied on{" "}
        {candidate.appliedOnDates
          .map((date) => formatCandidateActionDate(parseISO(date)))
          .join(", ")}
      </Typography>
      <Typography display="block" variant="footnote" color="textSecondary">
        Referred by{" "}
        <ObscureRecordedText>
          {candidate.referredByUser.firstName}{" "}
          {candidate.referredByUser.lastName}
        </ObscureRecordedText>
      </Typography>
      <Typography
        css={css`
          display: block;
        `}
        variant="footnote"
        color="textSecondary"
        component={Link}
        to={viewDetailsLink}
      >
        View details
        {Boolean(candidate.hasUploadedResume) && resumesEnabled && (
          <>
            {" "}
            and resume
            <div
              data-testid="resume-indicator"
              css={(theme: AppTheme) => css`
                display: inline-block;
                margin-left: ${theme.spacing(1)};
                margin-bottom: -1px;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: ${theme.palette.primary.main};
              `}
            />
          </>
        )}
      </Typography>

      {earliestApplicationDate &&
        shouldDisplayAppliedAtDisclaimer(candidate, organizationSteps) &&
        parseISO(earliestApplicationDate) <= new Date() && (
          <div
            css={(theme: AppTheme) =>
              css`
                margin-top: ${theme.spacing(1)};
              `
            }
          >
            <Disclaimer
              tooltipMessage={`This candidate applied on ${format(
                parseISO(earliestApplicationDate),
                "MMM dd"
              )} and should be contacted as soon as possible`}
              message={formatCandidateApplicationDateDistance(
                earliestApplicationDate
              )}
            />
          </div>
        )}
    </>
  );
}
