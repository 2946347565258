/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, OutlinedInput, Select } from "@material-ui/core";

import {
  SurveyInsightsDateRange,
  SurveyInsightsDateRangeLabel,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import { convertDateRangeToValue, convertValueToDateRange } from "../utils";

export function DateRangeSelectField({
  value,
  options,
  onChange,
}: {
  value: SurveyInsightsDateRange | undefined;
  options: SurveyInsightsDateRange[];
  onChange: (dateRange: SurveyInsightsDateRange) => void;
}) {
  const label = "Previous surveys";

  const convertDateRangeToLabel = (dateRange: SurveyInsightsDateRange) => {
    switch (dateRange.label) {
      case SurveyInsightsDateRangeLabel.Q1:
        return `Q1 ${dateRange.year}`;
      case SurveyInsightsDateRangeLabel.Q2:
        return `Q2 ${dateRange.year}`;
      case SurveyInsightsDateRangeLabel.Q3:
        return `Q3 ${dateRange.year}`;
      case SurveyInsightsDateRangeLabel.Q4:
        return `Q4 ${dateRange.year}`;
      case SurveyInsightsDateRangeLabel.TrialWeek_1:
        return "Trial Week 1";
      case SurveyInsightsDateRangeLabel.TrialWeek_4:
        return "Trial Week 4";
      case SurveyInsightsDateRangeLabel.Launch:
        return "Launch";
      default:
        return assertNever(dateRange.label as never);
    }
  };

  return (
    <ClassNames>
      {({ css, theme }) => (
        <Select
          input={
            <OutlinedInput
              label={label}
              notched={false}
              classes={{
                notchedOutline: "custom-notched-outline",
                root: css`
                  background-color: #fff;
                `,
              }}
              startAdornment={<FontAwesomeIcon icon={faCalendar} />}
            />
          }
          classes={{
            root: css`
              background-color: #fff;
              border-radius: 12px;
              padding: ${theme.spacing(1)} ${theme.spacing(2)};
              height: 0.8em;
              width: 180px;

              &:focus {
                background-color: #fff;
                border-radius: 12px;
              }
            `,
          }}
          value={convertDateRangeToValue(value)}
          onChange={(event) => {
            const dateRange = convertValueToDateRange(
              event.target.value as string,
              options
            );
            return onChange(dateRange);
          }}
          label={label}
        >
          {options.map((option) => {
            const value = convertDateRangeToValue(option);
            return (
              <MenuItem key={value} value={value}>
                {convertDateRangeToLabel(option)}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </ClassNames>
  );
}
