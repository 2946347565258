/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

export function DrawListEmptyState() {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  return (
    <Card
      css={(theme: AppTheme) => css`
        padding: ${theme.spacing(3)};
        height: ${theme.spacing(83)};
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      `}
    >
      <Typography
        color="textPrimary"
        variant="h5"
        component="h3"
        fontWeight={newThemeEnabled ? undefined : 700}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        No draw scheduled
      </Typography>

      <Typography
        color="grey.800"
        variant="body"
        align="center"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(3)};
          max-width: 750px;
        `}
      >
        Get started with your first draw! Caregivers can complete tasks such as
        sharing jobs and clocking in and out to earn tickets and win prizes of
        your choosing.
      </Typography>

      <Button
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        label="Schedule a draw"
        color="primary"
        size={newThemeEnabled ? "small" : "medium"}
        width="auto"
        linkTo="/draw/schedule-new"
      />
    </Card>
  );
}
