/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import {
  CandidateRecruitmentStepName,
  JobPosting,
  NumActiveCandidatesByJobPostingAndStatusItem,
  NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment,
  NumActiveCandidatesByJobPostingAndStatusItemTotalRetention,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { PageCard } from "../../../../shared/components/page-card";
import { PageCardContent } from "../../../../shared/components/page-card/page-card-content";
import { PageCardTitle } from "../../../../shared/components/page-card/page-card-title";
import { getStepLabel } from "../lib";
import { HiringPipelineEmptyState } from "./hiring-pipeline-empty-state";
import { HiringPipelineJobDetails } from "./hiring-pipeline-job-details";
import { PipelineTable } from "./pipeline-table";
import { PipelineTableCell } from "./pipeline-table/pipeline-table-cell";
import { PipelineTableHeaderCell } from "./pipeline-table/pipeline-table-header-cell";
import { StatusLink } from "./status-link";

export interface HiringPipelineProps {
  jobPostings: Array<
    { __typename?: "NumActiveCandidatesByJobPostingAndStatusItem" } & Pick<
      NumActiveCandidatesByJobPostingAndStatusItem,
      "total"
    > & {
        jobPosting: { __typename?: "JobPosting" } & Pick<
          JobPosting,
          "id" | "title" | "geography" | "closedForSubmission"
        >;
        totalsByStatus: Array<
          | ({
              __typename: "NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment";
            } & Pick<
              NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment,
              "numActiveCandidates" | "recruitmentStepName"
            >)
          | ({
              __typename: "NumActiveCandidatesByJobPostingAndStatusItemTotalRetention";
            } & Pick<
              NumActiveCandidatesByJobPostingAndStatusItemTotalRetention,
              "numActiveCandidates" | "retentionDurationMonths"
            >)
        >;
      }
  >;
}

export function HiringPipeline({
  jobPostings,
}: HiringPipelineProps): JSX.Element {
  const track = useTrack();
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  const totalCandidates = jobPostings.reduce(
    (prev, job) => prev + job.total,
    0
  );

  useEffect(() => {
    track("Viewed hiring pipeline", {
      totalCandidates: totalCandidates,
    });
  }, [totalCandidates, track]);

  const cardContent = (() => {
    if (jobPostings.length === 0) {
      return <HiringPipelineEmptyState />;
    }

    const stepHeaders = [
      "STEPS",
      ...jobPostings[0].totalsByStatus
        .map((step) => {
          return getStepLabel(step);
        })
        .filter(
          (step) => step !== CandidateRecruitmentStepName.ApplicationSubmitted
        ),
    ];

    return (
      <PipelineTable>
        <thead>
          <tr>
            {stepHeaders.map((step, index) => {
              return (
                <PipelineTableHeaderCell key={`${step}-${index}`} leftAlign>
                  <Typography
                    css={css`
                      font-weight: bold;
                      font-size: 0.9em;
                    `}
                    variant={newThemeEnabled ? "footnote" : "body"}
                  >
                    {step}
                    {index === 1 && (
                      <>
                        <span
                          css={css`
                            display: block;
                            font-weight: initial;
                          `}
                        >
                          (New Applicants)
                        </span>
                      </>
                    )}
                  </Typography>
                </PipelineTableHeaderCell>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {jobPostings.map((row) => {
            return (
              <tr key={row.jobPosting.id}>
                <PipelineTableCell leftAlign>
                  <HiringPipelineJobDetails
                    jobPosting={row.jobPosting}
                    totalSubmissions={row.total}
                  />
                </PipelineTableCell>

                {row.totalsByStatus.map((stepStatus, index) => {
                  if (index + 1 !== row.totalsByStatus.length) {
                    return (
                      <PipelineTableCell key={JSON.stringify(stepStatus)}>
                        <StatusLink
                          jobPostingId={row.jobPosting.id}
                          stepStatus={stepStatus}
                        />
                      </PipelineTableCell>
                    );
                  }
                  return null;
                })}
              </tr>
            );
          })}
        </tbody>
      </PipelineTable>
    );
  })();

  return (
    <PageCard>
      <PageCardTitle title="Hiring Pipeline" />
      <PageCardContent maxWidth="1300px">{cardContent}</PageCardContent>
    </PageCard>
  );
}
