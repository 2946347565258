/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

const HELP_EMAIL = "help@caribou.care";

interface RedrawWinnersModalProps {
  open: boolean;
  onClose(): void;
}

export function RedrawWinnersModal({ open, onClose }: RedrawWinnersModalProps) {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  return (
    <Modal open={open} width="580px" onClose={onClose}>
      <ModalContent>
        <Typography
          variant={newThemeEnabled ? "h5" : "h2"}
          component="h2"
          color="textPrimary"
          textAlign="center"
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(2)};
            `
          }
        >
          Redraw winner(s)
        </Typography>
        <Typography
          variant="body"
          color="grey.800"
          textAlign="center"
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(1)};
            `
          }
        >
          If you'd like to choose new winners, please email{" "}
          <a
            href={`mailto:${HELP_EMAIL}?subject=${encodeURIComponent(
              "Request to re-draw winners for draw"
            )}`}
            target="_blank"
            rel="noreferrer"
            css={(theme: AppTheme) => css`
              color: ${theme.palette.primary.main};
              text-decoration: none;
            `}
          >
            {HELP_EMAIL}
          </a>{" "}
          for assistance.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          width="auto"
          size="large"
          color="primary"
          label="Okay"
          onClick={onClose}
          css={css`
            margin: 0 auto;
          `}
        />
      </ModalActions>
    </Modal>
  );
}
