/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { CardContent } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme, fontFamily } from "@rewards-web/shared/style/theme";

interface AdminOnboardingCardHeaderProps {
  title: string;
  action?: ReactNode;
}

export function AdminOnboardingCardHeader({
  title,
  action,
}: AdminOnboardingCardHeaderProps) {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  return (
    <CardContent
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${theme.spacing(3)};
        border-bottom: 1px solid ${theme.palette.divider};
      `}
    >
      <Typography
        css={
          !newThemeEnabled &&
          css`
            font-family: ${fontFamily};
            font-weight: 800;
            font-size: 1.1em;
          `
        }
        variant={newThemeEnabled ? "h6" : "h2"}
        color="textPrimary"
      >
        {title}
      </Typography>
      {action}
    </CardContent>
  );
}
