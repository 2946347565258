import { isUndefined } from "lodash";

import {
  SurveyInsightsDateRangeInput,
  SurveyNpsScorePreviousResultsResponse,
  SurveyQuestionPreviousResultsResponse,
  SurveyQuestionType,
} from "@rewards-web/shared/graphql-types";

import { SurveyQuestionTypeSupportedByUI } from "../constants";
import { shortenDateRangeLabel } from "../utils";

export interface ScoreWithDateRange {
  score: number | null;
  label: string;
  year: string;
}

/**
 * Given the array of previous scores from the API (ordered from newest to oldest),
 * will convert to the format needed for comparison, ordered from oldest to newest.
 */
export const getPreviousScoresForComparison = (
  previousScores: (
    | SurveyQuestionPreviousResultsResponse
    | SurveyNpsScorePreviousResultsResponse
  )[]
): ScoreWithDateRange[] => {
  if (!previousScores.length) {
    return [];
  }

  return previousScores
    .map((result) => {
      return {
        score: (() => {
          if ("averageScore" in result) {
            return result.averageScore;
          }
          // NPS scores can be null if no responses were received
          return result.npsScore ?? null;
        })(),
        label: shortenDateRangeLabel(result.dateRange.label),
        year: result.dateRange.year.toString(),
      };
    })
    .slice(0, 4) // only show the previous 4 scores (plus the current)
    .reverse(); // order from oldest to newest
};

/**
 * Given the average score and date range input, will convert to the
 * format needed for comparison to previous scores.
 * Will return null if not all data is available, ie. while queries are loading.
 */
export const getCurrentScoreForComparison = ({
  averageScore,
  npsScore,
  dateRange,
}: {
  averageScore?: number;
  npsScore?: number | null;
  dateRange?: SurveyInsightsDateRangeInput;
}): ScoreWithDateRange | null => {
  if (isUndefined(averageScore ?? npsScore) || !dateRange) {
    return null;
  }

  return {
    score: (() => {
      if (typeof averageScore === "number") {
        return Math.round(averageScore * 10) / 10; // round to 1 decimal place
      }
      // NPS scores can be null if no responses were received
      return npsScore ?? null;
    })(),
    label: shortenDateRangeLabel(dateRange.label),
    year: dateRange.year.toString(),
  };
};

export const scoreToProgressValue = (
  score: number | null,
  surveyQuestionType: SurveyQuestionTypeSupportedByUI
) => {
  // Show empty progress bar if score is null
  if (score === null) {
    return 0;
  }

  // NPS score is -100 to 100, so for the progress bar we need to convert to a percentage
  // ie. -100 is 0%, 0 is 50%, 100 is 100%.
  if (surveyQuestionType === SurveyQuestionType.Nps) {
    return (score + 100) / 2;
  }

  return (score / 5) * 100;
};
