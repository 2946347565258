/** @jsxImportSource @emotion/react */
import { addMonths, format, startOfDay, startOfMonth } from "date-fns";

import { AdminGoalType } from "@rewards-web/shared/graphql-types";

import { BaseGoalConfigurationForm } from "../../base-goal-configuration-form";

const DEFAULT_REWARD_AMOUNT = 20;
const DEFAULT_TARGET_PERCENTAGE = 100;

export function BudgetUtilizationGoalForm(): JSX.Element {
  const startOfMonthDate = startOfMonth(startOfDay(new Date()));

  const defaultValues = {
    adminIds: [],
    rewardAmount: DEFAULT_REWARD_AMOUNT,
    targetPercentage: DEFAULT_TARGET_PERCENTAGE,
    startDate: startOfMonthDate,
    endDate: addMonths(startOfMonthDate, 1),
  };

  return (
    <BaseGoalConfigurationForm
      goalType={AdminGoalType.BudgetUtilization}
      goalTitle="Recognition budget utilization goal"
      goalPeriod={format(startOfMonthDate, "MMMM yyyy")}
      defaultValues={defaultValues}
    />
  );
}
