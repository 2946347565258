/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import {
  AdminGoal,
  AdminGoalStatus,
  AdminGoalType,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useHasPermissionQuery } from "../../../../../shared/modules/permissions/hooks/use-has-permission-query";
import { BaseGoalCard } from "../base-goal-card";
import loginIcon from "../icons/login.png";

export interface FirstMonthLoginGoalCardProps {
  goal: Pick<
    AdminGoal,
    "type" | "status" | "rewards" | "startDate" | "endDate" | "tracker"
  >;
}

export function FirstMonthLoginGoalCard({
  goal: { type, status, rewards, startDate, endDate, tracker },
}: FirstMonthLoginGoalCardProps) {
  const track = useTrack();

  const { hasPermission: hasAccessToEmployees } = useHasPermissionQuery(
    "read",
    "employees"
  );

  if (
    type !== AdminGoalType.LoginAfterLaunch ||
    tracker.__typename !== "LoginAdminGoalTracker"
  ) {
    return null;
  }

  const {
    targetPercentage,
    totalUsersWhoCheckedIn: checkedInUsersProp,
    totalActiveUsers,
    progressUpdatedAt,
  } = tracker;

  const reward = (() => {
    switch (rewards.__typename) {
      case "AdminGoalRewardsGiftCard":
        return {
          type: "gift_card" as const,
          dollarValue: rewards.dollarValue,
        };
      default:
        return null;
    }
  })();

  // Handle when `totalUsersWhoCheckedIn` is `null` after the goal is initially created,
  // by setting progress to 0
  const totalUsersWhoCheckedIn = checkedInUsersProp ?? 0;

  const progressPercentage =
    typeof totalActiveUsers === "number" && totalActiveUsers > 0
      ? (totalUsersWhoCheckedIn / totalActiveUsers) * 100
      : null;

  const progressText = (() => {
    switch (status) {
      case AdminGoalStatus.Available: {
        if (typeof totalActiveUsers !== "number" || totalActiveUsers <= 0) {
          return null;
        }

        const targetNumberOfUsers = Math.ceil(
          totalActiveUsers * (targetPercentage / 100)
        );
        const usersRemaining = targetNumberOfUsers - totalUsersWhoCheckedIn;

        if (usersRemaining <= 0) {
          return "You've reached your target goal!";
        }

        return `Get ${usersRemaining} more team members to log in to hit your ${targetPercentage}% team goal`;
      }
      case AdminGoalStatus.Achieved: {
        const defaultText = "You accomplished the first month login goal!";

        if (!reward) {
          return defaultText;
        }

        switch (reward.type) {
          case "gift_card":
            return "What's next: you will receive an email from Tango (gift card provider) within 48 hours with the link to redeem your gift card.";
          default:
            return defaultText;
        }
      }
      default:
        return null;
    }
  })();

  const progressTooltip = (() => {
    switch (status) {
      case AdminGoalStatus.Available:
        if (!progressText) {
          return null;
        }

        return "Login rate is calculated based on currently active users. Deactivated users are not included";
      default:
        return null;
    }
  })();

  return (
    <>
      <BaseGoalCard
        icon={<img src={loginIcon} alt="login" width={73} />}
        title="First month login goal"
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
        reward={reward}
        goalStatus={status}
        goalType={type}
        goalProgress={{
          targetPercentage,
          progressPercentage,
          progressLastUpdated: progressUpdatedAt
            ? new Date(progressUpdatedAt)
            : null,
          progressText,
          progressTooltip,
          targetType: "team", // login goals are always team
        }}
        tipsContent={{
          title: "Tips to boost login engagement",
          action: (
            <Tooltip
              title={
                hasAccessToEmployees
                  ? ""
                  : "You do not have permission to view the Employees page. Ask your admin for permission."
              }
              placement="right"
            >
              <Button
                variant="outlined"
                label="Find out who did not log in"
                linkTo="/employees/accounts"
                size="small"
                width="auto"
                css={(theme: AppTheme) => css`
                  border-color: ${theme.palette.grey[600]};
                `}
                onClick={() => {
                  track("Clicked to view employees from admin goal card");
                }}
                disabled={!hasAccessToEmployees}
              />
            </Tooltip>
          ),
          tips: [
            "Showcase your agency's unique benefits by introducing the rewards program to new employees during orientation and onboarding. ",
            "Encourage employees to explore the rewards app via reminders they receive, and highlighting opportunities to earn rewards.",
            "Re-send log in links when employees have trouble accessing their account via the Employee page in the admin portal.",
            "Send recognition regularly as this helps employees engage with the program and become more motivated to engage with all other goals available in the app.",
          ],
        }}
      />
    </>
  );
}
