import { ApolloProvider, ServerError } from "@apollo/client";
import Amplify, { Auth } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";

import {
  createApolloClient,
  enhanceFetchBreadcrumbs,
} from "@rewards-web/shared/lib/apollo-client";
import { delay } from "@rewards-web/shared/lib/delay";
import {
  AppErrorBoundary,
  initializeSentry,
} from "@rewards-web/shared/modules/error";
import {
  createLaunchDarklyFactory,
  LaunchDarklyFeatureFlagProvider,
  useFeatureFlag,
} from "@rewards-web/shared/modules/feature-flag";
import { MaintenanceModeWrapper } from "@rewards-web/shared/modules/maintenance-mode";
import { SnackbarProvider } from "@rewards-web/shared/modules/snackbar";
import { StyleProviders } from "@rewards-web/shared/style/global-styles";

import { AppRoutes } from "./pages/routes";
import { SmallScreenWarningModalProvider } from "./shared/components/small-screen-warning-modal-provider";
import {
  CognitoAuthProvider,
  CognitoAuthStatus,
} from "./shared/modules/cognito-auth";
import { getCognitoSession } from "./shared/modules/cognito-auth/lib";
import { LaunchCongratulationsModalProvider } from "./shared/modules/launch/components/launch-congratulations-modal-provider";
import {
  selectedTenant,
  SuperuserTenantSelectorProvider,
} from "./shared/modules/superuser-tenant-selector";
import { newAdminAppTheme, oldAdminAppTheme } from "./theme";

initializeSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN!,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT!,
  release: process.env.REACT_APP_SENTRY_RELEASE!,
  transformBreadcrumb: enhanceFetchBreadcrumbs,
  allowUrls: [
    "https://admin.caribou.care",
    "https://admin.caribourewards.ca",
    /https?:\/\/((admin(-\w+)?)\.)?quala\.app/,
  ],
  appName: "rewards-admin-app",
});

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: true,
  },
  Analytics: {
    disabled: true,
  },
});

const apolloClient = createApolloClient({
  graphQLURI: process.env.REACT_APP_GRAPHQL_URL!,
  appNameHeaderValue: "CARIBOU_REWARDS_APP",
  isAuthenticated: () => Promise.resolve(CognitoAuthStatus.isAuthenticated()),
  getAccessToken: async () => {
    try {
      return (await getCognitoSession()).getAccessToken().getJwtToken();
    } catch (error) {
      if ((error as any)?.message === "Refresh Token has expired") {
        window.location.href = "/login?session_expired=true";
        // ensure an error isn't thrown ...
        await delay(2000);
      }

      throw error;
    }
  },
  getHeaders: () => {
    const headers: Record<string, string> = {};

    if (selectedTenant.value) {
      headers["x-selected-tenant-id"] =
        String(selectedTenant.value) ?? undefined;
    }

    return headers;
  },
  onError: (error) => {
    if (
      error.graphQLErrors?.some((error) => error.message === "Not signed in") ||
      (error.networkError?.name === "ServerError" &&
        (error.networkError as ServerError).statusCode === 401)
    ) {
      // unexpected issue...
      // explicitly sign out and redirect to the login page. this shouldn't happen in practice
      Auth.signOut().then(() => {
        window.location.href = "/login?unexpected_logout_error=true";
      });
    } else if (
      error.networkError?.name === "ServerError" &&
      (error.networkError as ServerError).statusCode === 401 &&
      (error.networkError as ServerError).result?.message ===
        "User is deactivated"
    ) {
      analytics?.track("Admin logged out due to deactivation");

      delay(1000) // wait for segment event to track before navigation
        // user should not be able to access the system anymore -- log them out and redirect them to /login?deactivated=true screen
        .then(() => Auth.signOut())
        .then(() => {
          window.location.href = "/login?deactivated=true";
        });
    }
  },
});

const launchDarklyFactory = process.env.REACT_APP_LAUNCH_DARKLY_KEY
  ? createLaunchDarklyFactory({
      apiKey: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
    })
  : undefined;

export function App(): JSX.Element {
  return (
    <LaunchDarklyFeatureFlagProvider
      launchDarklyFactory={launchDarklyFactory}
      appName="rewards_admin_app"
    >
      <AdminAppStyleProviders>
        <AppErrorBoundary>
          <MaintenanceModeWrapper>
            <ApolloProvider client={apolloClient}>
              <SnackbarProvider>
                <BrowserRouter>
                  <CognitoAuthProvider>
                    <SuperuserTenantSelectorProvider>
                      <LaunchCongratulationsModalProvider>
                        <SmallScreenWarningModalProvider>
                          <AppRoutes />
                        </SmallScreenWarningModalProvider>
                      </LaunchCongratulationsModalProvider>
                    </SuperuserTenantSelectorProvider>
                  </CognitoAuthProvider>
                </BrowserRouter>
              </SnackbarProvider>
            </ApolloProvider>
          </MaintenanceModeWrapper>
        </AppErrorBoundary>
      </AdminAppStyleProviders>
    </LaunchDarklyFeatureFlagProvider>
  );
}

function AdminAppStyleProviders({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element | null {
  const newThemeEnabled =
    useFeatureFlag("admin-app-new-theme-temp") ||
    process.env.REACT_APP_SENTRY_ENVIRONMENT === "release";

  return (
    <StyleProviders
      theme={newThemeEnabled ? newAdminAppTheme : oldAdminAppTheme}
      renderCssBaseline
    >
      {children}
    </StyleProviders>
  );
}
