/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInMinutes } from "date-fns";

import { ProgressBar } from "@rewards-web/shared/components/progress-bar";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { AdminGoalStatus } from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface AdminGoalProgressProps {
  targetPercentage: number;
  targetType: "individual" | "team";
  progressPercentage: number | null;
  progressLastUpdated: Date | null;
  progressText: string | null;
  progressTooltip: string | null;
}

interface GoalCardProgressBarProps {
  goalStatus: AdminGoalStatus;
  goalProgress: AdminGoalProgressProps;
}

const PROGRESS_BAR_HEIGHT = 27;

export function GoalCardProgressBar({
  goalStatus,
  goalProgress: {
    progressLastUpdated,
    targetPercentage,
    targetType,
    progressText,
    progressTooltip,
    ...goalProgress
  },
}: GoalCardProgressBarProps): JSX.Element {
  const theme = useTheme();
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  // ensure progress is capped at 100%
  const progressPercentage =
    typeof goalProgress.progressPercentage === "number"
      ? Math.min(goalProgress.progressPercentage, 100)
      : null;

  const goalProgressHeader = (() => {
    switch (goalStatus) {
      case AdminGoalStatus.Available:
        return (
          <div>
            <Typography
              variant="footnote"
              color="grey.800"
              css={(theme: AppTheme) =>
                css`
                  margin-bottom: ${theme.spacing(1)};
                `
              }
            >
              Your progress
            </Typography>
            <div
              css={css`
                display: flex;
                align-items: ${newThemeEnabled ? "baseline" : "flex-end"};
              `}
            >
              <Typography variant="h2" fontWeight={700} color="textPrimary">
                {progressPercentage ?? 0}%
              </Typography>
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  align-items: center;
                  gap: ${theme.spacing(1)};
                `}
              >
                {progressText && (
                  <Typography
                    variant="h6"
                    fontWeight={700}
                    color="grey.800"
                    css={(theme: AppTheme) => css`
                      margin-left: ${theme.spacing(1)};
                    `}
                  >
                    {progressText}
                  </Typography>
                )}
                {progressTooltip && (
                  <Tooltip title={progressTooltip} placement="right">
                    <FontAwesomeIcon icon={faInfoCircle} color="textPrimary" />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        );
      case AdminGoalStatus.Achieved:
        return (
          <div>
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                gap: ${theme.spacing(1)};
              `}
            >
              <Typography variant="subtitle" color="grey.800">
                Goal accomplished
              </Typography>
              <FontAwesomeIcon
                icon={faCheck}
                color={theme.palette.success.main}
              />
            </div>
            {progressText && (
              <Typography variant="footnote" color="grey.800">
                {progressText}
              </Typography>
            )}
          </div>
        );
      case AdminGoalStatus.Failed:
        if (progressPercentage) {
          // Admin made progress towards the goal
          return (
            <div>
              <Typography variant="subtitle" color="grey.800">
                You tried your best
              </Typography>
              <Typography variant="footnote" color="grey.800">
                This month's goal isn't complete, but every step you took made a
                difference. Let's aim for the next one!
              </Typography>
            </div>
          );
        } else {
          // No progress
          return (
            <div>
              <Typography variant="subtitle" color="grey.800">
                You missed out
              </Typography>
              <Typography variant="footnote" color="grey.800">
                Unfortunately, this month's goal was not met. Stay tuned for
                another goal opportunity to earn a reward next time!
              </Typography>
            </div>
          );
        }
      default:
        return null;
    }
  })();

  const progressLastUpdatedText = (() => {
    if (goalStatus !== AdminGoalStatus.Available) {
      return null;
    }

    if (!progressLastUpdated) {
      return "Waiting to update progress";
    }

    const minutesSinceLastUpdate = differenceInMinutes(
      new Date(),
      progressLastUpdated
    );
    const hoursSinceLastUpdate = Math.round(minutesSinceLastUpdate / 60);

    if (minutesSinceLastUpdate === 0) {
      return "Progress last updated just now";
    }

    if (minutesSinceLastUpdate <= 30) {
      return `Progress last updated ${
        minutesSinceLastUpdate > 1
          ? `${minutesSinceLastUpdate} minutes`
          : "a minute"
      } ago`;
    }

    return `Progress last updated 
          ${
            hoursSinceLastUpdate > 1
              ? `${hoursSinceLastUpdate} hours`
              : "an hour"
          }
        ago`;
  })();

  const progressBarLabel = (() => {
    // No label if progress is null or 0
    // since there will be no progress bar
    if (!progressPercentage) {
      return null;
    }

    switch (goalStatus) {
      case AdminGoalStatus.Available: {
        return `Current ${progressPercentage}%`;
      }
      case AdminGoalStatus.Achieved:
        return "Goal accomplished";
      case AdminGoalStatus.Failed:
        return "Goal not completed";
      default:
        return null;
    }
  })();

  const progressBarColor = (() => {
    switch (goalStatus) {
      case AdminGoalStatus.Available:
        return "#00C7C7";
      case AdminGoalStatus.Achieved:
        return theme.palette.secondary.main;
      case AdminGoalStatus.Failed:
        return theme.palette.grey[600];
      default:
        return theme.palette.primary.main;
    }
  })();

  // show target line if target is not 100%, and progress has not exceeded target
  const showTargetLine =
    targetPercentage < 100 && targetPercentage > (progressPercentage ?? 0);

  return (
    <>
      <div
        css={(theme: AppTheme) =>
          css`
            margin-top: ${theme.spacing(3)};
            margin-bottom: ${theme.spacing(1)};
          `
        }
      >
        {goalProgressHeader}
      </div>
      <div
        css={css`
          position: relative;
        `}
      >
        <ProgressBar
          value={progressPercentage ?? 0}
          barWidth={PROGRESS_BAR_HEIGHT}
          greyBackground
          barColor={progressBarColor}
        />
        {showTargetLine && (
          <div
            css={(theme: AppTheme) => css`
              position: absolute;
              right: ${100 - targetPercentage}%;
              top: 0;
              background-color: ${theme.palette.secondary.main};
              width: 6px;
              height: ${PROGRESS_BAR_HEIGHT}px;
            `}
          />
        )}
        {progressPercentage !== null && (
          <Typography
            variant="footnote"
            fontWeight={700}
            css={css`
              position: absolute;
              text-transform: uppercase;
              color: ${goalStatus === AdminGoalStatus.Failed
                ? "textPrimary"
                : "white"};
              right: ${100 - progressPercentage}%;
              top: 0;
              padding: 3px 6px;
            `}
          >
            {progressBarLabel}
          </Typography>
        )}
        {targetPercentage < 100 && (
          <Typography
            variant="caption"
            color="grey.800"
            css={css`
              position: absolute;
              right: 0;
              top: ${PROGRESS_BAR_HEIGHT}px;
            `}
          >
            100%
          </Typography>
        )}
        <Typography
          variant="caption"
          fontWeight={700}
          color={theme.palette.secondary.main}
          css={css`
            position: absolute;
            right: ${100 - targetPercentage}%;
            top: ${PROGRESS_BAR_HEIGHT}px;
            text-transform: uppercase;
          `}
        >
          {targetType} goal {targetPercentage}%
        </Typography>
        {progressLastUpdatedText && (
          <Typography
            variant="footnote"
            color="grey.800"
            css={css`
              position: absolute;
              left: 0;
              top: ${PROGRESS_BAR_HEIGHT}px;
            `}
          >
            {progressLastUpdatedText}
          </Typography>
        )}
      </div>
    </>
  );
}
