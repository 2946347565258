/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

export function JobsHeader() {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  return (
    <Typography
      variant={newThemeEnabled ? "h3" : "h1"}
      color="textPrimary"
      css={(theme: AppTheme) => css`
        margin-bottom: ${theme.spacing(4)};
      `}
    >
      Jobs
    </Typography>
  );
}
