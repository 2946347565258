import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type NpsQuestionDetailsPageDataQueryVariables = Types.Exact<{
  dateRange?: Types.Maybe<Types.SurveyInsightsDateRangeInput>;
}>;


export type NpsQuestionDetailsPageDataQuery = (
  { __typename?: 'Query' }
  & { getScoreDistributionForSurveyQuestion: Array<(
    { __typename?: 'SurveyQuestionScoreCount' }
    & Pick<Types.SurveyQuestionScoreCount, 'score' | 'count'>
  )>, getSurveyNPSScore: (
    { __typename?: 'SurveyNPSScoreResponse' }
    & Pick<Types.SurveyNpsScoreResponse, 'npsScore'>
    & { previousScores?: Types.Maybe<Array<(
      { __typename?: 'SurveyNPSScorePreviousResultsResponse' }
      & Pick<Types.SurveyNpsScorePreviousResultsResponse, 'npsScore'>
      & { dateRange: (
        { __typename?: 'SurveyInsightsDateRange' }
        & Pick<Types.SurveyInsightsDateRange, 'label' | 'year'>
      ) }
    )>> }
  ) }
);


export const NpsQuestionDetailsPageDataDocument = gql`
    query NpsQuestionDetailsPageData($dateRange: SurveyInsightsDateRangeInput) {
  getScoreDistributionForSurveyQuestion(
    surveyType: PULSE_CHECK
    questionType: NPS
    dateRange: $dateRange
  ) {
    score
    count
  }
  getSurveyNPSScore(surveyType: PULSE_CHECK, dateRange: $dateRange) {
    npsScore
    previousScores {
      dateRange {
        label
        year
      }
      npsScore
    }
  }
}
    `;

/**
 * __useNpsQuestionDetailsPageDataQuery__
 *
 * To run a query within a React component, call `useNpsQuestionDetailsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNpsQuestionDetailsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNpsQuestionDetailsPageDataQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useNpsQuestionDetailsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<NpsQuestionDetailsPageDataQuery, NpsQuestionDetailsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NpsQuestionDetailsPageDataQuery, NpsQuestionDetailsPageDataQueryVariables>(NpsQuestionDetailsPageDataDocument, options);
      }
export function useNpsQuestionDetailsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NpsQuestionDetailsPageDataQuery, NpsQuestionDetailsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NpsQuestionDetailsPageDataQuery, NpsQuestionDetailsPageDataQueryVariables>(NpsQuestionDetailsPageDataDocument, options);
        }
export type NpsQuestionDetailsPageDataQueryHookResult = ReturnType<typeof useNpsQuestionDetailsPageDataQuery>;
export type NpsQuestionDetailsPageDataLazyQueryHookResult = ReturnType<typeof useNpsQuestionDetailsPageDataLazyQuery>;
export type NpsQuestionDetailsPageDataQueryResult = Apollo.QueryResult<NpsQuestionDetailsPageDataQuery, NpsQuestionDetailsPageDataQueryVariables>;