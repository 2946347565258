/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "@rewards-web/shared/components/icon-button";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { RewardsUserPartial } from ".";
import { useSendInvitationLinkToEmployeeMutation } from "./send-invitation-link-to-employee.generated";
import { useSendLoginLinkToEmployeeMutation } from "./send-login-link-to-employee.generated";

interface SendLinkToEmployeeButtonProps {
  employee: RewardsUserPartial;
  rewardsProgramShortName: string;
  usesWorkDevices: boolean;
}

export function SendLinkToEmployeeButton({
  employee,
  rewardsProgramShortName,
  usesWorkDevices,
}: SendLinkToEmployeeButtonProps) {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const track = useTrack();

  const [
    sendLoginLink,
    { loading: sendLoginLinkLoading },
  ] = useSendLoginLinkToEmployeeMutation();
  const [
    sendInvitationLink,
    { loading: sendInvitationLinkLoading },
  ] = useSendInvitationLinkToEmployeeMutation();

  if (!employee.active) {
    return null;
  }

  const shouldSendInvitation = usesWorkDevices && !employee.personalContactInfo;
  const canSendLink = shouldSendInvitation || employee.personalContactInfo;

  if (!canSendLink) {
    // Missing personal contact info, cannot send link
    return null;
  }

  const tooltipTitle = `Send ${shouldSendInvitation ? "invite" : "link"} to ${
    employee.firstName
  }`;
  const snackbarMessage = `A link to open ${rewardsProgramShortName} has been texted and/or emailed to the user's phone number and email.`;

  const handleClick = async () => {
    const mutation = shouldSendInvitation ? sendInvitationLink : sendLoginLink;

    const res = await mutation({
      variables: { employeeId: employee.id },
      onError: (error) => {
        reportError(error);
        snackbar.show({
          severity: "error",
          message: "An unexpected error has occurred. Please try again later.",
        });
      },
    });

    track(
      `Sent ${shouldSendInvitation ? "invitation" : "login"} link to employee`,
      {
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
        employeeId: employee.id,
      }
    );
    snackbar.show({ severity: "success", message: snackbarMessage });
  };

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        aria-label={tooltipTitle}
        onClick={handleClick}
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.grey[200]};
          border-radius: 4px;
          padding: 7px;
          cursor: pointer;
          &:disabled {
            pointer-events: auto;
            cursor: not-allowed;
            background-color: ${theme.palette.grey[200]};
            opacity: 0.5;
          }
        `}
        disabled={sendLoginLinkLoading || sendInvitationLinkLoading}
      >
        <FontAwesomeIcon
          icon={faPaperPlane}
          color={theme.palette.primary.main}
          css={css`
            width: 16px;
            height: auto;
          `}
        />
      </IconButton>
    </Tooltip>
  );
}
