/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

interface MarkAsSentToPayrollModalProps {
  open: boolean;
  loading: boolean;
  numRedemptions: number;
  onClose(): void;
  onConfirm(): void;
}

export function MarkAsSentToPayrollModal({
  open,
  loading,
  numRedemptions,
  onClose,
  onConfirm,
}: MarkAsSentToPayrollModalProps): JSX.Element {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  return (
    <Modal width="500px" open={open} onClose={onClose}>
      <ModalTitle>Mark as "Sent to Payroll"?</ModalTitle>
      <ModalContent>
        <Typography
          variant="body"
          color={newThemeEnabled ? "grey.800" : "textPrimary"}
          css={(theme: AppTheme) => css`
            margin-bottom: ${newThemeEnabled
              ? theme.spacing(2)
              : theme.spacing(3)};
          `}
        >
          This will change the status of {numRedemptions} redemption request(s)
          from "requested" to "sent to payroll".
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          size={newThemeEnabled ? "large" : "medium"}
          whiteBackground
          variant="outlined"
          onClick={onClose}
          disabled={loading}
          label="Cancel"
        />
        <Button
          size={newThemeEnabled ? "large" : "medium"}
          type="submit"
          color="primary"
          label="Yes"
          loading={loading}
          onClick={onConfirm}
        />
      </ModalActions>
    </Modal>
  );
}
