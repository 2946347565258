/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import GetAppIcon from "@material-ui/icons/GetApp";
import WarningIcon from "@material-ui/icons/Warning";
import { isNull } from "lodash";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { TextButton } from "@rewards-web/shared/components/text-button";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { usePathParamToggle } from "@rewards-web/shared/hooks/use-path-param-toggle";
import { usePathToggle } from "@rewards-web/shared/hooks/use-path-toggle";
import {
  useTrack,
  useTrackScreenRecordingEvent,
} from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { BranchFilter } from "../../../../shared/components/branch-filter";
import { HeaderWithButton } from "../../../../shared/components/header-with-button";
import { useEmployeesListQuery } from "../../../../shared/modules/employees/graphql/employees-list.generated";
import { useOrganizationInfoQuery } from "../../../../shared/modules/employees/graphql/organization-info.generated";
import { AddEmployeeModal } from "../../../../shared/modules/employees/list/components/add-employee-modal";
import { BulkEmployeeUploadModal } from "../../../../shared/modules/employees/list/components/bulk-employee-upload-modal";
import { BulkUploadProcessingModal } from "../../../../shared/modules/employees/list/components/bulk-upload-processing-modal";
import { EmployeesDataTable } from "../../../../shared/modules/employees/list/components/employees-data-table";
import { FileProcessingBanner } from "../../../../shared/modules/employees/list/components/file-processing-banner";
import { useHasPermissionQuery } from "../../../../shared/modules/permissions/hooks/use-has-permission-query";
import { ExportEmployeesModal } from "./export-employees-modal";
import { useEmployeeStatusFilterState } from "./hooks/use-employee-status-filter-state";
import { EmployeeFilter } from "./list-filter";

const SEARCH_DEBOUNCE_MS = 300;
const ITEMS_PER_PAGE = 10;

export function AccountsPage(): JSX.Element {
  const trackScreenRecordingEvent = useTrackScreenRecordingEvent();
  const track = useTrack();
  const fullEmployeePermissionsQuery = useHasPermissionQuery(
    "full",
    "employees"
  );
  const canSendRecognitionPointsQuery = useHasPermissionQuery(
    "full",
    "recognitionPoints"
  );

  const [uploadingEmployeesInBulk, setUploadingEmployeesInBulk] = useState(
    false
  );
  const [
    uploadProcessingConfirmation,
    setUploadProcessingConfirmation,
  ] = useState(false);

  const [selectedFilters, setSelectedFilters] = useEmployeeStatusFilterState();
  const [selectedBranchIds, setSelectedBranchIds] = useState<(string | null)[]>(
    []
  );
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchQueryText, setSearchQueryText] = useState("");
  const debouncedSearchQueryTextChange = useDebouncedCallback(
    (value: string) => {
      setCurrentPageIndex(0);
      setSearchQueryText(value);
    },
    SEARCH_DEBOUNCE_MS
  );

  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
    debouncedSearchQueryTextChange(value);
  };
  const organizationInfoQuery = useOrganizationInfoQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
  });

  const employeesListQuery = useEmployeesListQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only", // prevent from reloading query automatically after a mutation
    onError: reportError,
    variables: {
      offset: currentPageIndex * ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
      searchQuery: searchQueryText || undefined,
      branchIds: selectedBranchIds.length > 0 ? selectedBranchIds : undefined,
      active: !isNull(selectedFilters.active)
        ? selectedFilters.active
        : undefined,
      providedPersonalInfo: !isNull(selectedFilters.providedPersonalInfo)
        ? selectedFilters.providedPersonalInfo
        : undefined,
    },
  });

  const [creatingNewEmployee, setCreatingNewEmployee] = useState(false);

  const [editingUserId, userIdEditActions] = usePathParamToggle({
    param: "id",
    onPath: "/employees/accounts/:id/edit",
    offPath: "/employees/accounts",
  });

  const [
    exportModalOpen,
    { navigateToOnPath: openExportModal, navigateToOffPath: closeExportModal },
  ] = usePathToggle({
    onPath: "/employees/accounts/export",
    offPath: "/employees/accounts",
  });

  if (employeesListQuery.error || organizationInfoQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!organizationInfoQuery.data) {
    return <PageLoadingState />;
  }

  const loading = employeesListQuery.loading;
  const total = employeesListQuery.data?.listRewardsUsers.total;
  const items = employeesListQuery.data?.listRewardsUsers.items ?? [];

  const {
    usesWorkDevices,
    integratedWithAlayaCare,
    managersEnabled,
    referralsEnabled,
    bulkUserUploadIsProcessing,
    branches,
    whiteLabelConfig,
  } = organizationInfoQuery.data.getMyRewardsOrganization;

  const organizationHasBranches = (branches ?? []).length > 0;

  const rewardsProgramShortName =
    whiteLabelConfig?.rewardsProgramShortName ?? "Caribou Rewards";

  const anyFiltersApplied =
    selectedBranchIds.length > 0 ||
    searchQueryText.length > 0 ||
    !isNull(selectedFilters.active) ||
    !isNull(selectedFilters.providedPersonalInfo);

  const clearAllFilters = () => {
    handleSearchTermChange("");
    setSelectedBranchIds([]);
    setSelectedFilters({
      active: null,
      providedPersonalInfo: null,
    });
  };

  function EmptyEmployeeState(): JSX.Element {
    if (anyFiltersApplied) {
      return (
        <Alert
          severity="info"
          message={
            <>
              No employees match the filters.{" "}
              <TextButton type="button" onClick={() => clearAllFilters()}>
                Clear the filters
              </TextButton>{" "}
              to see all employees.
            </>
          }
        />
      );
    } else {
      return (
        <Alert
          severity="info"
          message={`No employees have been set up with ${rewardsProgramShortName} accounts yet.`}
        />
      );
    }
  }

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            & > *:not(:first-of-type) {
              margin-left: ${theme.spacing(2)};
            }
          `}
        >
          <TextField
            css={css`
              width: 312px;
              margin-top: auto;
              margin-bottom: auto;
            `}
            disableAutocomplete
            hideLabel
            hideSpaceForErrorText
            label="Search"
            placeholder="Search by name"
            size="small"
            type="search"
            value={searchTerm}
            onChange={(e) => {
              handleSearchTermChange(e.target.value);
            }}
          />

          {organizationHasBranches && (
            <BranchFilter
              value={selectedBranchIds ?? [null]}
              onChange={setSelectedBranchIds}
              resourceName="Employees"
              width={350}
              onOpen={() => {
                trackScreenRecordingEvent("employees_branch_filter_opened");
                track("Opened employees branch filter");
              }}
            />
          )}

          <EmployeeFilter
            css={css`
              width: 200px;
              margin-top: auto;
              margin-bottom: auto;
            `}
            value={selectedFilters}
            disabled={false}
            onChange={(nextFilters) => {
              setCurrentPageIndex(0);
              setSelectedFilters((prev) => ({
                ...prev,
                ...nextFilters,
              }));
            }}
          />
        </div>
      </div>

      <BulkUploadProcessingModal
        open={uploadProcessingConfirmation}
        onClose={() => setUploadProcessingConfirmation(false)}
      />
      <BulkEmployeeUploadModal
        open={uploadingEmployeesInBulk}
        onClose={() => setUploadingEmployeesInBulk(false)}
        onFileUploaded={() => {
          setUploadProcessingConfirmation(true);
          organizationInfoQuery.refetch();
        }}
      />
      <AddEmployeeModal
        withGroupIds
        open={creatingNewEmployee}
        onClose={() => setCreatingNewEmployee(false)}
        onUpdate={() => employeesListQuery.refetch()}
        usesWorkDevices={usesWorkDevices}
        integratedWithAlayaCare={integratedWithAlayaCare}
        managersEnabled={managersEnabled}
      />

      {bulkUserUploadIsProcessing && <FileProcessingBanner />}

      {total === 0 ? (
        <EmptyEmployeeState />
      ) : (
        <EmployeesDataTable
          title={
            <div>
              <HeaderWithButton
                header={
                  <Typography
                    variant={newThemeEnabled ? "h4" : "h3"}
                    color="textPrimary"
                    fontWeight={700}
                    css={(theme: AppTheme) => css`
                      margin-bottom: ${theme.spacing(2)};
                    `}
                  >
                    Employee Accounts
                  </Typography>
                }
                button={
                  <div
                    css={(theme: AppTheme) => css`
                      & > *:not(:first-of-type) {
                        margin-left: ${theme.spacing(2)};
                      }
                    `}
                  >
                    <Button
                      width="auto"
                      startIcon={<GetAppIcon />}
                      size="medium"
                      color="primary"
                      label="Export to CSV"
                      onClick={() => openExportModal()}
                    />

                    <Button
                      color="primary"
                      disabled={!fullEmployeePermissionsQuery.hasPermission}
                      label="Bulk Upload"
                      size="medium"
                      startIcon={<CloudUploadIcon />}
                      width="auto"
                      onClick={() => setUploadingEmployeesInBulk(true)}
                    />

                    <Button
                      css={(theme: AppTheme) => css`
                        margin-left: ${theme.spacing(1.5)};
                      `}
                      color="primary"
                      disabled={!fullEmployeePermissionsQuery.hasPermission}
                      label="Create Employee Account"
                      size="medium"
                      startIcon={
                        integratedWithAlayaCare ? <WarningIcon /> : undefined
                      }
                      width="auto"
                      onClick={() => {
                        setCreatingNewEmployee(true);
                      }}
                    />
                  </div>
                }
              />
            </div>
          }
          currentPageIndex={currentPageIndex}
          integratedWithAlayaCare={integratedWithAlayaCare}
          editingEmployeeId={editingUserId}
          items={items}
          loading={loading}
          perPage={ITEMS_PER_PAGE}
          refreshList={() => employeesListQuery.refetch()}
          setCurrentPageIndex={setCurrentPageIndex}
          total={total}
          readOnly={!fullEmployeePermissionsQuery.hasPermission}
          canSendRecognitionPoints={
            canSendRecognitionPointsQuery.hasPermission ?? false
          }
          usesWorkDevices={usesWorkDevices}
          managersEnabled={managersEnabled}
          referralsEnabled={referralsEnabled}
          rewardsProgramShortName={rewardsProgramShortName}
          onCloseEditEmployee={userIdEditActions.navigateToOffPath}
          onOpenEditEmployee={userIdEditActions.navigateToOnPath}
        />
      )}

      <ExportEmployeesModal
        open={exportModalOpen}
        filters={selectedFilters}
        onClose={() => {
          closeExportModal();
        }}
        exportCount={total ?? 0}
      />
    </>
  );
}
