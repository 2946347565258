import { AuthorizedAdminPageWrapper } from "../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";
import { AdminGoalsSettingsPageContents } from "./page-contents";

export function SettingsAdminGoalsPage() {
  return (
    <AuthorizedAdminPageWrapper resource="settingsAdminGoals">
      <AdminGoalsSettingsPageContents />
    </AuthorizedAdminPageWrapper>
  );
}
