/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { JobPosting } from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

interface HiringPipelineJobDetailsProps {
  jobPosting: { __typename?: "JobPosting" } & Pick<
    JobPosting,
    "id" | "title" | "geography" | "closedForSubmission"
  >;
  totalSubmissions: number;
}

export function HiringPipelineJobDetails({
  jobPosting,
  totalSubmissions,
}: HiringPipelineJobDetailsProps): JSX.Element {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  return (
    <div
      css={css`
        max-width: 200px;
      `}
    >
      <Typography
        display="inline"
        variant={newThemeEnabled ? "subtitle" : "h3"}
      >
        {jobPosting.title}
      </Typography>
      {jobPosting.closedForSubmission && (
        <Typography display="inline"> (Closed) </Typography>
      )}

      <Typography
        variant={newThemeEnabled ? "footnote" : undefined}
        css={css`
          ${!newThemeEnabled &&
          css`
            font-size: 0.75em;
          `}
        `}
      >
        Total Submissions:{" "}
        {newThemeEnabled ? (
          totalSubmissions
        ) : (
          <strong>{totalSubmissions}</strong>
        )}
      </Typography>

      <Typography
        variant={newThemeEnabled ? "footnote" : undefined}
        css={css`
          ${!newThemeEnabled &&
          css`
            font-size: 0.75em;
          `}
        `}
      >
        Region:{" "}
        {newThemeEnabled ? (
          jobPosting.geography
        ) : (
          <strong>{jobPosting.geography}</strong>
        )}
      </Typography>
    </div>
  );
}
