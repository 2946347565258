/** @jsxImportSource @emotion/react */
import { Typography } from "@rewards-web/shared/components/typography";
import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { SurveyQuestionTypeSupportedByUI } from "../constants";

export const rawScoreText = ({
  score,
  questionType,
  includeScore = true,
}: {
  score: number;
  questionType?: SurveyQuestionTypeSupportedByUI;
  includeScore?: boolean;
}) => {
  if (questionType === SurveyQuestionType.Nps) {
    if (score === 0) {
      return "0 (Not likely at all)";
    }
    if (score === 10) {
      return "10 (Extremely likely)";
    }
    return score;
  }

  const emojiText = (() => {
    switch (score) {
      case 1:
        return "😞 Strongly disagree";
      case 2:
        return "🫤 Disagree";
      case 3:
        return "😐 Neutral";
      case 4:
        return "😃 Agree";
      case 5:
        return "🤩 Strongly agree";
      default:
        return score;
    }
  })();

  return includeScore ? `${emojiText} (${score})` : emojiText;
};

export function ScoreText({
  questionType,
  score,
  color,
}: {
  questionType: SurveyQuestionTypeSupportedByUI;
  score: number;
  color?: Parameters<typeof Typography>[0]["color"];
}) {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  return (
    <Typography
      variant={newThemeEnabled ? "footnote" : "body"}
      color={color ?? "textPrimary"}
    >
      {rawScoreText({ score, questionType })}
    </Typography>
  );
}
