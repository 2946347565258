/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faBolt,
  faClock,
  faMinus,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInSeconds, format, subDays } from "date-fns";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  AdminGoalStatus,
  AdminGoalType,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import {
  AdminGoalProgressProps,
  GoalCardProgressBar,
} from "./goal-card-progress-bar";

interface BaseGoalCardProps {
  icon: JSX.Element;
  title: string;
  startDate: Date;
  endDate: Date;
  reward: {
    type: "gift_card";
    dollarValue: number;
  } | null;
  goalStatus: AdminGoalStatus;
  goalType: AdminGoalType;
  goalProgress: AdminGoalProgressProps;
  tipsContent: {
    title: string;
    action: JSX.Element;
    tips: string[];
  };
}

export function BaseGoalCard({
  icon,
  title,
  startDate,
  endDate,
  reward,
  goalStatus,
  goalType,
  goalProgress,
  tipsContent,
}: BaseGoalCardProps): JSX.Element {
  const theme = useTheme();
  const track = useTrack();
  // tips are expanded by default for available goals
  const [showTips, setShowTips] = useState(
    goalStatus === AdminGoalStatus.Available
  );

  const toggleShowTips = () => {
    if (!showTips) {
      track("Viewed tips on admin goal card", { goalType });
    }
    setShowTips((prev) => !prev);
  };

  const rewardText = (() => {
    if (!reward) {
      return null;
    }

    switch (reward.type) {
      case "gift_card":
        if (goalStatus === AdminGoalStatus.Failed) {
          return "$0 reward";
        }

        return `$${reward.dollarValue} reward`;
      default:
        return null;
    }
  })();

  // Note: not using `differenceInDays` here since it rounds to the nearest day (or zero),
  // and would show as "Closed" on the final day when we still want to show "1 day left".
  const daysRemaining = Math.ceil(
    differenceInSeconds(endDate, new Date()) / 86400
  );
  const goalFinished = daysRemaining < 1;

  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(4)};
        `
      }
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {icon}
        <div
          css={css`
            flex: 1;
            margin-left: 12px;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <Typography variant="h6" fontWeight={700} color="textPrimary">
              {title}
            </Typography>
            {rewardText && (
              <Typography
                variant="caption"
                fontWeight={600}
                color="white"
                css={(theme: AppTheme) => css`
                  background-color: ${goalStatus === AdminGoalStatus.Failed
                    ? theme.palette.grey[600]
                    : theme.palette.secondary.main};
                  border-radius: 100px;
                  padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
                  margin-left: ${theme.spacing(1)};
                  text-transform: uppercase;
                `}
              >
                {rewardText}
              </Typography>
            )}
          </div>
          <Typography
            variant="footnote"
            color="grey.800"
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(0.5)};
            `}
          >
            Goal period: {format(startDate, "MMM d")} -{" "}
            {format(subDays(endDate, 1), "MMM d")}
          </Typography>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            align-self: flex-start;
          `}
        >
          <FontAwesomeIcon icon={faClock} color="grey.800" />
          <Typography
            variant="footnote"
            color="grey.800"
            css={(theme: AppTheme) => css`
              margin-left: ${theme.spacing(1)};
            `}
          >
            {goalFinished
              ? "Closed"
              : `${daysRemaining} day${daysRemaining > 1 ? "s" : ""} left`}
          </Typography>
        </div>
      </div>
      <GoalCardProgressBar
        goalStatus={goalStatus}
        goalProgress={goalProgress}
      />
      <Divider
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(6)};
          margin-bottom: ${theme.spacing(3)};
        `}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            align-items: center;
            gap: ${theme.spacing(1)};
            flex: 1;
          `}
        >
          <Typography
            variant="subtitle"
            color="textPrimary"
            fontWeight={700}
            css={css`
              cursor: pointer;
            `}
            onClick={toggleShowTips}
          >
            {tipsContent.title}
          </Typography>
          {tipsContent.action}
        </div>
        <Button
          variant="text"
          label={
            showTips ? (
              <FontAwesomeIcon icon={faMinus} />
            ) : (
              <FontAwesomeIcon icon={faPlus} />
            )
          }
          width="auto"
          typographyVariant="body"
          onClick={toggleShowTips}
        />
      </div>
      {showTips && (
        <div>
          {tipsContent.tips.map((tip, index) => (
            <div
              key={index}
              css={(theme: AppTheme) => css`
                display: flex;
                align-items: center;
                gap: ${theme.spacing(1)};
                margin-top: ${theme.spacing(1)};
              `}
            >
              <FontAwesomeIcon
                icon={faBolt}
                color={theme.palette.primary.main}
              />
              <Typography variant="body" color="grey.800">
                {tip}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}
