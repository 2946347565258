/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact, groupBy, sortBy } from "lodash";

import {
  AdminGoal,
  AdminGoalStatus,
  AdminGoalType,
} from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { BudgetUtilizationGoalCard } from "./goal-cards/budget-utilization-goal-card";
import { FirstMonthLoginGoalCard } from "./goal-cards/first-month-login-goal-card";

const goalToGoalCard = (goal: AdminGoal, pointsPerDollar: number) => {
  switch (goal.type) {
    case AdminGoalType.BudgetUtilization:
      return (
        <div
          key={goal.id}
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(3)};
            `
          }
        >
          <BudgetUtilizationGoalCard
            goal={goal}
            pointsPerDollar={pointsPerDollar}
          />
        </div>
      );
    case AdminGoalType.LoginAfterLaunch:
      return (
        <div
          key={goal.id}
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(3)};
            `
          }
        >
          <FirstMonthLoginGoalCard goal={goal} />
        </div>
      );
    default:
      // Goal type currently unsupported by the UI
      return null;
  }
};

const goalsToSortedGoalCards = (
  goals: AdminGoal[],
  pointsPerDollar: number
) => {
  return compact(
    sortBy(goals, (goal) => goal.endDate).map((goal) =>
      goalToGoalCard(goal, pointsPerDollar)
    )
  );
};

export function getGoalCards(
  adminGoals: AdminGoal[],
  pointsPerDollar: number
): { availableGoalCards: JSX.Element[]; pastGoalCards: JSX.Element[] } {
  const goalsByStatus = groupBy(adminGoals, "status");

  const goalsWithStatus = (status: AdminGoalStatus) =>
    goalsByStatus[status] ?? [];

  const availableGoalCards = goalsToSortedGoalCards(
    goalsWithStatus(AdminGoalStatus.Available),
    pointsPerDollar
  );

  const pastGoalCards = goalsToSortedGoalCards(
    [
      ...goalsWithStatus(AdminGoalStatus.Achieved),
      ...goalsWithStatus(AdminGoalStatus.Failed),
    ],
    pointsPerDollar
  );

  return { availableGoalCards, pastGoalCards };
}
