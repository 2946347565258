/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { formatInTimeZone } from "date-fns-tz";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { AllTimePerformanceInsightMetricGroup } from "../../insight-metric-groups/all-time-performance";

interface AllTimeInsightMetricGroupRangeProps {
  launchedAt: Date;
  timezone: string;
  pointsPerDollar: number;
  referralsEnabled: boolean;
}

/**
 * Insight metrics that are shown for all time - no date range picker.
 */
export function AllTimeInsightMetricGroupRange({
  launchedAt,
  timezone,
  pointsPerDollar,
  referralsEnabled,
}: AllTimeInsightMetricGroupRangeProps) {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  if (!referralsEnabled) {
    return null; // hide all-time metrics if referrals are not enabled
  }
  return (
    <Card
      css={(theme: AppTheme) => css`
        position: relative;
        padding: ${theme.spacing(3)};
        padding-top: ${theme.spacing(2)};
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          position: absolute;
          left: 0;
          top: 0;
          width: 12px;
          height: 100%;
          border-radius: 10px 0 0 10px;
          background-color: ${theme.palette.secondary.main};
          gap: ${theme.spacing(3.75)};
        `}
      ></div>
      <div
        css={(theme: AppTheme) => css`
          position: absolute;
          right: ${theme.spacing(3)};
          top: ${theme.spacing(3.75)};
        `}
      >
        <Typography
          variant={newThemeEnabled ? "body" : "h4"}
          fontWeight={700}
          color="grey.600"
        >
          Launched {formatInTimeZone(launchedAt, timezone, "MMMM yyyy")}
        </Typography>
      </div>
      <AllTimePerformanceInsightMetricGroup
        launchDate={launchedAt}
        pointsPerDollar={pointsPerDollar}
      />
    </Card>
  );
}
