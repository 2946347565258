import { useLayoutEffect, useState } from "react";

/**
 * Manages the state of a drawer, maintaining state until
 * the drawer completes its exit transition.
 *
 * This can be used to ensure that a drawer is only unmounted
 * after its exit transition has completed, to ensure the exit
 * transition can complete.
 */
export function useDrawerControl<T>(
  liveState: T | undefined
): [
  {
    open: boolean;
    state: T | undefined;
  },
  {
    onExited(): void;
  }
] {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cachedState, setCachedState] = useState<T | undefined>(liveState);

  useLayoutEffect(() => {
    if (liveState) {
      setDrawerOpen(true);
      setCachedState(liveState);
    } else {
      setDrawerOpen(false);
    }
  }, [liveState]);

  return [
    {
      open: drawerOpen,
      state: cachedState,
    },
    {
      onExited: () => {
        setCachedState(undefined);
      },
    },
  ];
}
