/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  get,
  useFormState,
} from "react-hook-form";

import { Card } from "@rewards-web/shared/components/card";
import { SearchTextField } from "@rewards-web/shared/components/search-text-field";
import { SelectField } from "@rewards-web/shared/components/select-field";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { isEmail } from "@rewards-web/shared/lib/is-email";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useMyBranches } from "../../../../shared/modules/branches/use-my-branches";
import { AdminBranchesField } from "../admin-branches-field";
import { adminRolesMap } from "../lib";

const MAX_JOB_TITLE_LENGTH = 50;

const jobTitleList = [
  "Care Coordinator",
  "Scheduler",
  "Talent Acquisition Specialist",
  "Recruiter",
  "Care Manager",
  "Supervisor",
  "HR Business Partner",
  "HR Supervisor",
  "HR Manager",
  "HR Director",
  "HR Recruitment Coordinator",
];

const rolesList = Object.entries(adminRolesMap).map(([key, value]) => {
  return {
    label: value,
    value: key,
  };
});
interface AdminAccountDetailsProps<T extends FieldValues> {
  control: Control<T>;
  fieldNames: {
    firstName: Path<T>;
    lastName: Path<T>;
    branchIds: Path<T>;
    active?: Path<T>;
    jobTitle: Path<T>;
    role: Path<T>;
    email: Path<T>;
  };
  readOnly: boolean;
  currentAdminId?: string;
  editingAdminId?: string;
}

export function AdminAccountDetails<T extends FieldValues>({
  control,
  fieldNames,
  currentAdminId,
  editingAdminId,
  readOnly,
}: AdminAccountDetailsProps<T>): JSX.Element {
  const formState = useFormState({ control });
  const branchesQuery = useMyBranches();

  const roleSectionEnabled = useFeatureFlag("admin-app-admin-role-temp");
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  return (
    <Card
      css={(theme: AppTheme) => css`
        max-width: 861px;
        padding: ${theme.spacing(4)};
        margin-bottom: ${theme.spacing(3)};
      `}
    >
      <Typography
        variant={newThemeEnabled ? "h5" : "h3"}
        component="h2"
        color="textPrimary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        Account details
      </Typography>

      <div
        css={css`
          max-width: 496px;
        `}
      >
        <TextField
          label="First name"
          disableAutocomplete
          disabled={readOnly}
          error={get(formState.errors, fieldNames.firstName)}
          {...control.register(fieldNames.firstName, {
            required: "First name is required",
          })}
        />

        <TextField
          label="Last name"
          disableAutocomplete
          disabled={readOnly}
          error={get(formState.errors, fieldNames.lastName)}
          {...control.register(fieldNames.lastName, {
            required: "Last name is required",
          })}
        />

        {(branchesQuery.data?.getMyRewardsOrganization.branches.length ?? 0) >
          0 && (
          <AdminBranchesField
            control={control}
            name={fieldNames.branchIds}
            disabled={readOnly}
          />
        )}

        {fieldNames.active && (
          <Controller
            control={control}
            name={fieldNames.active}
            render={({ field }) => (
              <Tooltip
                title={
                  currentAdminId === editingAdminId
                    ? "You cannot modify your own account status."
                    : ""
                }
                placement="top"
                disabled={currentAdminId !== editingAdminId || readOnly}
                css={css`
                  width: 100%;
                `}
              >
                <div>
                  <SelectField
                    {...field}
                    label="Admin status"
                    readOnly={currentAdminId === editingAdminId || readOnly}
                    disabled={formState.isSubmitting}
                    width="full"
                    options={[
                      { label: "Active", value: "true" },
                      {
                        label: "De-activated",
                        value: "false",
                      },
                    ]}
                  />
                </div>
              </Tooltip>
            )}
          />
        )}

        <TextField
          label="Email address"
          disableAutocomplete
          disabled={readOnly}
          error={get(formState.errors, fieldNames.email)}
          {...control.register(fieldNames.email, {
            required: "Email is required",
            validate: (value: string) => {
              if (!isEmail(value)) {
                return "Please enter a valid email address";
              }
            },
          })}
        />
        {roleSectionEnabled && (
          <Controller
            control={control}
            name={fieldNames.role}
            render={({ field, fieldState }) => {
              return (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label="Role"
                  width="full"
                  disabled={readOnly || formState.isSubmitting}
                  options={rolesList}
                />
              );
            }}
          />
        )}
        <Controller
          control={control}
          name={fieldNames.jobTitle}
          rules={{
            validate: (value) => {
              if (value && value.length > MAX_JOB_TITLE_LENGTH) {
                return getCharactersRemainingText(value, MAX_JOB_TITLE_LENGTH);
              }
            },
          }}
          render={({ field, fieldState }) => (
            <SearchTextField
              {...field}
              error={fieldState.error}
              placeholder="Select a job title or type a custom one..."
              allowCustomValues
              label="Job title (optional)"
              disabled={readOnly}
              options={jobTitleList.map((jobTitle) => ({
                value: jobTitle,
                label: jobTitle,
              }))}
            />
          )}
        />
      </div>
    </Card>
  );
}
