/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faPlus, faTrash, faEye } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { useDrawerControl } from "@rewards-web/shared/hooks/use-drawer-control";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { HeaderWithButton } from "../../../shared/components/header-with-button";
import { DeleteDrawConfirmationModal } from "./delete-draw-confirmation-modal";
import { useDrawListDataQuery } from "./draw-list-data.generated";
import { DrawListEmptyState } from "./draw-list-empty-state";
import { DrawListTable, DrawListTableProps } from "./draw-list-table";
import { groupDrawsBySection, getDrawSectionTitleAndColor } from "./lib";

export function DrawPageContents() {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  const track = useTrack();
  const navigate = useNavigate();
  const [deletingDrawId, setDeletingDrawId] = useState<string | null>(null);
  const [drawerState, drawerControl] = useDrawerControl(deletingDrawId);

  const drawListData = useDrawListDataQuery({
    onError: reportError,
  });
  const theme = useTheme();

  const content = (() => {
    if (drawListData.error) {
      return (
        <Alert
          severity="error"
          message="An error occurred loading draws. Please try again later."
        />
      );
    }

    if (!drawListData.data) {
      return <PageLoadingState />;
    }

    const {
      awaitingWinnerApproval: drawsAwaitingWinnerApproval,
      live: liveDraws,
      scheduled: scheduledDraws,
      completed: completedDraws,
    } = groupDrawsBySection(drawListData.data.getAllDraws);

    const sections: Omit<DrawListTableProps, "timezone">[] = [
      {
        ...getDrawSectionTitleAndColor("awaitingWinnerApproval", theme),
        draws: drawsAwaitingWinnerApproval,
        getActionNode: (draw) => (
          <>
            <Button
              variant="outlined"
              size="small"
              label="Review winner(s)"
              linkTo={`/draw/${draw.id}/approve-winners`}
              onClick={() => {
                track("Clicked approve draw winners button from list table");
              }}
            />
          </>
        ),
      },
      {
        ...getDrawSectionTitleAndColor("live", theme),
        draws: liveDraws,
      },
      {
        ...getDrawSectionTitleAndColor("scheduled", theme),
        draws: scheduledDraws,
        getActionNode: (draw) => (
          <>
            <IconButton
              onClick={() => {
                setDeletingDrawId(draw.id);
              }}
              aria-label="Delete Draw"
            >
              <FontAwesomeIcon icon={faTrash} size="xs" />
            </IconButton>

            <DeleteDrawConfirmationModal
              open={drawerState.open && drawerState.state === draw.id}
              drawId={drawerState.state ?? null}
              drawName={draw.name ?? null}
              onDeleted={() => {
                drawListData.refetch();
              }}
              onClose={() => {
                setDeletingDrawId(null);
              }}
              onExited={drawerControl.onExited}
            />
          </>
        ),
      },
      {
        ...getDrawSectionTitleAndColor("completed", theme),
        draws: completedDraws,
        getActionNode: (draw) => (
          <IconButton
            onClick={() => navigate(`/draw/${draw.id}`)}
            aria-label="Draw Details"
          >
            <FontAwesomeIcon icon={faEye} size="xs" />
          </IconButton>
        ),
      },
    ];

    if (sections.every((section) => section.draws.length === 0)) {
      return <DrawListEmptyState />;
    }

    return sections
      .filter((section) => section.draws.length > 0) // hide sections with no draws
      .map((section, index) => (
        <DrawListTable
          key={index}
          {...section}
          timezone={drawListData.data!.getMyRewardsOrganization.timezone}
        />
      ));
  })();

  return (
    <>
      <HeaderWithButton
        header={
          <Typography
            variant={newThemeEnabled ? "h3" : "h1"}
            component="h1"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            Draw
          </Typography>
        }
        button={
          <Button
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            label="Schedule a draw"
            color="primary"
            size={newThemeEnabled ? "small" : "medium"}
            linkTo="/draw/schedule-new"
          />
        }
      />
      {content}
    </>
  );
}
