/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { CardContent } from "@rewards-web/shared/components/card/card-content";
import { Divider } from "@rewards-web/shared/components/divider";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme, fontFamily } from "@rewards-web/shared/style/theme";

import { PageCard } from "../../../../../../shared/components/page-card";
import { PageCardActions } from "../../../../../../shared/components/page-card/page-card-actions";
import {
  DESCRIPTION_TEXT,
  RECOMMENDATION_TEXT,
  RedemptionThresholdField,
} from "../../../../../../shared/modules/settings/fields/settings-redemption-threshold-field";
import { InfoIcon } from "../../../../../../shared/modules/settings/fields/settings-redemption-threshold-field/info-icon";
import { useUpdateRedemptionThresholdMutation } from "./update-redemption-threshold.generated";

export interface SettingsRedemptionThresholdFormValues {
  redemptionThreshold: string;
}

interface SettingsRedemptionThresholdFormProps {
  pointsPerDollar: number;
  initialValue: number;
}

export function SettingsRedemptionThresholdForm({
  pointsPerDollar,
  initialValue,
}: SettingsRedemptionThresholdFormProps): JSX.Element {
  const form = useForm<SettingsRedemptionThresholdFormValues>({
    defaultValues: {
      redemptionThreshold: initialValue.toString(),
    },
  });
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const [updateRedemptionThreshold] = useUpdateRedemptionThresholdMutation();
  const snackbar = useSnackbar();

  const { control } = form;

  const redemptionThresholdValue = useWatch({
    control,
    name: "redemptionThreshold",
  });

  return (
    <>
      <Modal
        width="550px"
        open={confirmationModalOpen}
        onClose={closeConfirmationModal}
      >
        <ModalTitle>{"Update minimum redemption amount?"}</ModalTitle>

        <ModalContent>
          <Typography>
            {`
            The update will take effect immediately in the app, employees will now require ${numberWithCommas(
              parseInt(redemptionThresholdValue)
            )} points (${formatDollars(
              parseInt(redemptionThresholdValue) / pointsPerDollar
            )}) to redeem their points for rewards.`}
            <br />
            <br />
            {`Employees will not receive an email or SMS notification about this update. It is up to your discretion to communicate this change.`}
          </Typography>
        </ModalContent>

        <ModalActions>
          <Button
            label="Cancel"
            variant="outlined"
            onClick={closeConfirmationModal}
          />

          <Button
            color={"primary"}
            label="Confirm"
            type="submit"
            onClick={async () => {
              try {
                await updateRedemptionThreshold({
                  variables: {
                    minimumPointsNeededToRedeem: parseInt(
                      redemptionThresholdValue
                    ),
                  },
                });
                snackbar.show({
                  severity: "success",
                  message:
                    "Success! The minimum redemption amount has been updated.",
                });
              } catch (e) {
                reportError(e);
                snackbar.show({
                  severity: "error",
                  message:
                    "An unexpected error has occurred. Please try again later.",
                });
              } finally {
                closeConfirmationModal();
              }
            }}
          />
        </ModalActions>
      </Modal>

      <Form
        submitting={form.formState.isSubmitting}
        onSubmit={form.handleSubmit(() => setConfirmationModalOpen(true))}
      >
        <PageCard>
          <CardContent
            css={(theme: AppTheme) => css`
              padding-left: ${theme.spacing(3)};
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Typography
                css={css`
                  font-weight: 800;
                  font-family: ${fontFamily};
                `}
                color="textPrimary"
                variant={newThemeEnabled ? "h5" : "h3"}
                component="h2"
              >
                Employee minimum redemption
              </Typography>

              <Tooltip
                css={css`
                  display: flex;
                  padding-left: 4px;
                `}
                title={RECOMMENDATION_TEXT}
              >
                <InfoIcon />
              </Tooltip>
            </div>
          </CardContent>

          <Divider />

          <CardContent
            css={(theme: AppTheme) => css`
              padding-left: ${theme.spacing(3)};
              max-width: 768px;
            `}
          >
            <Typography
              variant="body"
              color="textSecondary"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(3.75)};
              `}
            >
              {DESCRIPTION_TEXT}
            </Typography>

            <RedemptionThresholdField
              control={control}
              name="redemptionThreshold"
            />
          </CardContent>

          <PageCardActions disabled={!form.formState.isDirty} />
        </PageCard>
      </Form>
    </>
  );
}
