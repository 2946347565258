/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { HhaxCaribouUserSyncFiltersInput } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { EnableHHAXAutoSyncConfirmationModal } from "../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/enable-hhax-auto-sync-confirmation-modal";
import { HhaxAutoSyncFilters } from "../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-auto-sync-filters";
import { useHhaxCaregiverFilterOptionsQuery } from "../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-caregiver-filter-options.generated";
import { HHAXAutoSyncCaregiverPreviewTable } from "../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-caregiver-preview-table";
import { HHAXAutoSyncCaregiverTitleTotal } from "../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-caregiver-title-total";
import {
  HhaxCaregiversToSyncQueryVariables,
  useHhaxCaregiversToSyncQuery,
} from "../../../../../shared/modules/employee-auto-sync/components/configure-hhax-employee-auto-sync/hhax-caregivers-to-sync.generated";
import { SuccessPageContents } from "./success-page-contents";

const ITEMS_PER_PAGE = 10;

export function EnableSyncPageContents(): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();
  const track = useTrack();
  const integrationId = params.id!;
  const shortenedId = integrationId?.split("-")[0];

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  const [
    filterValues,
    setFilterValues,
  ] = useState<HhaxCaribouUserSyncFiltersInput>({
    disciplines: undefined,
    officeIds: undefined,
    branchIds: undefined,
    teamIds: undefined,
    hasCompletedFirstShift: undefined,
  });

  const filterOptionsQuery = useHhaxCaregiverFilterOptionsQuery({
    onError: reportError,
    variables: {
      hhaxIntegrationId: integrationId,
    },
  });

  const queryVariables: HhaxCaregiversToSyncQueryVariables = useMemo(
    () => ({
      hhaxIntegrationId: integrationId,
      limit: ITEMS_PER_PAGE,
      offset: currentPageIndex * ITEMS_PER_PAGE,
      filters: filterValues,
    }),
    [integrationId, currentPageIndex, filterValues]
  );

  const caregiversToSyncQuery = useHhaxCaregiversToSyncQuery({
    onError: reportError,
    variables: queryVariables,
  });

  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  if (filterOptionsQuery.error || caregiversToSyncQuery.error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const listLoading = caregiversToSyncQuery.loading;
  const total = caregiversToSyncQuery.data?.caregiversToSync?.total;
  const items = caregiversToSyncQuery.data?.caregiversToSync?.items ?? [];
  const filterOptions = filterOptionsQuery.data?.filterOptions;

  const updateQueryFilters = (newFilters: HhaxCaribouUserSyncFiltersInput) => {
    setCurrentPageIndex(0);
    setFilterValues({
      ...filterValues,
      ...newFilters,
    });
  };

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Button
          variant="text"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          label="Back"
          width="auto"
          typographyVariant="body"
          onClick={() => {
            navigate(-1);
          }}
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        />
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Typography
            variant="footnote"
            color="grey.800"
            css={(theme: AppTheme) => css`
              margin-right: ${theme.spacing(2)};
            `}
          >
            Got questions about importing?
          </Typography>
          <Button
            startIcon={<FontAwesomeIcon icon={faEnvelope} />}
            label="Ask us"
            linkTo="mailto:help@caribou.care"
            externalLink
            color="primary"
            size="medium"
            width="auto"
            onClick={() =>
              track("HHAX Auto Sync clicked Ask Us button", { integrationId })
            }
          />
        </div>
      </div>
      <Typography
        variant={newThemeEnabled ? "h3" : "h2"}
        color="textPrimary"
        fontWeight={700}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        Integration ID: {shortenedId}
      </Typography>
      {showSuccessPage ? (
        <SuccessPageContents totalRecords={total ?? 0} />
      ) : (
        <>
          <Typography
            variant="body"
            color="grey.800"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(5)};
            `}
          >
            Choose filters and confirm employees for the program. Caribou will
            automatically invite new employees who match these filters.
          </Typography>

          <Card
            css={(theme: AppTheme) => css`
              padding: ${theme.spacing(3)};
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            <HhaxAutoSyncFilters
              value={filterValues}
              onChange={updateQueryFilters}
              filterOptions={filterOptions}
              initializeAllPossibleValuesOnOptionsLoad
              integrationId={integrationId}
            />
          </Card>
          <HHAXAutoSyncCaregiverPreviewTable
            integrationId={integrationId}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPageIndex={currentPageIndex}
            onCurrentPageIndexChange={setCurrentPageIndex}
            loading={listLoading}
            items={items}
            total={total}
            title={
              <HHAXAutoSyncCaregiverTitleTotal
                total={total}
                integrationId={integrationId}
              />
            }
          />
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Button
              label="Confirm"
              color="primary"
              size="large"
              width="auto"
              disabled={items.length === 0}
              onClick={() => setConfirmationModalOpen(true)}
              css={css`
                margin-top: 30px;
              `}
            />
          </div>
          <EnableHHAXAutoSyncConfirmationModal
            integrationId={integrationId}
            filters={filterValues}
            open={confirmationModalOpen}
            onClose={() => setConfirmationModalOpen(false)}
            onSuccess={() => setShowSuccessPage(true)}
          />
        </>
      )}
    </>
  );
}
