/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { DialogTitle } from "@material-ui/core";
import { ReactNode } from "react";

import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { StylableProps } from "../../types";
import { Typography } from "../typography";

interface ModalTitleProps extends StylableProps {
  children: ReactNode;
  fontSize?: string;
}

export function ModalTitle({ className, children, fontSize }: ModalTitleProps) {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  return (
    <DialogTitle className={className} disableTypography>
      <Typography
        css={() => {
          if (fontSize) {
            return css`
              font-size: ${fontSize};
            `;
          }
        }}
        variant={newThemeEnabled ? "h4" : "h5"}
        component="h1"
        color="textPrimary"
      >
        {children}
      </Typography>
    </DialogTitle>
  );
}
