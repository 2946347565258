/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AddIcon from "@material-ui/icons/Add";
import { groupBy } from "lodash";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { Pagination } from "../../../shared/components/pagination";
import { useOrganizationLaunchStatusQuery } from "../../../shared/modules/launch/hooks/use-organization-launch-status/organization-launch-status.generated";
import { useHasPermissionQuery } from "../../../shared/modules/permissions/hooks/use-has-permission-query";
import { AddCandidateModal } from "./add-candidate-modal";
import { CandidateFilter } from "./candidate-filter";
import {
  getInitialCandidateFilterStepOptionsForOrganizationSteps,
  serializeCandidateFilterStepOptionIntoGraphQLInput,
} from "./candidate-filter/lib";
import { CandidateJobFilter } from "./candidate-job-filter";
import {
  CandidateJobFilterOptionsQuery,
  useCandidateJobFilterOptionsQuery,
} from "./candidate-job-filter-options.generated";
import { CandidateList } from "./candidate-list";
import { CandidateListEmptyState } from "./candidate-list-empty-state";
import { useCandidateDrawerAccordionState } from "./candidate-list/candidate-details-drawer/use-accordion-state";
import { useMyRewardsOrganizationQuery } from "./candidate-list/my-rewards-organization.generated";
import { CandidateSearchBar } from "./candidate-search-bar";
import { CandidateSorter } from "./candidate-sorter";
import { useCandidatesCountQuery } from "./candidates-count-query.generated";
import { useCandidatesListQuery } from "./candidates-list-query.generated";
import { useCandidatesPageDataQuery } from "./candidates-page-data.generated";
import { useCandidatesFilterState } from "./hooks/use-candidates-filter-state";
import {
  useCandidatesSortState,
  DEFAULT_ORDER,
} from "./hooks/use-candidates-sort-state";
import { OrganizationSteps, getOrganizationSteps } from "./lib";
import { MergeCandidatesDrawer } from "./merge-candidates-drawer";
import MergeIcon from "./merge-icon";

const JOB_LIMIT = 1000;
const ITEMS_PER_PAGE = 25;

export function CandidatesPageContents(): JSX.Element {
  const jobOptionsQuery = useCandidateJobFilterOptionsQuery({
    onError: reportError,
    variables: {
      limit: JOB_LIMIT,
      offset: 0,
    },
  });

  const totalJobPostings = jobOptionsQuery.data?.listJobPostings.total;
  useEffect(() => {
    if (typeof totalJobPostings === "number" && totalJobPostings > JOB_LIMIT) {
      reportError(
        new Error(
          `Warning -- organization with > ${JOB_LIMIT} jobs; only top ${JOB_LIMIT} shown in candidate job filter`
        )
      );
    }
  }, [totalJobPostings]);

  const [searchParams] = useSearchParams();
  const [addCandidateModalOpen, setAddCandidateModalOpen] = useState(false);
  const [mergeCandidatesDrawerOpen, setMergeCandidatesDrawerOpen] = useState(
    false
  );

  /**
   * Step filters are set based on the organization's configuration
   */
  const [
    organizationSteps,
    setOrganizationSteps,
  ] = useState<null | OrganizationSteps>(null);
  const [selectedOrder, setSelectedOrder] = useCandidatesSortState();
  const [selectedFilters, setSelectedFilters] = useCandidatesFilterState();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const launchStatusQuery = useOrganizationLaunchStatusQuery({
    onError: reportError,
  });
  const fullCandidatesPermissionQuery = useHasPermissionQuery(
    "full",
    "candidates"
  );
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  const candidatesPageData = useCandidatesPageDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const preLaunch =
    launchStatusQuery.data?.getMyRewardsOrganization.launched === false;

  const readOnly =
    !fullCandidatesPermissionQuery.hasPermission ||
    candidatesPageData.data?.getMyRewardsOrganization.referralsEnabled ===
      false;

  const myRewardsOrganizationQuery = useMyRewardsOrganizationQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });
  const candidatesCountQuery = useCandidatesCountQuery({
    onError: reportError,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  const setFiltersToDefault = useCallback(
    (
      organizationSteps: OrganizationSteps,
      jobQueryData: CandidateJobFilterOptionsQuery
    ) => {
      const branchIdsWithJobs = jobQueryData.listJobPostings.items.reduce<
        Set<string>
      >(
        (prev, job) => (job.branch ? prev.add(job.branch.id) : prev),
        new Set()
      );

      setCurrentPageIndex(0);
      setSelectedFilters({
        branchIds:
          (jobQueryData.getMyRewardsAdminUser?.branches ?? []).length > 0
            ? (jobQueryData.getMyRewardsAdminUser?.branches ?? [])
                .map((branch) => branch.id)
                .filter((branchId) => branchIdsWithJobs.has(branchId))
            : null,
        statuses: getInitialCandidateFilterStepOptionsForOrganizationSteps(
          organizationSteps
        ).map(serializeCandidateFilterStepOptionIntoGraphQLInput),

        // don't show archived by default
        archived: false,
        hasUploadedResume: null,
        searchQuery: null,
      });
    },
    [setSelectedFilters]
  );

  useEffect(() => {
    if (
      myRewardsOrganizationQuery.data &&
      !organizationSteps &&
      jobOptionsQuery.data
    ) {
      // initialize filters once data loads

      const organizationSteps = getOrganizationSteps(
        myRewardsOrganizationQuery.data.getMyRewardsOrganization.candidateSteps,
        myRewardsOrganizationQuery.data.getMyRewardsOrganization
          .referralRewardStructure
      );

      setOrganizationSteps(organizationSteps);
      if (Array.from(searchParams).length === 0) {
        // no query params means we're probably hitting an initial page visit,
        // so let's set the default filters
        setFiltersToDefault(organizationSteps, jobOptionsQuery.data);
      }
    }
  }, [
    jobOptionsQuery.data,
    myRewardsOrganizationQuery.data,
    organizationSteps,
    setFiltersToDefault,
    searchParams,
  ]);

  const assignedToJobPostingIdsFilter = useMemo((): string[] | null => {
    if (
      !selectedFilters?.assignedToJobPostingIds &&
      !selectedFilters?.branchIds
    ) {
      return null;
    }

    const jobsByBranchId = groupBy(
      jobOptionsQuery.data?.listJobPostings.items ?? [],
      (job) => job.branch?.id ?? null
    );

    return Array.from(
      new Set([
        ...(selectedFilters.assignedToJobPostingIds ?? []),
        ...(selectedFilters.branchIds ?? []).flatMap((branchId) =>
          (jobsByBranchId[branchId] ?? []).map((job) => job.id)
        ),
      ])
    );
  }, [
    jobOptionsQuery.data,
    selectedFilters?.assignedToJobPostingIds,
    selectedFilters?.branchIds,
  ]);

  const candidatesListQuery = useCandidatesListQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first", // prevent from reloading query automatically after a mutation
    skip: !selectedFilters || !jobOptionsQuery.data,
    variables: selectedFilters
      ? {
          searchQuery: selectedFilters.searchQuery,
          archived: selectedFilters.archived,
          hasUploadedResume: selectedFilters.hasUploadedResume,
          assignedToJobPostingIds: assignedToJobPostingIdsFilter,
          statuses: selectedFilters.statuses,
          offset: currentPageIndex * ITEMS_PER_PAGE,
          limit: ITEMS_PER_PAGE,
          order: selectedOrder,
        }
      : undefined,
  });

  const [
    candidateDrawerAccordionState,
    setCandidateDrawerAccordionState,
  ] = useCandidateDrawerAccordionState();

  const content = (() => {
    if (
      [myRewardsOrganizationQuery, candidatesListQuery].some(
        (query) => query.error
      )
    ) {
      return (
        <Alert
          severity="error"
          message="An unexpected error occurred. Please try again later."
        />
      );
    }

    if (
      [myRewardsOrganizationQuery, candidatesListQuery].some(
        (query) => !query.data || query.loading
      )
    ) {
      return <PageLoadingState />;
    }

    if (candidatesListQuery.data!.listCandidatesV2.total === 0) {
      return (
        <CandidateListEmptyState
          readOnly={readOnly || preLaunch}
          totalCandidatesIgnoringFilters={
            candidatesCountQuery.data?.listCandidatesV2.total
          }
          organizationSteps={organizationSteps!}
          setFiltersToDefault={() =>
            setFiltersToDefault(organizationSteps!, jobOptionsQuery.data!)
          }
          showAllCandidates={() => {
            setCurrentPageIndex(0);
            setSelectedFilters((prev) => ({
              ...prev,
              statuses: null,
              archived: null,
              hasUploadedResume: null,
              searchQuery: null,
            }));
          }}
          selectedFilters={selectedFilters!}
          openAddCandidateModal={() => setAddCandidateModalOpen(true)}
        />
      );
    }

    return (
      <>
        <CandidateList
          candidates={candidatesListQuery.data!.listCandidatesV2.items}
          steps={
            myRewardsOrganizationQuery.data!.getMyRewardsOrganization
              .candidateSteps
          }
          referralRewardStructure={
            myRewardsOrganizationQuery.data!.getMyRewardsOrganization
              .referralRewardStructure
          }
          onRefreshList={candidatesListQuery.refetch}
          candidateDrawerAccordionState={candidateDrawerAccordionState}
          onChangeCandidateDrawerAccordionState={
            setCandidateDrawerAccordionState
          }
          resumesEnabled={
            myRewardsOrganizationQuery.data!.getMyRewardsOrganization
              .referralResumesEnabled
          }
        />
      </>
    );
  })();

  const total = (candidatesListQuery.data ?? candidatesListQuery.previousData)
    ?.listCandidatesV2.total;

  const organizationHasCandidates = Boolean(
    typeof candidatesCountQuery.data?.listCandidatesV2.total === "number" &&
      candidatesCountQuery.data.listCandidatesV2.total > 0
  );

  return (
    <>
      <Typography
        variant={newThemeEnabled ? "h3" : "h1"}
        color="textPrimary"
        css={(theme: AppTheme) => css`
          padding-bottom: ${theme.spacing(3)};
          position: relative;
          width: calc(100% - ${theme.spacing(8)});
          max-width: 1400px;
        `}
      >
        Candidates
      </Typography>
      {organizationSteps && selectedFilters && (
        <>
          <CandidateSearchBar
            value={selectedFilters.searchQuery ?? ""}
            disabled={!organizationHasCandidates}
            onChange={(searchQuery) => {
              setCurrentPageIndex(0);
              setSelectedFilters((prev) => ({ ...prev, searchQuery }));
            }}
          />
          <Divider
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(4)};
              max-width: 830px;
            `}
          />
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            `}
          >
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                flex-direction: row;
                > * {
                  margin-right: ${theme.spacing(1)};
                }
              `}
            >
              <CandidateFilter
                organizationStepFilters={organizationSteps}
                value={selectedFilters}
                disabled={!organizationHasCandidates}
                onChange={(nextFilters) => {
                  setCurrentPageIndex(0);
                  setSelectedFilters((prev) => ({
                    ...prev,
                    ...nextFilters,
                  }));
                }}
              />
              <CandidateJobFilter
                jobs={jobOptionsQuery.data?.listJobPostings.items ?? []}
                value={{
                  jobPostingIds: selectedFilters.assignedToJobPostingIds ?? [],
                  branchIds: selectedFilters.branchIds ?? [],
                }}
                disabled={!organizationHasCandidates}
                onChange={({ jobPostingIds, branchIds }) => {
                  setSelectedFilters((prev) => ({
                    ...prev,
                    assignedToJobPostingIds: jobPostingIds,
                    branchIds,
                  }));
                }}
              />
              <CandidateSorter
                disabled={!organizationHasCandidates}
                value={selectedOrder}
                onChange={setSelectedOrder}
                defaultValue={DEFAULT_ORDER}
              />
            </div>
            <div
              css={(theme: AppTheme) => css`
                & > *:not(:first-child) {
                  margin-left: ${theme.spacing(1)};
                }
              `}
            >
              <Button
                size="medium"
                color="primary"
                label="Add Candidate"
                width="auto"
                disabled={preLaunch || readOnly}
                startIcon={<AddIcon />}
                onClick={() => {
                  setAddCandidateModalOpen(true);
                }}
              />

              {Boolean(
                typeof candidatesCountQuery.data?.listCandidatesV2.total ===
                  "number" &&
                  candidatesCountQuery.data.listCandidatesV2.total > 1
              ) && (
                <Button
                  size="medium"
                  color="primary"
                  disabled={!organizationHasCandidates}
                  label="Merge Candidates"
                  startIcon={<MergeIcon />}
                  width="auto"
                  onClick={() => {
                    setMergeCandidatesDrawerOpen(true);
                  }}
                />
              )}
            </div>
          </div>
          <AddCandidateModal
            open={addCandidateModalOpen}
            onClose={() => setAddCandidateModalOpen(false)}
            onAdded={() => {
              // we clear filters & reload after adding a candidate
              // so we can guarantee that the new candidate shows up
              setFiltersToDefault(organizationSteps!, jobOptionsQuery.data!);
              candidatesListQuery.refetch();
              candidatesCountQuery.refetch();
            }}
          />
          <MergeCandidatesDrawer
            open={mergeCandidatesDrawerOpen}
            onClose={() => setMergeCandidatesDrawerOpen(false)}
            onMerged={() => candidatesListQuery.refetch()}
          />
          <div
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(4)};
            `}
          >
            {content}
            {typeof total === "number" && total > 0 && (
              <Pagination
                total={total}
                currentPageIndex={currentPageIndex}
                itemsPerPage={ITEMS_PER_PAGE}
                onChange={(_, page) => {
                  setCurrentPageIndex(page - 1);
                }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
