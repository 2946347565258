import {
  SurveyInsightsDateRange,
  SurveyInsightsDateRangeLabel,
  SurveyQuestionType,
} from "@rewards-web/shared/graphql-types";

import {
  SURVEY_QUESTION_TYPES_SUPPORTED_BY_UI,
  SurveyQuestionTypeSupportedByUI,
} from "./constants";

export const shortenDateRangeLabel = (
  label: SurveyInsightsDateRangeLabel
): string => {
  switch (label) {
    case SurveyInsightsDateRangeLabel.Q1:
      return "Q1";
    case SurveyInsightsDateRangeLabel.Q2:
      return "Q2";
    case SurveyInsightsDateRangeLabel.Q3:
      return "Q3";
    case SurveyInsightsDateRangeLabel.Q4:
      return "Q4";
    case SurveyInsightsDateRangeLabel.TrialWeek_1:
      return "W1";
    case SurveyInsightsDateRangeLabel.Launch:
      return "L";
    case SurveyInsightsDateRangeLabel.TrialWeek_4:
      return "W4";
    default:
      return label;
  }
};

export const convertDateRangeToValue = (
  dateRange: SurveyInsightsDateRange | undefined
) => {
  if (!dateRange) {
    return "";
  }
  return `${dateRange.label}:${dateRange.year}`;
};

export const convertValueToDateRange = (
  value: string,
  options: SurveyInsightsDateRange[]
): SurveyInsightsDateRange => {
  const dateRange = options.find(
    (opt) => convertDateRangeToValue(opt) === value
  );

  return dateRange!;
};

export const isSurveyTypeSupportedByUI = (
  questionType: SurveyQuestionType
): questionType is SurveyQuestionTypeSupportedByUI => {
  return SURVEY_QUESTION_TYPES_SUPPORTED_BY_UI.includes(
    questionType as SurveyQuestionTypeSupportedByUI
  );
};
