/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageLayout } from "../../../../../../shared/components/page-layout";
import { AuthorizedAdminPageWrapper } from "../../../../../../shared/modules/permissions/components/authorized-admin-page-wrapper";

const MAX_CONTENT_WIDTH = 1400;

type BaseGoalConfigurationPageProps = {
  children: ReactNode;
};

export function BaseGoalConfigurationPage({
  children,
}: BaseGoalConfigurationPageProps): JSX.Element {
  return (
    <AuthorizedAdminPageWrapper resource="settingsAdminGoals">
      <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
        <Button
          variant="text"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          label="Back"
          width="auto"
          typographyVariant="body"
          linkTo="/settings/office-goals"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        />
        {children}
      </PageLayout>
    </AuthorizedAdminPageWrapper>
  );
}
