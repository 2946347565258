/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";

import { Alert } from "@rewards-web/shared/components/alert";
import {
  SurveyInsightsDateRangeInput,
  SurveyQuestionType,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ResponseBreakdownCard } from "../../../components/response-breakdown-card";
import { ScoreComparisonCard } from "../../../components/score-comparison-card";
import {
  getCurrentScoreForComparison,
  getPreviousScoresForComparison,
} from "../../utils";
import { useNpsQuestionDetailsPageDataQuery } from "./nps-question-details-page-data.generated";

/**
 * Question details page contents for NPS question, scored -100 to 100.
 */
export function NpsQuestionDetailsPage({
  dateRangeInput,
}: {
  dateRangeInput?: SurveyInsightsDateRangeInput;
}) {
  const { data, error } = useNpsQuestionDetailsPageDataQuery({
    variables: {
      dateRange: dateRangeInput,
    },
    onError: reportError,
    skip: !dateRangeInput,
  });

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const previousScores = getPreviousScoresForComparison(
    data?.getSurveyNPSScore.previousScores ?? []
  );

  const currentScoreWithDateRange = getCurrentScoreForComparison({
    npsScore: data?.getSurveyNPSScore.npsScore,
    dateRange: dateRangeInput,
  });

  const totalResponseCount = data?.getScoreDistributionForSurveyQuestion.reduce(
    (acc, { count }) => acc + count,
    0
  );

  return (
    <>
      {data && (
        <>
          {totalResponseCount === 0 ? (
            <Alert
              severity="info"
              message="No responses received during this time period."
            />
          ) : (
            <>
              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  flex-wrap: wrap;
                  gap: ${theme.spacing(3)};
                `}
              >
                <ResponseBreakdownCard
                  questionType={SurveyQuestionType.Nps}
                  scores={data.getScoreDistributionForSurveyQuestion}
                />
                {previousScores?.length ? (
                  <ScoreComparisonCard
                    surveyQuestionType={SurveyQuestionType.Nps}
                    previousScores={compact([
                      ...previousScores,
                      currentScoreWithDateRange,
                    ])}
                  />
                ) : null}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
