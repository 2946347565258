/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { AdminGoal } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useAdminGoalsPageDataQuery } from "./admin-goals-page-data.generated";
import { NoActiveGoalsCard } from "./goal-cards/no-active-goals-card";
import { getGoalCards } from "./utils";

export function GoalsPageContents(): JSX.Element {
  const adminGoalsQuery = useAdminGoalsPageDataQuery({
    onError: reportError,
  });

  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  if (adminGoalsQuery.error) {
    return (
      <Alert
        message="An unexpected error occurred. Please try again later."
        severity="error"
      />
    );
  }

  if (!adminGoalsQuery.data) {
    return <PageLoadingState />;
  }

  const { adminGoals, organization } = adminGoalsQuery.data;

  const { availableGoalCards, pastGoalCards } = getGoalCards(
    adminGoals as AdminGoal[],
    organization.pointsPerDollar
  );

  return (
    <>
      <div
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(3)};
          `
        }
      >
        <Typography
          variant={newThemeEnabled ? "h3" : "h2"}
          fontWeight={700}
          color="textPrimary"
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(1)};
            `
          }
        >
          Office goals
        </Typography>
        <Typography variant="caption" color="grey.800">
          Track your monthly office goals, earn rewards, and view your progress
          at a glance.
        </Typography>
      </div>
      <Typography
        variant="h6"
        fontWeight={700}
        color="textPrimary"
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(3)};
          `
        }
      >
        Active goals
      </Typography>
      {availableGoalCards.length > 0 ? (
        availableGoalCards
      ) : (
        <div
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(3)};
            `
          }
        >
          <NoActiveGoalsCard />
        </div>
      )}
      {pastGoalCards.length > 0 && (
        <div
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(1)};
            `
          }
        >
          <Typography
            variant="h6"
            fontWeight={700}
            color="textPrimary"
            css={(theme: AppTheme) =>
              css`
                margin-bottom: ${theme.spacing(3)};
              `
            }
          >
            Past goals
          </Typography>
          {pastGoalCards}
        </div>
      )}
    </>
  );
}
