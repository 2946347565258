/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInDays, subDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useEffect } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { AdminGoalType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useAdminGoalsSettingsPageDataQuery } from "./admin-goals-settings-page-data.generated";
import {
  ADMIN_GOAL_TYPES_SUPPORTED_BY_UI,
  AdminGoalTypeSupportedByUI,
} from "./constants";
import coinsIcon from "./icons/coins.png";
import loginIcon from "./icons/login.png";

const GOAL_CONFIG_CONTENT = {
  [AdminGoalType.BudgetUtilization]: {
    label: "Recognition budget utilization",
    description:
      "Set recognition goals to make the most of your monthly budget, fostering a culture of appreciation where employees feel valued and supported.",
    icon: coinsIcon,
    iconAltText: "coins",
    linkTo: "/settings/office-goals/recognition-budget",
  },
  [AdminGoalType.LoginAfterLaunch]: {
    label: "First month login goal",
    description:
      "Set a login target to encourage engagement and keep your team active and connected.",
    icon: loginIcon,
    iconAltText: "login",
    linkTo: "/settings/office-goals/first-month-login",
  },
};

export function AdminGoalsSettingsPageContents(): JSX.Element {
  const track = useTrack();
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  const budgetUtilizationGoalEnabled = useFeatureFlag(
    "admin-app-budget-utilization-goal-available-temp"
  );

  const firstMonthLoginGoalEnabled = useFeatureFlag(
    "admin-app-first-month-login-goal-available-temp"
  );

  const adminGoalsSettingsQuery = useAdminGoalsSettingsPageDataQuery({
    onError: reportError,
  });

  const availableGoalTypes: AdminGoalTypeSupportedByUI[] = ADMIN_GOAL_TYPES_SUPPORTED_BY_UI.filter(
    // eslint-disable-next-line array-callback-return
    (goalType) => {
      switch (goalType) {
        case AdminGoalType.BudgetUtilization:
          return budgetUtilizationGoalEnabled;
        case AdminGoalType.LoginAfterLaunch: {
          // For this goal type, the feature flag needs to be enabled
          // and the organization needs to have launched less than 30 days ago
          const organizationLaunchedAt =
            adminGoalsSettingsQuery?.data?.organization?.launchedAt;

          return (
            firstMonthLoginGoalEnabled &&
            organizationLaunchedAt &&
            differenceInDays(new Date(), organizationLaunchedAt) < 30
          );
        }
        // add other goal types here
        default:
          assertNever(goalType);
      }
    }
  );

  useEffect(() => {
    track("Viewed admin goals settings page", {
      availableGoalTypes: availableGoalTypes,
    });
  }, [track, availableGoalTypes]);

  if (adminGoalsSettingsQuery.error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (adminGoalsSettingsQuery.loading || !adminGoalsSettingsQuery.data) {
    return (
      <div
        css={css`
          height: 200px;
        `}
      >
        <PageLoadingState />
      </div>
    );
  }

  if (availableGoalTypes.length === 0) {
    // The page should only be visible if they have available goal types,
    // so this would only happen if they navigate to the route directly.
    return (
      <Alert
        severity="info"
        message="There are no goals available to configure at this time."
      />
    );
  }

  const {
    getCurrentAdminGoalConfigs: existingConfigs,
  } = adminGoalsSettingsQuery.data;

  return (
    <>
      <Typography
        variant={newThemeEnabled ? "h5" : "h3"}
        fontWeight={700}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        Office goals
      </Typography>
      <Typography
        variant="footnote"
        color="grey.800"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        Set-up goals for your office staff around key metrics for your agency.
        This helps ensure your admins and your caregivers are all getting
        rewarded for achieving results!
      </Typography>
      <Typography
        variant="h6"
        fontWeight={700}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        Select one goal to start with
      </Typography>
      <div>
        {availableGoalTypes.map((goalType, idx) => {
          const goalConfigContent = GOAL_CONFIG_CONTENT[goalType];
          const existingConfig = existingConfigs.find(
            (c) => c.goalType === goalType
          );
          const startDate = existingConfig
            ? formatInTimeZone(existingConfig.startDate, "UTC", "MMM d")
            : null;
          const endDate = existingConfig
            ? formatInTimeZone(
                subDays(new Date(existingConfig.endDate), 1),
                "UTC",
                "MMM d"
              )
            : null;

          return (
            <Card
              key={`${goalType}-${idx}`}
              variant="flat"
              css={(theme: AppTheme) => css`
                padding: ${theme.spacing(3)};
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <img
                      width={30}
                      src={goalConfigContent.icon}
                      alt={goalConfigContent.iconAltText}
                    />
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      color="textPrimary"
                      css={(theme: AppTheme) => css`
                        margin-left: ${theme.spacing(2)};
                      `}
                    >
                      {goalConfigContent.label}
                    </Typography>
                  </div>
                  <Typography
                    variant="footnote"
                    color="grey.800"
                    maxWidth={543}
                    css={(theme: AppTheme) => css`
                      margin-top: ${theme.spacing(1)};
                    `}
                  >
                    {goalConfigContent.description}
                  </Typography>
                </div>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  {existingConfig && (
                    <Typography
                      variant={newThemeEnabled ? "footnote" : "caption"}
                      color="grey.800"
                      css={(theme: AppTheme) => css`
                        margin-right: ${theme.spacing(1.5)};
                      `}
                    >
                      Goal period: {startDate} - {endDate}
                    </Typography>
                  )}
                  <Button
                    variant={newThemeEnabled ? "contained" : "outlined"}
                    size="medium"
                    width="auto"
                    disabled={existingConfig ? true : false}
                    label={
                      existingConfig
                        ? existingConfig.launched
                          ? "Launched"
                          : "Scheduled"
                        : "Start"
                    }
                    endIcon={
                      existingConfig ? undefined : (
                        <FontAwesomeIcon icon={faArrowRight} width={13} />
                      )
                    }
                    linkTo={goalConfigContent.linkTo}
                    onClick={() =>
                      track("Clicked to start configuring admin goal", {
                        goalType,
                      })
                    }
                  />
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
}
