/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useFormContext, useWatch } from "react-hook-form";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { CompleteNewPasswordFormValues } from "../../types";
import CheckIcon from "./check-icon";
import CrossIcon from "./cross-icon";
import { validatePassword } from "./lib";

export function PasswordRequirements(): JSX.Element {
  const newThemeEnabled =
    useFeatureFlag("admin-app-new-theme-temp") ||
    process.env.REACT_APP_SENTRY_ENVIRONMENT === "release";
  const { control } = useFormContext<CompleteNewPasswordFormValues>();
  const password = useWatch({
    control,
    name: "password",
  });

  const validations = validatePassword(password);

  return (
    <ul
      css={css`
        padding-left: 0;
        list-style: none;
      `}
    >
      {validations.map((rule, idx) => (
        <li
          key={idx}
          css={(theme: AppTheme) =>
            css`
              ${newThemeEnabled && `margin-bottom: ${theme.spacing(1.5)}`}
            `
          }
        >
          {rule.fulfilled ? (
            <CheckIcon
              css={css`
                margin-right: 6px;
                margin-bottom: -2px;
              `}
            />
          ) : (
            <CrossIcon
              css={css`
                margin-right: 6px;
                margin-bottom: -2px;
              `}
            />
          )}{" "}
          <Typography variant="body" color="textSecondary" display="inline">
            {rule.message}
          </Typography>
        </li>
      ))}
    </ul>
  );
}
