/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faBullseyeArrow } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export function NoActiveGoalsCard(): JSX.Element {
  const theme = useTheme();

  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(4)};
          text-align: center;
        `
      }
    >
      <FontAwesomeIcon
        icon={faBullseyeArrow}
        color={theme.palette.grey[800]}
        css={css`
          height: 45px;
          width: 45px;
        `}
      />
      <Typography
        variant="subtitle"
        fontWeight={700}
        css={(theme: AppTheme) =>
          css`
            margin: ${theme.spacing(1.5)};
          `
        }
      >
        No active goals right now!
      </Typography>
      <Typography variant="footnote" color="grey.800">
        Check back soon for your next opportunity to earn more rewards
      </Typography>
    </Card>
  );
}
