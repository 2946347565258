/** @jsxImportSource @emotion/react */
import { SurveyInsightsDateRangeInput } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { SurveyQuestionTypeSupportedByUI } from "../../../constants";
import {
  getCurrentScoreForComparison,
  getPreviousScoresForComparison,
} from "../../utils";
import { BaseQuestionScoreCard } from "../base-question-score-card";
import { usePulseSurveyQuestionScoreCardDataQuery } from "./pulse-survey-question-score-card-data.generated";

export interface ScoreQuestionScoreCardProps {
  questionType: SurveyQuestionTypeSupportedByUI;
  dateRangeInput?: SurveyInsightsDateRangeInput;
}

/**
 * Score card component for non-NPS questions, scored 1-5.
 */
export function ScoreQuestionScoreCard({
  questionType,
  dateRangeInput,
}: ScoreQuestionScoreCardProps) {
  const { data, error } = usePulseSurveyQuestionScoreCardDataQuery({
    variables: {
      questionType,
      dateRange: dateRangeInput,
    },
    onError: reportError,
  });

  if (error) {
    return null;
  }

  const averageScore = (() => {
    if (!data) {
      return undefined;
    }

    // round to 1 decimal place
    return Math.round(data.getSurveyQuestionResults.averageScore * 10) / 10;
  })();

  const previousScores = getPreviousScoresForComparison(
    data?.getSurveyQuestionResults.previousAverageScores ?? []
  );

  const currentScoreWithDateRange = getCurrentScoreForComparison({
    averageScore,
    dateRange: dateRangeInput,
  });

  return (
    <BaseQuestionScoreCard
      questionType={questionType}
      dateRangeInput={dateRangeInput}
      score={averageScore}
      previousScores={previousScores}
      currentScoreWithDateRange={currentScoreWithDateRange}
      maxScore={5}
      numComments={data?.getSurveyQuestionResults.numComments}
    />
  );
}
