import { ClassNames } from "@emotion/react";
import { Drawer } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ReactNode } from "react";

import { IconButton } from "@rewards-web/shared/components/icon-button";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

export * from "./actions";
export * from "./content";

const NAVBAR_HEIGHT = 60;

interface RightDrawerProps {
  open: boolean;
  onClose(): void;
  onExited?(): void;
  children: ReactNode;
  title: string;
  disableClose?: boolean;
  maxWidth?: string;
  backgroundColor?: string;
  headerBackgroundColor?: string;
  drawerDescription?: JSX.Element;
  obscureTitleFromRecordedText?: boolean;
}

export function RightDrawer({
  open,
  onClose,
  onExited,
  children,
  title,
  disableClose,
  maxWidth,
  backgroundColor,
  headerBackgroundColor,
  drawerDescription,
  obscureTitleFromRecordedText,
}: RightDrawerProps): JSX.Element {
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");
  return (
    <ClassNames>
      {({ css, theme }) => (
        <Drawer
          anchor="right"
          open={open}
          onClose={() => {
            if (!disableClose) {
              onClose();
            }
          }}
          SlideProps={{ onExited }}
          className={css`
            margin-top: ${NAVBAR_HEIGHT}px;
          `}
          PaperProps={{
            className: css`
              margin-top: ${NAVBAR_HEIGHT}px;
              height: calc(100% - ${NAVBAR_HEIGHT}px);
              ${backgroundColor &&
              css`
                background-color: ${backgroundColor};
              `}
            `,
          }}
          BackdropProps={{
            className: css`
              top: ${NAVBAR_HEIGHT}px;
            `,
          }}
        >
          <div
            className={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;

              ${maxWidth &&
              css`
                max-width: ${maxWidth};
              `}
            `}
          >
            <div
              className={css`
                min-width: 320px;
                padding: ${theme.spacing(drawerDescription ? 2 : 1)}
                  ${theme.spacing(2.5)};
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 0 0 auto;
                ${headerBackgroundColor &&
                css`
                  background-color: ${headerBackgroundColor};
                `}
              `}
            >
              <div>
                <Typography
                  variant={newThemeEnabled ? "h5" : "h3"}
                  component="h1"
                  className={css`
                    ${!newThemeEnabled &&
                    `
                      font-size: 1.2rem;
                    `}
                  `}
                >
                  {obscureTitleFromRecordedText ? (
                    <ObscureRecordedText>{title}</ObscureRecordedText>
                  ) : (
                    title
                  )}
                </Typography>
                {drawerDescription && drawerDescription}
              </div>
              <IconButton
                disabled={disableClose}
                onClick={() => {
                  onClose();
                }}
                aria-label={"Close"}
              >
                <CloseIcon
                  className={css`
                    color: ${theme.palette.text.primary};
                  `}
                />
              </IconButton>
            </div>
            {children}
          </div>
        </Drawer>
      )}
    </ClassNames>
  );
}
