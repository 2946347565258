import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminGoalsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminGoalsPageDataQuery = (
  { __typename?: 'Query' }
  & { adminGoals: Array<(
    { __typename?: 'AdminGoal' }
    & Pick<Types.AdminGoal, 'id' | 'type' | 'status' | 'startDate' | 'endDate'>
    & { rewards: (
      { __typename: 'AdminGoalRewardsGiftCard' }
      & Pick<Types.AdminGoalRewardsGiftCard, 'dollarValue'>
    ), tracker: (
      { __typename: 'BudgetUtilizationAdminGoalTracker' }
      & Pick<Types.BudgetUtilizationAdminGoalTracker, 'id' | 'targetPercentage' | 'totalPointsBudget' | 'totalPointsSent' | 'progressUpdatedAt'>
    ) | (
      { __typename: 'LoginAdminGoalTracker' }
      & Pick<Types.LoginAdminGoalTracker, 'id' | 'targetPercentage' | 'totalUsersWhoCheckedIn' | 'totalActiveUsers' | 'progressUpdatedAt'>
    ) }
  )>, organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar'>
  ) }
);


export const AdminGoalsPageDataDocument = gql`
    query AdminGoalsPageData {
  adminGoals: getMyAdminGoals {
    id
    type
    status
    rewards {
      __typename
      ... on AdminGoalRewardsGiftCard {
        dollarValue
      }
    }
    startDate
    endDate
    tracker {
      __typename
      ... on BudgetUtilizationAdminGoalTracker {
        id
        targetPercentage
        totalPointsBudget
        totalPointsSent
        progressUpdatedAt
      }
      ... on LoginAdminGoalTracker {
        id
        targetPercentage
        totalUsersWhoCheckedIn
        totalActiveUsers
        progressUpdatedAt
      }
    }
  }
  organization: getMyRewardsOrganization {
    id
    pointsPerDollar
  }
}
    `;

/**
 * __useAdminGoalsPageDataQuery__
 *
 * To run a query within a React component, call `useAdminGoalsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGoalsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGoalsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGoalsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<AdminGoalsPageDataQuery, AdminGoalsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGoalsPageDataQuery, AdminGoalsPageDataQueryVariables>(AdminGoalsPageDataDocument, options);
      }
export function useAdminGoalsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGoalsPageDataQuery, AdminGoalsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGoalsPageDataQuery, AdminGoalsPageDataQueryVariables>(AdminGoalsPageDataDocument, options);
        }
export type AdminGoalsPageDataQueryHookResult = ReturnType<typeof useAdminGoalsPageDataQuery>;
export type AdminGoalsPageDataLazyQueryHookResult = ReturnType<typeof useAdminGoalsPageDataLazyQuery>;
export type AdminGoalsPageDataQueryResult = Apollo.QueryResult<AdminGoalsPageDataQuery, AdminGoalsPageDataQueryVariables>;