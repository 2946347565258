import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AdminGoalAdminListQueryVariables = Types.Exact<{
  budgetsEnabled?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type AdminGoalAdminListQuery = (
  { __typename?: 'Query' }
  & { listRewardsAdmins: (
    { __typename?: 'ListRewardsAdminsResponse' }
    & Pick<Types.ListRewardsAdminsResponse, 'total'>
    & { items: Array<(
      { __typename?: 'RewardsAdminUser' }
      & Pick<Types.RewardsAdminUser, 'id' | 'firstName' | 'lastName' | 'role' | 'active'>
    )> }
  ) }
);


export const AdminGoalAdminListDocument = gql`
    query AdminGoalAdminList($budgetsEnabled: Boolean) {
  listRewardsAdmins(filter: {active: true, budgetsEnabled: $budgetsEnabled}) {
    total
    items {
      id
      firstName
      lastName
      role
      active
    }
  }
}
    `;

/**
 * __useAdminGoalAdminListQuery__
 *
 * To run a query within a React component, call `useAdminGoalAdminListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGoalAdminListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGoalAdminListQuery({
 *   variables: {
 *      budgetsEnabled: // value for 'budgetsEnabled'
 *   },
 * });
 */
export function useAdminGoalAdminListQuery(baseOptions?: Apollo.QueryHookOptions<AdminGoalAdminListQuery, AdminGoalAdminListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGoalAdminListQuery, AdminGoalAdminListQueryVariables>(AdminGoalAdminListDocument, options);
      }
export function useAdminGoalAdminListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGoalAdminListQuery, AdminGoalAdminListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGoalAdminListQuery, AdminGoalAdminListQueryVariables>(AdminGoalAdminListDocument, options);
        }
export type AdminGoalAdminListQueryHookResult = ReturnType<typeof useAdminGoalAdminListQuery>;
export type AdminGoalAdminListLazyQueryHookResult = ReturnType<typeof useAdminGoalAdminListLazyQuery>;
export type AdminGoalAdminListQueryResult = Apollo.QueryResult<AdminGoalAdminListQuery, AdminGoalAdminListQueryVariables>;