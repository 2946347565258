/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faHandHoldingDollar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { AppTheme } from "@rewards-web/shared/style/types";

import { RewardsUserPartial } from ".";

export function SendRecognitionToEmployeeButton({
  employee,
}: {
  employee: RewardsUserPartial;
}) {
  const theme = useTheme();

  return (
    <Tooltip title={`Send recognition points to ${employee.firstName}`}>
      <div
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.grey[200]};
          border-radius: 4px;
          padding: 5px;
          cursor: pointer;
        `}
      >
        <Link
          to={`/recognition/give-points?${new URLSearchParams({
            userId: employee.id,
          })}`}
          state={{ backTo: "/employees/accounts" }}
        >
          <FontAwesomeIcon
            icon={faHandHoldingDollar}
            color={theme.palette.primary.main}
            css={css`
              width: 22px;
              height: auto;
            `}
          />
        </Link>
      </div>
    </Tooltip>
  );
}
