/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useEffect, useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { CandidateRecruitmentStepName } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { InsightsDownloadReportModal } from "./download-report-modal";
import { AllTimeInsightMetricGroupRange } from "./insight-metric-group-ranges/all-time";
import { QuarterlyInsightMetricGroupRange } from "./insight-metric-group-ranges/quarterly";
import { QuarterlyMonthlyInsightMetricGroupRange } from "./insight-metric-group-ranges/quarterly-monthly";
import { useInsightsPageDataQuery } from "./insights-page-data.generated";

export function InsightsContent() {
  const theme = useTheme();
  const [downloadReportModalOpen, setDownloadReportModalOpen] = useState(false);
  const track = useTrack();
  const downloadReportFeatureFlagEnabled = useFeatureFlag(
    "admin-app-homepage-insights-download-report-button-visible-temp"
  );
  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  const {
    data: dateRangeData,
    error: dateRangeError,
  } = useInsightsPageDataQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const downloadReportButtonEnabled = dateRangeData
    ? // only enable button if org is launched
      Boolean(dateRangeData.getMyRewardsOrganization.launchedAt)
    : undefined;

  useEffect(() => {
    track("Viewed homepage insights page");
  }, [track]);

  useEffect(() => {
    if (typeof downloadReportButtonEnabled === "boolean") {
      track("Viewed homepage insights download report button", {
        buttonEnabled: downloadReportButtonEnabled,
      });
    }
  }, [track, downloadReportButtonEnabled]);

  if (dateRangeError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!dateRangeData) {
    return <PageLoadingState />;
  }
  const {
    pointsPerDollar,
    timezone,
    referralsEnabled,
    launchedAt,
    candidateStepsV2,
  } = dateRangeData.getMyRewardsOrganization;

  const dateRangeOptions = (() => {
    if (
      dateRangeData.insightDateRanges.__typename ===
      "NotLaunchedInsightDateRanges"
    ) {
      return { quarterly: [], monthly: [] };
    }
    return {
      quarterly: dateRangeData.insightDateRanges.quarterly
        .map((range) => ({
          ...range,
          type: "quarterly" as const,
        }))
        .reverse(),
      monthly: dateRangeData.insightDateRanges.monthly
        .map((range) => ({
          ...range,
          type: "monthly" as const,
        }))
        .reverse(),
    };
  })();

  const content = (() => {
    if (dateRangeOptions.monthly.length || dateRangeOptions.quarterly.length) {
      return (
        <div
          css={(theme: AppTheme) =>
            css`
              display: flex;
              flex-direction: column;
              gap: ${theme.spacing(3.75)};
            `
          }
        >
          <AllTimeInsightMetricGroupRange
            launchedAt={launchedAt}
            pointsPerDollar={pointsPerDollar}
            timezone={timezone}
            referralsEnabled={referralsEnabled}
          />
          <QuarterlyInsightMetricGroupRange
            quarterlyDateRangeOptions={dateRangeOptions.quarterly}
            timezone={timezone}
            referralsEnabled={referralsEnabled}
            hasContactedStep={candidateStepsV2.some(
              (step) =>
                step.__typename === "CandidateRecruitmentStep" &&
                step.stepName === CandidateRecruitmentStepName.Contacted
            )}
          />
          <QuarterlyMonthlyInsightMetricGroupRange
            dateRangeOptions={dateRangeOptions}
            pointsPerDollar={pointsPerDollar}
            timezone={timezone}
          />
        </div>
      );
    }
    return (
      <Card
        css={css`
          text-align: center;
          padding: ${theme.spacing(2.25)};
        `}
        variant="outlined"
      >
        <Typography variant="h3" fontWeight={700}>
          No insights available yet
        </Typography>
        <Typography marginTop={theme.spacing(1.25)} color="grey.800">
          Your agency insights will appear here once you launch your program.
          Ready to get started?
        </Typography>
        <Button
          css={css`
            margin-top: ${theme.spacing(2)};
            border-radius: 100px;
            height: auto;
            padding: ${theme.spacing(1)} ${theme.spacing(2)};
          `}
          width="auto"
          variant="outlined"
          linkTo="mailto:help@caribou.care"
          externalLink
          label="Contact us"
        />
      </Card>
    );
  })();

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Typography
          variant={newThemeEnabled ? "h3" : "h1"}
          color="textPrimary"
          fontWeight={700}
        >
          Insights
        </Typography>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            gap: ${theme.spacing(3)};
          `}
        >
          {downloadReportFeatureFlagEnabled && (
            <>
              <Tooltip
                title="Reports become available after launch. Come back later to generate your reports."
                placement="bottom"
                disabled={downloadReportButtonEnabled}
              >
                <Button
                  color="primary"
                  width="auto"
                  size="medium"
                  onClick={() => {
                    setDownloadReportModalOpen(true);
                    track("Clicked homepage insights download report button");
                  }}
                  label="Download report(s)"
                  disabled={!downloadReportButtonEnabled}
                />
              </Tooltip>

              <InsightsDownloadReportModal
                open={downloadReportModalOpen}
                onClose={() => {
                  setDownloadReportModalOpen(false);
                }}
              />
            </>
          )}
        </div>
      </div>
      <Divider
        css={(theme: AppTheme) => css`
          margin-top: ${theme.spacing(4.5)};
          margin-bottom: ${theme.spacing(3.75)};
        `}
      />
      {content}
    </>
  );
}
