import {
  RewardsAdminPermissions,
  RewardsAdminPermissionsType,
  RewardsAdminRestrictedAccessPermissions,
  RewardsAdminRestrictedAccessPermissionLevel,
} from "@rewards-web/shared/graphql-types";

import { AdminPermissionType } from "../types";

export function hasPermission(
  permissions: RewardsAdminPermissions,
  level: "read" | "full",
  resource: AdminPermissionType
): boolean {
  if (permissions.type === RewardsAdminPermissionsType.FullAccess) {
    return true;
  }

  const isApiPermission = resource !== "settings" && resource !== "onboarding";

  if (resource === "home") {
    return (
      permissions.__typename === "RewardsAdminFullAccessPermissions" ||
      permissions.__typename === "RewardsAdminRestrictedAccessPermissions"
    );
  }

  if (resource === "settings") {
    return (
      permissions.__typename === "RewardsAdminFullAccessPermissions" ||
      permissions.__typename === "RewardsAdminRestrictedAccessPermissions"
    );
  }

  if (resource === "goals") {
    return (
      permissions.__typename === "RewardsAdminFullAccessPermissions" ||
      permissions.__typename === "RewardsAdminRestrictedAccessPermissions"
    );
  }

  if (
    resource === "settingsEmployeeAccounts" ||
    resource === "settingsCommunication" ||
    resource === "settingsRecognition" ||
    resource === "settingsRedemption" ||
    resource === "settingsJobPosting" ||
    resource === "settingsAdminGoals"
  ) {
    return permissions.__typename === "RewardsAdminFullAccessPermissions";
  }

  if (resource === "draw") {
    return permissions.__typename === "RewardsAdminFullAccessPermissions";
  }

  if (resource === "moments") {
    return permissions.__typename === "RewardsAdminFullAccessPermissions";
  }

  if (level === "read") {
    return (
      isApiPermission &&
      permissions.type === RewardsAdminPermissionsType.RestrictedAccess &&
      [
        RewardsAdminRestrictedAccessPermissionLevel.FullAccess,
        RewardsAdminRestrictedAccessPermissionLevel.ReadOnly,
      ].includes(
        (permissions as RewardsAdminRestrictedAccessPermissions).permissionsV2![
          resource
        ]?.permissionLevel as RewardsAdminRestrictedAccessPermissionLevel
      )
    );
  }

  if (level === "full") {
    return (
      isApiPermission &&
      permissions.type === RewardsAdminPermissionsType.RestrictedAccess &&
      RewardsAdminRestrictedAccessPermissionLevel.FullAccess ===
        ((permissions as RewardsAdminRestrictedAccessPermissions)
          .permissionsV2![resource]
          ?.permissionLevel as RewardsAdminRestrictedAccessPermissionLevel)
    );
  }

  return false;
}
