/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faCheckCircle,
  faBellExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";

import { ModalErrorState } from "@rewards-web/shared/components/modal/modal-error-state";
import { ModalLoadingState } from "@rewards-web/shared/components/modal/modal-loading-state";
import { ObscureRecordedText } from "@rewards-web/shared/components/obscure-recorded-text";
import { Typography } from "@rewards-web/shared/components/typography";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/theme";

import {
  RightDrawer,
  RightDrawerContent,
} from "../../../../../../shared/components/right-drawer";
import { RightDrawerBaseCard } from "../../../../../../shared/components/right-drawer/right-drawer-base-card";
import { RecognitionPointsSentIcon } from "../recognition-points-sent-icon";
import { RecognitionPointsDetailsActivityTable } from "./bonus-points-details-activity-card";
import { useGetAwardedRecognitionPointsByIdQuery } from "./get-awarded-recognition-points-by-id.generated";

export interface RecognitionPointsDetailsDrawerProps {
  open: boolean;
  onClose(): void;
  recognitionPointsId: string | null;
}

export function RecognitionPointsDetailsDrawer({
  open,
  onClose,
  recognitionPointsId,
}: RecognitionPointsDetailsDrawerProps): JSX.Element {
  const theme = useTheme();
  const recognitionPointsDetailsQuery = useGetAwardedRecognitionPointsByIdQuery(
    {
      variables: {
        id: recognitionPointsId ?? "",
      },
      fetchPolicy: "network-only",
      skip: !recognitionPointsId,
      nextFetchPolicy: "network-only", // prevent from reloading query automatically after a mutation
      onError: reportError,
    }
  );

  const newThemeEnabled = useFeatureFlag("admin-app-new-theme-temp");

  if (recognitionPointsDetailsQuery.error) {
    return (
      <RightDrawer open={open} onClose={onClose} title="Error">
        <ModalErrorState onClose={onClose} />;
      </RightDrawer>
    );
  }

  if (
    recognitionPointsDetailsQuery.loading ||
    !recognitionPointsDetailsQuery.data ||
    !recognitionPointsDetailsQuery.data.getRecognitionPointsById
  ) {
    return (
      <RightDrawer open={open} onClose={onClose} title="">
        <ModalLoadingState />;
      </RightDrawer>
    );
  }

  const recognitionPointsDetails =
    recognitionPointsDetailsQuery.data.getRecognitionPointsById;

  return (
    <RightDrawer
      open={open}
      onClose={onClose}
      title="Message Details"
      maxWidth="400px"
    >
      <RightDrawerContent>
        <Typography variant={newThemeEnabled ? "subtitle" : "h4"}>
          <div
            css={(theme: AppTheme) => css`
              margin-top: ${theme.spacing(2)};
              margin-left: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            Recipient details
          </div>
        </Typography>
        <RightDrawerBaseCard>
          <ObscureRecordedText>
            <RecognitionPointsDetailsActivityTable
              items={[
                {
                  label: "Sent to",
                  value: `${recognitionPointsDetails.employee.firstName} ${recognitionPointsDetails.employee.lastName}`,
                },
              ]}
            />
          </ObscureRecordedText>
        </RightDrawerBaseCard>

        <Typography variant={newThemeEnabled ? "subtitle" : "h4"}>
          <div
            css={(theme: AppTheme) => css`
              margin-top: ${newThemeEnabled
                ? theme.spacing(2.5)
                : theme.spacing(2)};
              margin-left: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            Text message sent
          </div>
        </Typography>
        <RightDrawerBaseCard>
          <div
            css={(theme: AppTheme) => css`
              margin-right: ${theme.spacing(1)};
              margin-left: ${theme.spacing(1)};
            `}
          >
            <Typography variant="body" color="textSecondary" display="inline">
              ⭐ {recognitionPointsDetails.from} has given you{" "}
              {numberWithCommas(recognitionPointsDetails.pointValue)} points!
              Here's their message:{" "}
            </Typography>
            <Typography variant="body" display="inline">
              {recognitionPointsDetails.message}
            </Typography>
          </div>
        </RightDrawerBaseCard>

        <Typography variant={newThemeEnabled ? "subtitle" : "h4"}>
          <div
            css={(theme: AppTheme) => css`
              margin-top: ${newThemeEnabled
                ? theme.spacing(2.5)
                : theme.spacing(2)};
              margin-left: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            Activity
          </div>
        </Typography>

        {recognitionPointsDetails.claimedAt && (
          <RightDrawerBaseCard
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            <RecognitionPointsDetailsActivityTable
              items={[
                {
                  icon: (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color={theme.palette.success.main}
                      css={css`
                        width: 18px;
                        height: 18px;
                      `}
                    />
                  ),
                  label: "Claimed",
                  value: formatDate(recognitionPointsDetails.claimedAt),
                },
              ]}
            />
          </RightDrawerBaseCard>
        )}

        {recognitionPointsDetails.reminders.map((reminder) => (
          <RightDrawerBaseCard
            key={reminder.id}
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            <RecognitionPointsDetailsActivityTable
              items={[
                {
                  label: "Reminder",
                  value: formatDate(reminder.sentAt),
                  icon: (
                    <FontAwesomeIcon
                      icon={faBellExclamation}
                      color={theme.palette.gold.light}
                      css={css`
                        width: 18px;
                        height: 18px;
                      `}
                    />
                  ),
                },
              ]}
            />
          </RightDrawerBaseCard>
        ))}

        <RightDrawerBaseCard
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          <RecognitionPointsDetailsActivityTable
            items={[
              {
                label: "Sent",
                value: formatDate(recognitionPointsDetails.sentAt),
                icon: (
                  <RecognitionPointsSentIcon
                    css={css`
                      margin-bottom: -4px;
                    `}
                    fill={theme.palette.primary.main}
                  />
                ),
              },
            ]}
          />
        </RightDrawerBaseCard>
      </RightDrawerContent>
    </RightDrawer>
  );
}

function formatDate(date: number) {
  return format(date, "LLLL d, y");
}
